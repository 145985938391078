import React from "react";

import { Image } from 'react-native';

import { Text } from "@components/Text";

import { buildPropsStyle, useThemeValue } from './Theme';

export const Avatar = (props) => {
    const {
        size = 'lg',
        children,
        source,
        style,
        ...rest
    } = props;

    const themedSize = useThemeValue('components.Avatar.sizes', size);

    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const imageStyle = {
        width: themedSize,
        height: themedSize,
        ...useThemeValue('components.Avatar', 'baseStyle'),
        ...propsStyle
    };

    if (typeof source === 'string' || (source && typeof source.uri === 'string')) {
        return <Image source={source} style={style ? [imageStyle, style] : imageStyle} {...nonStyleProps} />
    } else if (children && children.length > 0) {
        return <Text
            fontSize={size}
            alignContent='center'
            textAlign='center'
            style={style ? [imageStyle, style] : imageStyle}
            {...nonStyleProps}>
            {children}
        </Text>
    } else {
        return null
    }
}
