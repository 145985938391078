import React from 'react';

import {
    Box
} from '@components/Layout';

const ResponsiveContainer = (props) => {
    const { children, ...rest } = props;
    return (
        <Box flex={1} alignItems={'center'} {...rest}>
            <Box flex={1} maxW={'contentMaxWidth'} w={'100%'}>
                {children}
            </Box>
        </Box>
    )
}

export default ResponsiveContainer;
