import React from 'react';

import { Button } from '@components/Button';

import {
    Box
} from '@components/Layout';

import { Heading, Text } from '@components/Text';

import useAuth from '@hooks/useAuth';
import useCurrentUser from '@hooks/useCurrentUser';

import { createUserPlayer } from '@api/player';
import { enterTournament } from '@api/tournament';
import fetchUserInfo from '@api/fetchUserInfo';

import PlayerAvatar from '@components/PlayerAvatar';

import ResponsiveContainer from '@components/ResponsiveContainer';

import {
    useTournament,
    useFetchTournament
} from '@hooks/TournamentListContext';

import getTournamentColors from '@utils/getTournamentColors';

const isAlreadyEntered = (tournament, player) => {
    if (player && tournament && tournament.Players) {
        const found = tournament.Players.find(p => {
            return p.id === player.id
        })
        return Boolean(found);
    }
    return false;
}

const getEntryCount = (tournament) => {
    return (tournament.Players ? tournament.Players.length : 0) + (tournament.Teams ? tournament.Teams.length : 0);
}

const enterAsUserPlayer = async (authHeader, tournament, user) => {
    let player = user.Player;
    if (!player) {
        let name;
        const userInfo = await fetchUserInfo(() => {}, authHeader);
        if (userInfo) {
            name = userInfo.name;
        }
        player = await createUserPlayer(authHeader, user, {name});
    }
    const result = await enterTournament(authHeader, tournament, 'player', player.id);
    return result;
}

const TournamentEnter = ({params, navigation}) => {
    const { authHeader } = useAuth();
    const { currentUser } = useCurrentUser();
    const fetchTournament = useFetchTournament();
    const tournament = useTournament(params.id);

    const [colors, setColors] = React.useState({});

    React.useEffect(() => {
        if (!tournament) {
            fetchTournament(params.id).then(t => {
                const tournamentColors = getTournamentColors(t);
                setColors(tournamentColors);
                navigation.setOptions({
                    title: t.name,
                    headerStyle: tournamentColors,
                });
            })
        }
    }, []);

    if (!tournament) {
        return null;
    }

    const isOrganizer = tournament && currentUser.id === tournament.organizerId;
    const alreadyEntered = isAlreadyEntered(tournament, currentUser.Player)

    const onPressEnterTournament = () => {
        enterAsUserPlayer(authHeader, tournament, currentUser);
    }

    let bottomSection;
    if (isOrganizer) {
        bottomSection = (
            <Text>Organizer</Text>
        )
    } else if (alreadyEntered) {
        bottomSection = (
            <Text>Already entered</Text>
        )
    } else if (tournament?.status === 'created') {
        bottomSection = (
            <Button onPress={onPressEnterTournament} size={'lg'} isDisabled={alreadyEntered}>
                Enter
            </Button>
        )
    }

    return (
        <ResponsiveContainer style={colors}>
            <Box alignItems={'center'} justifyContent={'center'} flex={1}>
                <Heading size={'5xl'} my={4}>{tournament && tournament.name}</Heading>
                {currentUser?.Player && (
                    <Box my={2}>
                        <PlayerAvatar player={currentUser?.Player} size={'lg'} />
                    </Box>
                )}
                {currentUser?.Player?.name && (
                    <Text fontSize={'lg'}>{currentUser?.Player?.name}</Text>
                )}
                <Box mt={4}>{bottomSection}</Box>
            </Box>
        </ResponsiveContainer>
    );
}

export default TournamentEnter;
