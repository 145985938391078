import React from "react";

import {
    Platform,
    SafeAreaView,
    StatusBar,
    StyleSheet,
    View
} from "react-native";

import { buildPropsStyle, useThemeValue, useBreakpointValue } from '@components/Theme';

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0
    }
});

export const Divider = (props) => {
    const baseStyle = useThemeValue('components.Divider', 'baseStyle');
    const { colorScheme = 'primary', colorLevel = 100 } = props;
    const color = useThemeValue("colors", `${colorScheme}.${colorLevel}`);
    return <View style={[baseStyle, { backgroundColor: color }]} role="none"></View>
}

export const Box = (props) => {
    const { style, children, safeArea = false, ...rest } = props;
    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);
    if (safeArea) {
        return <SafeAreaView style={[style, propsStyle, styles.safeArea]} {...nonStyleProps}>{children}</SafeAreaView>
    } else {
        return <View style={[style, propsStyle]} {...nonStyleProps}>{children}</View>
    }
}

const renderStackChildren = (props, spaceFieldName) => {
    const { children, space = 0, divider } = props;
    const themedSpace = useThemeValue('space', space);
    const spacerFactory = divider ? (index) => React.cloneElement(divider, {key:index}) : {[spaceFieldName]:themedSpace}
    if (Array.isArray(children) && children.length > 1) {
        const withSpace = [];
        for (let i = 0; i < children.length; ++i) {
            withSpace.push(children[i])
            if (i < (children.length - 1)) {
                withSpace.push(typeof spacerFactory === 'function' ? spacerFactory(i) : <View key={i} style={spacerFactory}></View>)
            }
        }
        return withSpace;
    } else {
        return children;
    }
}

export const HStack = (props) => {
    const { children, style, ...rest } = props;
    const baseStyle = useThemeValue('components.HStack', 'baseStyle');
    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);
    return <View style={[style, propsStyle, baseStyle]}  {...nonStyleProps}>
        {renderStackChildren(props, 'width')}
    </View>
}

export const VStack = (props) => {
    const { children, style, ...rest } = props;
    const baseStyle = useThemeValue('components.VStack', 'baseStyle');
    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);
    return <View style={[style, propsStyle, baseStyle]} {...nonStyleProps}>
        {renderStackChildren(props, 'height')}
    </View>
}

export const Stack = (props) => {
    const { children, style, direction, ...rest } = props;
    const directionStyle = {flexDirection: useBreakpointValue(direction || 'column')};
    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);
    return <View style={[style, propsStyle, directionStyle]} {...nonStyleProps}>
        {renderStackChildren(props, directionStyle.flexDirection === 'column' ? 'height' : 'width')}
    </View>
}
