import React from 'react';

import { SectionList } from 'react-native';

import useOpenCloseState from '@utils/useOpenCloseState';

import {
    HStack
} from '@components/Layout';

import { IconButton } from '@components/Button';
import { Heading } from '@components/Text';

import { BackIcon, DeleteIcon } from '@components/Icons';
import ConfirmDialog from '@components/ConfirmDialog';
import ResponsiveContainer from '@components/ResponsiveContainer';
import SimpleCheckBox from '@components/SimpleCheckbox';

import {
    useTournamentListActions,
    useTournamentListState,
    useTournamentAPI,
    useEliminationGroups
} from '@hooks/TournamentListContext';

import * as TournamentStatus from '@api/TournamentStatus';

import { TournamentCardDefault, TournamentCardStarted, TournamentCardCompleted } from './TournamentCard';
import TournamentListEmpty from './TournamentListEmpty';

const getSectionTitle = title => {
    if (title === TournamentStatus.CREATED) {
        return 'Drafts';
    } else if (title === TournamentStatus.STARTED) {
        return 'In Progress';
    } else if (title === TournamentStatus.COMPLETED) {
        return 'Complete';
    } else {
        return title;
    }
}

const TournamentSectionHeader = ({title}) => {
    return (
        <Heading bg={"primary.500"} px={"2"} py={"2"} size={"lg"}>
            {getSectionTitle(title)}
        </Heading>
    )
}

const createSections = (tournaments, options) => {
    if (tournaments && tournaments.length > 0) {
        const tournamentsByStatus = tournaments.reduce((result, value) => {
            (result[value.status] || (result[value.status] = [])).push(value);
            return result;
        }, {});
        const sections = Object.entries(tournamentsByStatus).map(([status, tournaments]) => {
            return {
                ...(options && options[status]),
                title: status,
                data: tournaments
            }
        })
        return sections;
    }
    return [];
}

const SectionListWithSelectionMode = (props) => {
    const { children, sections, renderItem, keyExtractor, selectionModeState, selectionModeDispatch, ...rest } = props;

    const wrapRenderItem = (renderItem) => {
        return ({item}) => {
            const rendered = renderItem({item})
            return selectionModeState.active ? (
                <HStack>
                    <SimpleCheckBox
                        value={selectionModeState.selected.indexOf(keyExtractor(item)) !== -1}
                        onChange={(checked) => {
                            if (checked) {
                                selectionModeDispatch({type:'select', ...item})
                            } else {
                                selectionModeDispatch({type:'deselect', ...item})
                            }
                        }
                    } />
                    {rendered}
                </HStack>
            ) : rendered
        }
    }

    return (
        <SectionList
            sections={sections.map(s => ({...s, renderItem: s.renderItem ? wrapRenderItem(s.renderItem) : null}))}
            keyExtractor={keyExtractor}
            renderItem={wrapRenderItem(renderItem)}
            {...rest}
        >
            {children}
        </SectionList>
    )
}

const selectionModeReducer = (state, action) => {
    const { type, ...rest } = action;
    switch (type) {
        case 'activate': {
            return {
                ...state,
                active: true,
                selected: rest ? [state.keyExtractor(rest)] : []
            }
        }
        case 'deactivate': {
            return {
                ...state,
                active: false,
                selected: []
            }
        }
        case 'clear': {
            return {
                ...state,
                selected: []
            }
        }
        case 'select': {
            return {
                ...state,
                selected: state.selected.concat(state.keyExtractor(rest))
            }
        }
        case 'deselect': {
            return {
                ...state,
                selected: state.selected.filter(x => x !== state.keyExtractor(rest))
            }
        }
        default: {
            return state;
        }
    }
}

const TournamentList = ({navigation}) => {
    const { fetchEliminationGroups } = useTournamentAPI();
    const { selectTournament } = useTournamentListActions();
    const { deleteTournaments } = useTournamentAPI();
    const { tournaments } = useTournamentListState();

    const onSelectTournament = tournament => {
        selectTournament(tournament);
        if (tournament.status !== TournamentStatus.CREATED) {
            navigation.navigate('View', {
                id: tournament.id
            });
        } else {
            navigation.navigate('Edit', {
                id: tournament.id
            });
        }
    }

    const keyExtractor = item => item.id

    const [ selectionModeState, selectionModeDispatch ] = React.useReducer(selectionModeReducer, {
        active: false,
        selected: [],
        keyExtractor
    });

    const { isOpen, onOpen, onClose } = useOpenCloseState();

    const renderTournamentItemStarted = ({item}) => {
        return (
            <TournamentCardStarted
                tournament={item}
                eliminationGroups={useEliminationGroups(item.id)}
                onPress={() => onSelectTournament(item)}
                onLongPress={() => {
                    selectionModeDispatch({type:'activate', ...item})
                }}
            />
        )
    }

    const renderTournamentItemCompleted = ({item}) => {
        return (
            <TournamentCardCompleted
                tournament={item}
                onPress={() => onSelectTournament(item)}
                onLongPress={() => {
                    selectionModeDispatch({type:'activate', ...item})
                }}
            />
        )
    }

    const sectionOptions = {
        [TournamentStatus.STARTED]: {
            renderItem: renderTournamentItemStarted
        },
        [TournamentStatus.COMPLETED]: {
            renderItem: renderTournamentItemCompleted
        }
    }

    const sections = createSections(tournaments, sectionOptions);

    React.useEffect(() => {
        const started = fetchEliminationGroups && tournaments?.filter(t => t.status === TournamentStatus.STARTED);
        if (started?.length > 0) {
            Promise.all(started.map(t => fetchEliminationGroups(t))).then(() => {
            })
        }
    }, [tournaments, fetchEliminationGroups]);

    React.useEffect(() => {
        if (selectionModeState.active) {
            navigation.setOptions({
                headerLeft: () => (
                    <IconButton
                        onPress={() => {
                            selectionModeDispatch({type:'deactivate'})
                        }}
                        icon={<BackIcon/>}
                        size={'lg'}
                    />
                ),
                headerRight: () => (
                    <IconButton
                        isDisabled={selectionModeState.selected.length === 0}
                        onPress={onOpen}
                        icon={<DeleteIcon/>}
                        size={'lg'}
                        mr={1}
                    />
                )
            });
        } else {
            navigation.setOptions({
                headerLeft: null,
                headerRight: null
            })
        }
    }, [selectionModeState]);

    const renderTournamentItemDefault = ({item}) => {
        return (
            <TournamentCardDefault
                tournament={item}
                onPress={() => onSelectTournament(item)}
                onLongPress={() => {
                    selectionModeDispatch({type:'activate', ...item})
                }}
            />
        )
    }

    const confirmedDelete = () => {
        onClose();
        deleteTournaments(selectionModeState.selected).then(() => {
            selectionModeDispatch({type:'deactivate'})
        })
    }

    return (
        <ResponsiveContainer>
            <ConfirmDialog
                isOpen={isOpen}
                onCancel={() => {
                    onClose();
                    selectionModeDispatch({type:'deactivate'})
                }}
                onConfirm={() => confirmedDelete()}
                dialogTitle={'Confirm delete'}
                dialogText={selectionModeState.selected.length === 1 ? 'Delete tournament?' : `Delete ${selectionModeState.selected.length} tournaments?`}
                confirmLabel={'Delete'}
                confirmButtonProps={{colorScheme: 'red'}}
            />
            <SectionListWithSelectionMode
                sections={sections}
                selectionModeState={selectionModeState}
                selectionModeDispatch={selectionModeDispatch}
                keyExtractor={keyExtractor}
                renderItem={renderTournamentItemDefault}
                renderSectionHeader={({section}) => (
                    <TournamentSectionHeader {...section} />
                )}
                ListEmptyComponent={TournamentListEmpty}
            />
        </ResponsiveContainer>
    )
}

export default TournamentList;