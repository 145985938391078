import React from 'react';

import { Ionicons } from '@expo/vector-icons';

import { useThemeValue } from '@components/Theme';

export const Icon = ({
    color,
    size = 'md',
    ...rest
}) => {
    const iconColor = useThemeValue('colors', color)
    const iconSize = useThemeValue('components.Icon.sizes', size)
    return <Ionicons color={iconColor} size={iconSize} {...rest} />
}

export const CloseIcon = props => <Icon name={'close-outline'} {...props} />

export const CheckmarkIcon = props => <Icon name='checkmark-outline' {...props} />

export const MenuIcon = props => <Icon name='ellipsis-vertical' {...props} />
export const BurgerMenuIcon = props => <Icon name='menu' {...props} />

export const CheckboxEmpty = props => <Icon name={'square-outline'} {...props} />
export const CheckboxChecked = props => <Icon name={'checkbox-outline'} {...props} />

export const PlusIcon = props => <Icon name={'add'} {...props} />
export const MinusIcon = props => <Icon name={'remove'} {...props} />

export const BackIcon = props => <Icon name={'arrow-back'} {...props} />

export const DeleteIcon = props => <Icon name={'trash-sharp'} {...props} />

export const ReorderIcon = props => <Icon name={'reorder-three'} {...props} />

export const FacebookLogoIcon = props => <Icon name={'logo-facebook'} {...props} />
export const InstagramLogoIcon = props => <Icon name={'logo-instagram'} {...props} />
export const TwitterLogoIcon = props => <Icon name={'logo-twitter'} {...props} />

export const QRCodeIcon = props => <Icon name={'qr-code-outline'} {...props} />

export const SendIcon = props => <Icon name={'send-outline'} {...props} />