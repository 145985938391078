import React from 'react';

import { isNumber } from 'lodash';

import { Button, IconButton } from '@components/Button';
import { Modal } from '@components/Modal';

import {
    FormControl,
    Input
} from '@components/Form';

import {
    HStack,
    VStack
} from '@components/Layout';

import { useForm, Controller } from "react-hook-form";

import { PlusIcon, MinusIcon } from '@components/Icons';
import SimpleCheckbox from '@components/SimpleCheckbox';
import { Heading } from '@components/Text';

export const isValidScore = value => {
    const score = Number.parseInt(value);
    return isFinite(score) && score >= 0;
}

const validateScore = score => {
    return isValidScore(score) ? true : 'Value should be a number >= 0';
}

export const buildMatchScoreMap = match => {
    return match.contestants.reduce((s, c) => {
        s[c.id] = c.score;
        return s;
    }, {});
}

const MatchScoreModal = ({
    match,
    isOpen, onCancel, onUpdateScores,
    dialogTitle = 'Update score',
    cancelButtonLabel = 'Close',
    cancelButtonProps = {variant: 'unstyled'},
    okButtonLabel = 'Update',
    okButtonProps
}) => {
    const scores = match && buildMatchScoreMap(match);

    const isFinal = match.resultStatus === 'verified';

    const { control, formState: { isValid }, setValue, handleSubmit } = useForm({
        defaultValues: {...scores, isFinal},
        mode: 'all'
    });

    const onPressOK = async () => {
        await handleSubmit(result => {
            const {isFinal, ...scores} = result;
            onUpdateScores(scores, isFinal);
        })(); // (handleSubmit returns a function)
    }

    const toNumber = n => {
        if (typeof n === 'string') {
            nn = parseInt(n);
            return !isNaN(nn) ? nn : n;
        } else {
            return n;
        }
    }

    const incrementScore = (contestant, score) => {
        if (isNumber(score)) {
            setValue(contestant.id, score + 1)
        }
    }

    const decrementScore = (contestant, score) => {
        if (isNumber(score) && score > 0) {
            setValue(contestant.id, score - 1)
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={() => onCancel()}>
            <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>{dialogTitle}</Modal.Header>
                <Modal.Body>
                    {match && <Heading size={'sm'} textAlign={'center'} mb={1}>{`Round ${match.round + 1}, Match ${match.index + 1}`}</Heading>}
                    <VStack space={2}>
                        {match && match.contestants.map(contestant => (
                            <Controller
                                key={contestant.id}
                                control={control}
                                name={contestant.id}
                                rules={{
                                    required: true,
                                    validate: validateScore
                                }}
                                render={({field: {onChange, onBlur, value}, fieldState: {error, invalid}}) => (
                                    <FormControl isInvalid={invalid} flexDirection={'row'}>
                                        <FormControl.Label alignSelf={'center'} mr={2}>{contestant.name}</FormControl.Label>
                                        <Input testID='score-input'
                                            value={String(value)}
                                            type={"text"}
                                            inputMode={"numeric"}
                                            onChange={onChange}
                                            onChangeText={onChange}
                                            onBlur={onBlur}
                                            flexGrow={1}
                                            InputRightElement={
                                                <IconButton testID='inc-score'
                                                    onPress={() => {
                                                        incrementScore(contestant, toNumber(typeof value !== 'undefined' ? value : scores[contestant.id]))
                                                    }}
                                                    icon={<PlusIcon/>}
                                                />
                                            }
                                            InputLeftElement={
                                                <IconButton testID='dec-score'
                                                    onPress={() => {
                                                        decrementScore(contestant, toNumber(typeof value !== 'undefined' ? value : scores[contestant.id]))
                                                    }}
                                                    icon={<MinusIcon/>}
                                                />
                                            }
                                        />
                                        <FormControl.ErrorMessage>{error && error.message}</FormControl.ErrorMessage>
                                    </FormControl>
                                )}
                            />
                        ))}
                        <Controller
                            control={control}
                            name={'isFinal'}
                            render={({field}) => (
                                <FormControl accessibilityLabel='Is match final?'>
                                    <SimpleCheckbox {...field} justifyContent={'flex-end'}>
                                        Final score
                                    </SimpleCheckbox>
                                </FormControl>
                            )}
                        />
                    </VStack>
                </Modal.Body>
                <Modal.Footer>
                    <HStack space={2}>
                        <Button onPress={onCancel} {...cancelButtonProps}>{cancelButtonLabel}</Button>
                        <Button onPress={onPressOK} {...okButtonProps} isDisabled={!isValid}>{okButtonLabel}</Button>
                    </HStack>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    )
}

export default MatchScoreModal;
