import React from 'react';

import { Pressable, Share, StyleSheet } from 'react-native';

import { Button } from '@components/Button';

import { useToast } from '@components/Toast';

import { useThemeValue } from '@components/Theme';

import {
    Box,
    HStack,
    VStack
} from '@components/Layout';

import { useFocusEffect, useNavigation } from '@react-navigation/native';

import DraggableFlatList, {
    OpacityDecorator,
} from 'react-native-draggable-flatlist';

import { makeTournamentEnterURL } from '@api';

import { validateEntryCountProduct } from './validateProduct';
import { getTournamentProduct } from '@hooks/TournamentProductsContext';

import { Text } from '@components/Text';

import { PlusIcon, QRCodeIcon, DeleteIcon, SendIcon } from '@components/Icons';
import FloatingActionButton from '@components/FloatingActionButton';

import { useSelectedTournament, useTournamentAPI } from '@hooks/TournamentListContext';

import buildTournamentEntriesList from '@utils/buildTournamentEntriesList';

import AddEntryModal from './AddEntryModal';
import ValidationErrorMessage from './ValidationErrorMessage';


const NoEntries = ({tournament, addEntry, invite}) => {
    const navigation = useNavigation();
    return (
        <VStack minH={100} space={2} mx={8} alignContent={'center'} justifyContent={'center'}>
            <Text mt={4} mb={2} fontSize={'lg'} fontStyle={'italic'} textAlign={'center'}>Tournament has no entries</Text>
            <Button variant={'subtle'} onPress={invite}>Invite</Button>
            <Button variant={'subtle'} onPress={addEntry}>Add a player</Button>
            <Button variant={'subtle'} onPress={() => {
                navigation.push('TournamentQR', {
                    id: tournament.shortId
                });
            }}>Show QR code</Button>
        </VStack>
    )
}

const TournamentFillStep = () => {
    const [selectedId, setSelectedId] = React.useState();

    const [showAddModal, setShowAddModal] = React.useState(false);

    const [entryCountWarning, setEntryCountWarning] = React.useState(null);

    const navigation = useNavigation();

    const toast = useToast();

    const tournament = useSelectedTournament();

    const { addEntry, removeEntry, setSeedingOrder } = useTournamentAPI();

    const entries = tournament && buildTournamentEntriesList(tournament) || [];

    const onDragEnd = React.useCallback(({to, data}) => {
        const seeding = entries.reduce((seeding, entry, index) => {
            seeding[entry.participantId] = index;
            return seeding;
        }, {})
        const newSeeding = data.reduce((seeding, entry, index) => {
            seeding[entry.participantId] = index;
            return seeding;
        }, {})
        setSeedingOrder(tournament.id, newSeeding).then(() => {
            const selected = entries.find(e => e.participantId === selectedId);
            if (selected) {
                toast.show(`${selected.name} moved to #${to+1} seed`);
            }
            setSelectedId(null);
        }, () => {
            setSeedingOrder(tournament.id, seeding);
            setSelectedId(null);
        })
    }, [tournament, entries, selectedId]);

    const onAdd = React.useCallback(() => {
        setShowAddModal(true);
    }, []);

    const onShowCode = React.useCallback(() => {
        navigation.push('TournamentQR', {
            id: tournament.shortId
        });
    }, [tournament]);

    const onAddOK = React.useCallback((name) => {
        addEntry(tournament.id, { name, type: 'player' })
    }, [tournament]);

    useFocusEffect(React.useCallback(() => {
        const result = validateEntryCountProduct(entries.length, { product: getTournamentProduct(tournament )});
        if (typeof result === 'string') {
            setEntryCountWarning(result);
        } else {
            setEntryCountWarning(null);
        }
    }, [entries]));

    const onRemoveEntry = React.useCallback((item) => {
        removeEntry(tournament.id, {
            id: item.participantId,
            type: item.participantType
        }).then(success => {
            if (success) {
                toast.show('Removed ' + item.name);
            } else {
                toast.show('Something went wrong removing ' + item.name);
            }
        })
    }, [tournament]);

    const onInvite = React.useCallback(() => {
        const url = makeTournamentEnterURL(tournament);
        const message = tournament.name ?
            `${tournament.name} is almost ready to start.\nJoin the tournament here: ${url}` : `Join the tournament here: ${url}`;
        Share.share({
            message,
            url
        })
    }, [tournament]);

    const [selectionColor, lightTextColor] = useThemeValue("colors", ["primary.600", "lightText"]);

    const renderEntryListItemName = ({item}) => {
        const isSelected = item.participantId === selectedId;
        return <Text fontSize={'lg'} mx={1} my={1} flex={1} color={isSelected ? lightTextColor : null}>{item.name}</Text>
    }

    const renderEntryListItem = ({item, drag, isActive}) => {
        const isSelected = item.participantId === selectedId;
        return (
            <OpacityDecorator>
                {isSelected ? (
                    <Pressable
                        style={[styles.entryListItem, {
                            color: lightTextColor,
                            backgroundColor: selectionColor
                        }]}
                        disabled={isActive}
                        onPress={() => {
                            setSelectedId(null)
                        }}
                        onLongPress={drag}
                    >
                        <HStack space={1}>
                            {renderEntryListItemName({item})}
                            <DeleteIcon size={'sm'} style={styles.entryDeleteIcon} color={lightTextColor} onPress={() => onRemoveEntry(item)} />
                        </HStack>
                    </Pressable>
                ) : (
                    <Pressable
                        style={styles.entryListItem}
                        disabled={isActive}
                        onPress={() => {
                            setSelectedId(item.participantId)
                        }}
                    >
                        {renderEntryListItemName({item})}
                    </Pressable>
                )}
            </OpacityDecorator>
        );
    }

    return (
        <Box mx={1} flexGrow={1}>
            {entryCountWarning && <ValidationErrorMessage error={{message:entryCountWarning}} />}
            <DraggableFlatList
                data={entries}
                extraData={selectedId}
                renderItem={renderEntryListItem}
                keyExtractor={item => item.participantId}
                onDragEnd={onDragEnd}
                ListEmptyComponent={() => <NoEntries addEntry={onAdd} invite={onInvite} tournament={tournament} />}
            />
            <FloatingActionButton
                icon={<PlusIcon color={'white'} size={'lg'} />}>
                <FloatingActionButton.Action label={"Invite"} icon={<SendIcon color={'white'} size={'sm'}/>} onPress={onInvite} />
                <FloatingActionButton.Action label={"Show code"} icon={<QRCodeIcon color={'white'} size={'sm'}/>} onPress={onShowCode} />
                <FloatingActionButton.Action label={"Add player"} icon={<PlusIcon color={'white'} size={'md'}/>} onPress={onAdd} />
            </FloatingActionButton>
            {showAddModal && 
                <AddEntryModal
                    isOpen={showAddModal}
                    onOK={onAddOK}
                    onClose={() => setShowAddModal(false)}
                />
            }
        </Box>
    )
}
export default TournamentFillStep;

const styles = StyleSheet.create({
    entryDeleteIcon: {
        alignSelf: 'center',
        marginRight: 4
    },
    entryListItem: {
        paddingLeft: 4,
        paddingRight: 8
    }
});
