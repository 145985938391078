import React from 'react';

import { Button } from '@components/Button';
import { Modal } from '@components/Modal';

import { FormControl, Select } from '@components/Form';

import {
    HStack
} from '@components/Layout';

const AvatarChoiceDialog = ({
    initialAvatarChoice = 'profilepic',
    isOpen, onCancel, onOK,
    avatarChoiceLabel = 'Avatar:',
    dialogTitle,
    cancelLabel = 'Cancel',
    cancelButtonProps = {variant: 'unstyled'},
    okLabel = 'OK',
    okButtonProps
}) => {
    const [avatarChoice, setAvatarChoice] = React.useState(initialAvatarChoice);
    const [okProcessing, setOKProcessing] = React.useState(false);
    const cancelRef = React.useRef(null);
    const inputRef = React.useRef(null);
    return (
        <Modal initialFocusRef={inputRef} isOpen={isOpen} onClose={onCancel}>
            <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>{dialogTitle}</Modal.Header>
                <Modal.Body>
                    {/* <FormControl>
                            <FormControl.Label>{inputLabel}</FormControl.Label>
                            <Input ref={inputRef} ml={2} flex={1} value={inputValue} testID={'input'}
                                onChangeText={text => {
                                    setInputValue(text);
                                }}></Input>
                    </FormControl> */}
                    <FormControl>
                        <FormControl.Label mr={3}>{avatarChoiceLabel}</FormControl.Label>
                        <Select accessibilityLabel="avatar choice"
                            selectedValue={avatarChoice}
                            onValueChange={newValue => setAvatarChoice(newValue)}>
                            <Select.Item value='profilepic' my={1} label="Profile picture"></Select.Item>
                            <Select.Item value='initials' my={1} label="Initials"></Select.Item>
                        </Select>
                    </FormControl>
                </Modal.Body>
                <Modal.Footer>
                    <HStack space={2}>
                        <Button onPress={onCancel} {...cancelButtonProps} ref={cancelRef}>{cancelLabel}</Button>
                        <Button {...okButtonProps} disabled={okProcessing} onPress={() => {
                            const result = onOK({avatarChoice});
                            if (result instanceof Promise) {
                                setOKProcessing(true);
                                const setOKProcessingFalse = setOKProcessing.bind(null, false);
                                result.then(setOKProcessingFalse, setOKProcessingFalse);
                            }
                        }}>{okLabel}</Button>
                    </HStack>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    )
}

export default AvatarChoiceDialog;
