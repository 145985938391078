import * as ActionTypes from '@actions/actionTypes';

export const loginRequest = () => {
    return {
        type: ActionTypes.LOGIN_REQUEST
    }
}

export const loginSuccess = tokenConfig => {
    return {
        type: ActionTypes.LOGIN_SUCCESS,
        ...tokenConfig
    }
}

export const loginFailure = () => {
    return {
        type: ActionTypes.LOGIN_FAILURE
    }
}

export const logoutRequest = () => {
    return {
        type: ActionTypes.LOGOUT_REQUEST
    }
}

export const logoutSuccess = () => {
    return {
        type: ActionTypes.LOGOUT_SUCCESS
    }
}

export const fetchUserRequest = () => {
    return {
        type: ActionTypes.FETCH_USER_REQUEST
    }
}

export const fetchUserSuccess = user => {
    return {
        type: ActionTypes.FETCH_USER_SUCCESS,
        user
    }
}

export const fetchUserFailure = () => {
    return {
        type: ActionTypes.FETCH_USER_FAILURE
    }
}
