const isBlack = color => color === '#000' || color === '#000000' || String(color).toLowerCase() === 'black'
const isWhite = color => color === '#fff' || color === '#ffffff' || String(color).toLowerCase() === 'white'

const getTournamentColors = tournament => {
    // Filter out black and white because applying them to a navigation header can cause warnings
    const colors = {};
    if (tournament && tournament.bgColor && !isBlack(tournament.bgColor) && !isWhite(tournament.bgColor)) {
        colors.backgroundColor = tournament.bgColor;
    }
    if (tournament && tournament.textColor && !isBlack(tournament.textColor) && !isWhite(tournament.textColor)) {
        colors.color = tournament.textColor;
        colors.borderColor = tournament.textColor;
    }
    return colors;
}

export default getTournamentColors;