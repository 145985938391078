import { isEmpty } from "lodash";

const updateMatchScores = (match, contestantsField, updatedScores) => {
    if (!match.placeholder && match.resultStatus !== 'verified' && typeof match[contestantsField] !== 'undefined') {
        return {
            ...match,
            [contestantsField]: match[contestantsField].map(contestant => {
                if (contestant && updatedScores && typeof updatedScores[contestant.id] !== 'undefined') {
                    return {
                        ...contestant,
                        score: updatedScores[contestant.id]
                    }
                } else {
                    return contestant;
                }
            })
        }
    } else {
        return match;
    }
}

export default (eliminationGroups, updatedScores, updatedResultStatuses = {}) => {
    if (isEmpty(updatedScores) && isEmpty(updatedResultStatuses)) {
        return eliminationGroups;
    }

    return eliminationGroups.map(eliminationGroup => {
        const updatedMatches = eliminationGroup.Matches.map(match => {
            if (typeof updatedResultStatuses[match.id] !== 'undefined') {
                match.resultStatus = updatedResultStatuses[match.id];
            }
            return updateMatchScores(match, 'MatchContestants', updatedScores)
        })

        const updatedBracket = eliminationGroup.bracket.map(round => round.map(match => {
            if (typeof updatedResultStatuses[match.id] !== 'undefined') {
                match.resultStatus = updatedResultStatuses[match.id];
            }
            return updateMatchScores(match, 'contestants', updatedScores)
        }))

        return {
            ...eliminationGroup,
            Matches: updatedMatches,
            bracket: updatedBracket
        }
    })
}
