import React from 'react';

import * as ScreenOrientation from 'expo-screen-orientation';

const ScreenOrientationContext = React.createContext();

export const ScreenOrientationProvider = ({ children }) => {
    const [orientation, setOrientation] = React.useState();

    React.useEffect(() => {
        ScreenOrientation.unlockAsync().then(() => {
            ScreenOrientation.getOrientationAsync().then((o) => {
                setOrientation(o);
            });
        })
    }, []);

    React.useEffect(() => {
        const subscription = ScreenOrientation.addOrientationChangeListener((event) => {
            setOrientation(event.orientationInfo.orientation);
        });
        return () => ScreenOrientation.removeOrientationChangeListener(subscription)
    }, []);

    return (
        <ScreenOrientationContext.Provider value={{
            orientation
        }}>
            { children }
        </ScreenOrientationContext.Provider>
    );
};

export function useScreenOrientation() {
    const { orientation } = React.useContext(ScreenOrientationContext);
    return {
        isLandscape: (orientation === ScreenOrientation.Orientation.LANDSCAPE_LEFT ||
            orientation === ScreenOrientation.Orientation.LANDSCAPE_RIGHT)
    };
};
