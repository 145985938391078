import React from 'react';

import { Button } from '@components/Button';
import { Spinner } from '@components/Spinner';

import {
    VStack
} from '@components/Layout';

import { Text } from '@components/Text';

import { useNavigation } from '@react-navigation/native';

import { useTournamentListState } from '@hooks/TournamentListContext';

const TournamentListEmpty = () => {
    const navigation = useNavigation();
    const { fetching } = useTournamentListState();
    return fetching ? (
        <Spinner mt={4} alignItems={'center'} />
    ) : (
        <VStack space={4} mx={8} mt={4} alignItems={'center'}>
            <Text mt={4} mb={2} fontSize={'lg'} textAlign={'center'}>
                No tournaments yet
            </Text>
            <Button variant={'subtle'} size={'lg'} px={6} onPress={() => {
                navigation.push('Create');
            }}>Create tournament</Button>
        </VStack>
    )
}

export default TournamentListEmpty;
