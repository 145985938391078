import React from 'react';

import useAuth from '@hooks/useAuth';

import { useSelectedTournament, useTournamentAPI } from '@hooks/TournamentListContext';

import ResponsiveContainer from '@components/ResponsiveContainer';

import StepsNavigator from './StepsNavigator';

const TournamentEditor = () => {
    const { authHeader } = useAuth();

    const { updateTournament } = useTournamentAPI(authHeader);
    const selectedTournament = useSelectedTournament();

    return (
        <ResponsiveContainer>
            <StepsNavigator
                tournament={selectedTournament}
                createOrUpdateAfterFirstStep={data => updateTournament(selectedTournament.id, data)}
            />
        </ResponsiveContainer>
    )
}

export default TournamentEditor;
