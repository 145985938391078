import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { Icon } from '@components/Icons';
import { buildPropsStyle } from '@components/Theme';
import { Button } from '@components/Button';
import { VStack } from '@components/Layout';

const styles = StyleSheet.create({
    indicatorIcon: {
        alignSelf: 'center',
        marginRight: '.5em'
    }
})

const renderIndicatorDefault = ({isSelected, isDisabled}) => {
    return <Icon style={styles.indicatorIcon} name={`radio-button-${isSelected ? 'on' : 'off'}-outline`} />
}

const renderRadioButtonDefault = (buttonProps) => {
    const { id, children, size, isDisabled, isSelected, onPress, renderIndicator, ...rest } = buttonProps;
    return <Button
        key={id}
        p={0}
        size={size}
        variant={'unstyled'}
        leftIcon={renderIndicator({isSelected, isDisabled})}
        onPress={onPress}
        isDisabled={isDisabled}
        {...rest}
    >
        {children}
    </Button>
}

const RadioButtonGroup = React.forwardRef((props, ref) => {
    const {
        children,
        value,
        isDisabled = false,
        renderIndicator = renderIndicatorDefault,
        renderRadioButton = renderRadioButtonDefault,
        onChange,
        size = 'md',
        style,
        ...rest
    } = props;

    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const [selectedValue, setSelectedValue] = React.useState(value)

    const radioButtons = (Array.isArray(children) ? children : [children]).map(React.useCallback(({key, props}) => {
        return renderRadioButton({
            ...props,
            key: key ?? props.id,
            size,
            isDisabled: props.isDisabled ?? isDisabled,
            isSelected: selectedValue === props.id,
            onPress: () => {
                if (props.id !== selectedValue) {
                    onChange(props.id);
                    setSelectedValue(props.id);
                }
            },
            renderIndicator
        })
    }), []);

    return (
        <VStack ref={ref} style={[propsStyle, style]} {...nonStyleProps} alignItems='flex-start'>
            {radioButtons}
        </VStack>
    )
})

RadioButtonGroup.displayName = 'RadioButtonGroup';

RadioButtonGroup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    value: PropTypes.any,
    isDisabled: PropTypes.bool,
    renderIndicator: PropTypes.func,
    renderRadioButton: PropTypes.func,
    onChange: PropTypes.func,
    size: PropTypes.string,
    style: PropTypes.object
};

const RadioButton = props => ({...props});

RadioButton.propTypes = {
    id: PropTypes.string.isRequired
};

RadioButton.Group = RadioButtonGroup;

export { RadioButton };
