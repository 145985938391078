import { makeApiUrl } from '@api';

import { fetchUserRequest, fetchUserSuccess, fetchUserFailure } from '@actions/auth';

export default async function fetchOrCreateCurrentUser(dispatch, authHeader) {
    dispatch(fetchUserRequest());

    const headers = new Headers({
        Authorization: authHeader,
        'Content-Type': 'application/json'
    });

    const existingUser = await fetch(makeApiUrl('user'), {
        headers
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            return null;
        }
    }).catch(err => {
        throw new Error(err);
    });

    if (existingUser) {
        dispatch(fetchUserSuccess(existingUser));
        return existingUser;
    }

    const newUser = await fetch(makeApiUrl('users'), {
        method: 'post',
        headers
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            return null;
        }
    }).catch(err => {
        throw new Error(err);
    });

    if (newUser) {
        dispatch(fetchUserSuccess(newUser));
        return newUser;
    } else {
        dispatch(fetchUserFailure());
        return null;
    }
}