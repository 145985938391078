// auth action types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

// profile action types
export const FETCH_USERINFO_REQUEST = 'FETCH_USERINFO_REQUEST';
export const FETCH_USERINFO_SUCCESS = 'FETCH_USERINFO_SUCCESS';
export const FETCH_USERINFO_FAILURE = 'FETCH_USERINFO_FAILURE';
export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const UPDATE_PLAYER_NAME = 'UPDATE_PLAYER_NAME';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

// tournament list and editor action types
export const SET_SELECTED_TOURNAMENT = 'SET_SELECTED_TOURNAMENT';
export const ADD_TOURNAMENT = 'ADD_TOURNAMENT';
export const REMOVE_TOURNAMENT = 'REMOVE_TOURNAMENT';
export const REMOVE_TOURNAMENT_BYID = 'REMOVE_TOURNAMENT_BYID';
export const FETCH_TOURNAMENT_REQUEST = 'FETCH_TOURNAMENT_REQUEST';
export const FETCH_TOURNAMENT_SUCCESS = 'FETCH_TOURNAMENT_SUCCESS';
export const FETCH_TOURNAMENT_FAILURE = 'FETCH_TOURNAMENT_FAILURE';
export const FETCH_TOURNAMENTS_REQUEST = 'FETCH_TOURNAMENTS_REQUEST';
export const FETCH_TOURNAMENTS_SUCCESS = 'FETCH_TOURNAMENTS_SUCCESS';
export const FETCH_TOURNAMENTS_FAILURE = 'FETCH_TOURNAMENTS_FAILURE';
export const TOURNAMENT_UPDATE = 'TOURNAMENT_UPDATE';
export const TOURNAMENT_ADD_ENTRY = 'TOURNAMENT_ADD_ENTRY';
export const TOURNAMENT_REMOVE_ENTRY = 'TOURNAMENT_REMOVE_ENTRY';
export const TOURNAMENT_SET_SEEDING = 'TOURNAMENT_SET_SEEDING';
export const TOURNAMENT_SET_STATUS = 'TOURNAMENT_SET_STATUS';
export const FETCH_PREVIEW_REQUEST = 'FETCH_PREVIEW_REQUEST';
export const FETCH_PREVIEW_SUCCESS = 'FETCH_PREVIEW_SUCCESS';
export const FETCH_PREVIEW_FAILURE = 'FETCH_PREVIEW_FAILURE';
export const START_TOURNAMENT_REQUEST = 'START_TOURNAMENT_REQUEST';
export const START_TOURNAMENT_SUCCESS = 'START_TOURNAMENT_SUCCESS';
export const START_TOURNAMENT_FAILURE = 'START_TOURNAMENT_FAILURE';

// tournament viewer action types
export const FETCH_ELIMINATION_GROUPS_REQUEST = 'FETCH_ELIMINATION_GROUPS_REQUEST';
export const FETCH_ELIMINATION_GROUPS_SUCCESS = 'FETCH_ELIMINATION_GROUPS_SUCCESS';
export const FETCH_ELIMINATION_GROUPS_FAILURE = 'FETCH_ELIMINATION_GROUPS_FAILURE';
export const UPDATE_MATCH_SCORE_REQUEST = 'UPDATE_MATCH_SCORE_REQUEST';
export const UPDATE_MATCH_SCORE_SUCCESS = 'UPDATE_MATCH_SCORE_SUCCESS';
export const UPDATE_MATCH_SCORE_FAILURE = 'UPDATE_MATCH_SCORE_FAILURE';
export const ADVANCE_WINNERS_REQUEST = 'ADVANCE_WINNERS_REQUEST';
export const ADVANCE_WINNERS_SUCCESS = 'ADVANCE_WINNERS_SUCCESS';
export const ADVANCE_WINNERS_FAILURE = 'ADVANCE_WINNERS_FAILURE';
