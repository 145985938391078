import React from 'react';

import { createStackNavigator } from "@react-navigation/stack";

import TournamentCreator from '@screens/tournament-editor/TournamentCreator';
import TournamentEditor from '@screens/tournament-editor/TournamentEditor';
import TournamentViewer from '@screens/tournament-viewer/TournamentViewer';
import TournamentEnter from '@screens/tournament-enter/TournamentEnter';
import TournamentQR from '@screens/tournament-qr/TournamentQR';

import HomeNavigator from '@screens/main/HomeNavigator';

const Stack = createStackNavigator();

const MainNavigator = (props) => {
    return (
        <Stack.Navigator {...props}>
            <Stack.Screen name="Home" options={{
                headerShown: false
            }}>
                {props => <HomeNavigator {...props} />}
            </Stack.Screen>
            <Stack.Screen name="View">
                {props => <TournamentViewer params={props.route.params} />}
            </Stack.Screen>
            <Stack.Screen name="Edit">
                {props => <TournamentEditor params={props.route.params} />}
            </Stack.Screen>
            <Stack.Screen
                name="Create"
                options={{title: "New Tournament"}}>
                {props => <TournamentCreator params={props.route.params} />}
            </Stack.Screen>
            <Stack.Screen name="TournamentEnter" options={{
                headerShown: false,
                title: "Enter Tournament"
            }}>
                {props => <TournamentEnter params={props.route.params} />}
            </Stack.Screen>
            <Stack.Screen name="TournamentQR" options={{
                headerShown: false,
                title: "Tournament Link"
            }}>
                {props => <TournamentQR params={props.route.params} />}
            </Stack.Screen>
        </Stack.Navigator>
    );
}

export default MainNavigator;