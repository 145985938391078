import React from 'react';

import { Button } from '@components/Button';
import { Modal } from '@components/Modal';

import { FormControl, Select } from '@components/Form';

import {
    HStack
} from '@components/Layout';

const ThemeChoiceDialog = ({
    initialThemeChoice = 'system',
    isOpen, onCancel, onOK,
    dialogTitle = 'Theme',
    cancelLabel = 'Cancel',
    cancelButtonProps = {variant: 'unstyled'},
    okLabel = 'OK',
    okButtonProps
}) => {
    const [themeChoice, setThemeChoice] = React.useState(initialThemeChoice);
    const [okProcessing, setOKProcessing] = React.useState(false);
    const cancelRef = React.useRef(null);
    const inputRef = React.useRef(null);
    return (
        <Modal initialFocusRef={inputRef} isOpen={isOpen} onClose={onCancel}>
            <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>{dialogTitle}</Modal.Header>
                <Modal.Body>
                    {/* <FormControl isInvalid={false}>
                        <Radio.Group
                            name="themeChoiceRadioGroup"
                            accessibilityLabel="theme choice"
                            value={themeChoice}
                            onChange={newValue => {
                                setThemeChoice(newValue);
                            }}
                            validation={{isInvalid:false}}
                            isInvalid={false}
                        >
                            <Radio value="light" my={1} isInvalid={false}>
                                Light
                            </Radio>
                            <Radio value="dark" my={1} isInvalid={false}>
                                Dark
                            </Radio>
                            <Radio value="system" my={1} isInvalid={false}>
                                System
                            </Radio>
                        </Radio.Group>
                    </FormControl> */}
                    <FormControl>
                        <Select
                            accessibilityLabel="theme choice"
                            selectedValue={themeChoice}
                            onValueChange={newValue => setThemeChoice(newValue)}>
                            <Select.Item value='light' my={1} label="Light"></Select.Item>
                            <Select.Item value='dark' my={1} label="Dark"></Select.Item>
                            <Select.Item value='system' my={1} label="System"></Select.Item>
                        </Select>
                    </FormControl>
                </Modal.Body>
                <Modal.Footer>
                    <HStack space={2}>
                        <Button onPress={onCancel} {...cancelButtonProps} ref={cancelRef}>{cancelLabel}</Button>
                        <Button {...okButtonProps} isDisabled={okProcessing} onPress={() => {
                            const result = onOK({themeChoice});
                            if (result instanceof Promise) {
                                setOKProcessing(true);
                                const setOKProcessingFalse = setOKProcessing.bind(null, false);
                                result.then(setOKProcessingFalse, setOKProcessingFalse);
                            }
                        }}>{okLabel}</Button>
                    </HStack>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    )
}

export default ThemeChoiceDialog;
