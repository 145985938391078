import React from 'react';

import {
    Box
} from '@components/Layout';

import Bracket, { BracketSkeleton } from '@components/Bracket';

import getTournamentColors from '@utils/getTournamentColors';

import { isDevelopmentServer } from '@api';

export const delayPreviewBracketRender = () => isDevelopmentServer()

const PreviewBracket = ({tournament, preview, colors, theme}) => {
    colors = colors || getTournamentColors(tournament);

    const delay = delayPreviewBracketRender() ? 2500 : 0;

    const [isReady, setReady] = React.useState(!delay);
    React.useEffect(() => {
        const timerId = setTimeout(() => setReady(true), delay);
        return () => clearTimeout(timerId);
    });

    return (
        <Box backgroundColor={colors.backgroundColor}>
            {isReady && tournament && preview ? (
                <Bracket id={tournament.id} bracket={preview} colors={colors} theme={theme ?? tournament.theme} />
            ) : (
                <BracketSkeleton tournament={tournament} colors={colors} />
            )}
        </Box>
    )
}

export default PreviewBracket;
