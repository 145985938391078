import React from 'react';

import { Button } from '@components/Button';
import { HStack } from '@components/Layout';
import { Modal } from '@components/Modal';
import { Text } from '@components/Text';

const ConfirmDialog = ({
    size = 'sm',
    isOpen, onCancel, onConfirm,
    dialogText = 'Confirm?',
    dialogTitle = 'Confirm',
    cancelLabel = 'Cancel',
    cancelButtonProps = {variant: 'unstyled'},
    confirmLabel = 'Confirm',
    confirmButtonProps
}) => {
    const cancelRef = React.useRef(null);
    return (
        <Modal initialFocusRef={cancelRef} isOpen={isOpen} onClose={onCancel} size={size}>
            <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>{dialogTitle}</Modal.Header>
                <Modal.Body><Text>{dialogText}</Text></Modal.Body>
                <Modal.Footer>
                    <HStack space={2}>
                        <Button onPress={onCancel} {...cancelButtonProps} ref={cancelRef}>{cancelLabel}</Button>
                        <Button onPress={onConfirm} {...confirmButtonProps}>{confirmLabel}</Button>
                    </HStack>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    )
}

export default ConfirmDialog;
