import React from 'react';

import { IconButton } from '@components/Button';

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
}

const IconLink = ({icon, url, newTab=true}) => {
    return (
        <IconButton icon={icon} href={url} onPress={event => {
            event.preventDefault();
            openInNewTab(url);
        }} />
    )
}

export default IconLink;