import React from 'react';

import Bracket from '@components/Bracket';

import getTournamentColors from '@utils/getTournamentColors';

const buildEntriesMap = tournament => {
    let playersById;
    if (tournament.Players && tournament.Players.length > 0) {
      playersById = tournament.Players.reduce((byId, player) => {
        byId[player.TournamentEntry.id] = player;
        return byId;
      }, {});
    }
    let teamsById;
    if (tournament.Teams && tournament.Teams.length > 0) {
      teamsById = tournament.Teams.reduce((byId, team) => {
        byId[team.TournamentEntry.id] = team;
        return byId;
      }, {});
    }
    return Object.freeze(Object.assign({}, playersById, teamsById));
}

const getEliminationGroupWinner = (tournament, eliminationGroup) => {
    if (eliminationGroup?.winningEntryId) {
        const entriesById = buildEntriesMap(tournament);
        return entriesById[eliminationGroup.winningEntryId] || null;
    }
    return null;
}

const getTournamentWinner = (tournament, eliminationGroups) => {
    if (eliminationGroups?.length === 1) {
        return getEliminationGroupWinner(tournament, eliminationGroups[0]);
    }
    return null;
}

export { getTournamentWinner }

const TournamentBracket = props => {
    const {tournament, eliminationGroups, ...rest} = props;
    return (
        <>
            {eliminationGroups && eliminationGroups.map(eliminationGroup => {
                const winner = getEliminationGroupWinner(tournament, eliminationGroup);
                return <Bracket key={eliminationGroup.id}
                    id={eliminationGroup.id}
                    bracket={eliminationGroup.bracket}
                    winner={winner}
                    colors={getTournamentColors(tournament)}
                    theme={tournament.theme}
                    {...rest}
                />
            })}
        </>
    )
}

export default TournamentBracket;
