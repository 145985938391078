import React from 'react';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { exchangeCodeAsync, makeRedirectUri, useAuthRequest, ResponseType, TokenResponse } from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { Platform } from 'react-native';

import { loginRequest, loginSuccess, loginFailure, logoutRequest, logoutSuccess } from '@actions/auth';

import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, POST_LOGOUT_URL } from '@env';

import authReducer from './authReducer';

import { loadStoredTokens, storeTokens, clearStoredTokens } from './authTokenStore';

import generateNonce from '@utils/generateNonce';

// REFERENCE https://gist.github.com/thedewpoint/181281f8cbec10378ecd4bb65c0ae131

const AuthContext = createContext();

const getAuth0Config = () => {
    const domain = AUTH0_DOMAIN ?? process.env.AUTH0_DOMAIN;
    const clientId = AUTH0_CLIENT_ID ?? process.env.AUTH0_CLIENT_ID;
    return {
        domain,
        clientId,
        authorizationEndpoint: `https://${domain}/authorize`,
        tokenEndpoint: `https://${domain}/oauth/token`
    }
}

const getRedirectUri = () => {
    if (Platform.OS === 'web') {
        return makeRedirectUri({
            path: `/${Platform.OS}/callback`
        })
    } else {
        const { domain } = getAuth0Config();
        const scheme = 'byb';
        return makeRedirectUri({
            scheme,
            path: `${domain}/${Platform.OS}/${scheme}/callback`
        })
    }
}

WebBrowser.maybeCompleteAuthSession();

const loginAuthSession = async (request, result, dispatch) => {
    dispatch(loginRequest());

    const { clientId, tokenEndpoint } = getAuth0Config();

    let tokenConfig;
    if (result && result.type === 'success') {
        if (result.params.code) {
            const codeResponse = await exchangeCodeAsync({
                code: result.params.code,
                redirectUri: getRedirectUri(),
                clientId,
                extraParams: {
                    code_verifier: request.codeVerifier
                }
            }, {
                tokenEndpoint
            });

            tokenConfig = codeResponse.getRequestConfig();

            console.debug('Exchanged code for tokens', tokenConfig);
        } else if (result.authentication) {
            tokenConfig = Object.assign({}, result.authentication);
            console.debug("Authorized without code exchange", tokenConfig);
        } else {
            // This could be some other success case to implement
            console.debug('Authorized', result);
        }
    } else {
        tokenConfig = null;
    }

    if (tokenConfig) {
        dispatch(loginSuccess(tokenConfig));
    } else {
        dispatch(loginFailure());
    }

    return tokenConfig;
}

const loginSilentlyWithStoredTokens = async ({dispatch}) => {
    dispatch(loginRequest());

    const { clientId, tokenEndpoint } = getAuth0Config();

    let tokenConfig = await loadStoredTokens();
    if (tokenConfig) {
        console.debug('Loaded stored tokens', tokenConfig);
        let tokenResponse = new TokenResponse(tokenConfig);

        if (!TokenResponse.isTokenFresh(tokenResponse)) {
            console.debug('Refreshing tokens');
            try {
                tokenResponse = await tokenResponse.refreshAsync({
                    clientId,
                    refreshToken: tokenConfig.refreshToken
                }, {
                    tokenEndpoint
                });

                tokenConfig = tokenResponse.getRequestConfig();

                console.debug('Refreshed tokens', tokenConfig);
            } catch (err) {
                console.error(err);
                tokenConfig = null;
            }
        }
    }

    if (tokenConfig) {
        dispatch(loginSuccess(tokenConfig));
    } else {
        dispatch(loginFailure());
    }

    return Boolean(tokenConfig);
}

const loginSilentlyWithIframe = async ({dispatch, timeoutSecs = 5} = {}) => {
    dispatch(loginRequest());

    const nonce = await generateNonce();

    return new Promise((resolve, reject) => {
        const iframe = window.document.createElement('iframe');
        iframe.setAttribute('width', '0');
        iframe.setAttribute('height', '0');
        iframe.style.display = 'none';

        let messageHandler;

        const removeIframe = () => {
            if (window.document.body.contains(iframe)) {
                window.document.body.removeChild(iframe);
                window.removeEventListener('message', messageHandler);
            }
        };
        
        const { clientId, authorizationEndpoint } = getAuth0Config();

        const silentAuthURL = authorizationEndpoint + '?' + new URLSearchParams({
            response_type: 'token',
            client_id: clientId,
            redirect_uri: getRedirectUri(),
            scope: 'openid profile offline_access',
            nonce,
            audience: 'https://backyardbracket.com/api',
            response_mode: 'web_message',
            prompt: 'none'
        });

        const timeoutTimerId = setTimeout(() => {
            dispatch(loginFailure());
            removeIframe();
            resolve(false);
        }, timeoutSecs * 1000);

        messageHandler = event => {
            if (!event.data || event.data.type !== 'authorization_response') {
                return;
            }

            let result = false;
            if (event.data.response.error) {
                dispatch(loginFailure());
            } else {
                dispatch(loginSuccess({
                    accessToken: event.data.response.access_token,
                    tokenType: event.data.response.token_type
                }));
                result = true;
            }

            clearTimeout(timeoutTimerId);
            removeIframe();
            resolve(result);
        }

        window.addEventListener('message', messageHandler);
        window.document.body.appendChild(iframe);

        iframe.setAttribute('src', silentAuthURL);
    })
}

const getLogoutReturnToUrl = () => {
    if (Platform.OS === 'web') {
        return POST_LOGOUT_URL ?? 'https://backyardbracket.com/';
    } else {
        return getRedirectUri();
    }
}

const logout = async (dispatch) => {
    dispatch(logoutRequest());

    const { domain, clientId } = getAuth0Config();

    // Adapted from this example for logging out
    // https://github.com/expo/auth0-example/issues/25#issuecomment-468533295
    const returnTo = encodeURIComponent(getLogoutReturnToUrl());
    let logoutUrl = `https://${domain}/v2/logout?federated&client_id=${clientId}&returnTo=${returnTo}`;

    await WebBrowser.openBrowserAsync(logoutUrl);

    dispatch(logoutSuccess());
}

export const AuthProvider = ({ children }) => {
    const [ authState, authDispatch ] = useReducer(authReducer, {
        loading: false,
        accessToken: null,
        idToken: null,
        refreshToken: null,
        tokenType: 'Bearer'
    });

    const redirectUri = getRedirectUri();

    const { clientId, authorizationEndpoint } = getAuth0Config();

    const [request, result, promptAsync] = useAuthRequest({
        responseType: ResponseType.Code,
        redirectUri,
        clientId,
        scopes: ['openid', 'profile', 'email', 'offline_access'],
        extraParams: {
          audience: 'https://backyardbracket.com/api',
          access_type: "offline"
        },
    }, {
        authorizationEndpoint
    });

    useEffect(() => {
        (async () => {
            if (result) {
                const tokenConfig = await loginAuthSession(request, result, authDispatch);
                if (tokenConfig) {
                    await storeTokens(tokenConfig);
                } else {
                    await clearStoredTokens();
                }
            }
        })();
    }, [request, result]);

    const loginAndStore = async () => {
        promptAsync();
    }

    const logoutAndClear = async () => {
        await logout(authDispatch);
        await clearStoredTokens();
    }

    const authHeader = authState.tokenType && authState.accessToken ? authState.tokenType + ' ' + authState.accessToken : '';

    const loginSilently = async () => {
        if (Platform.OS === 'web') {
            return loginSilentlyWithIframe({dispatch: authDispatch})
        } else {
            return loginSilentlyWithStoredTokens({dispatch: authDispatch})
        }
    }

    return (
        <AuthContext.Provider value={{
            isAuthenticated: Boolean(authState.accessToken),
            isAuthenticating: authState.loading,
            authHeader,
            login: loginAndStore,
            logout: logoutAndClear,
            loginSilently
        }}>
            { children }
        </AuthContext.Provider>
    );
};

export default function useAuth() {
    return useContext(AuthContext);
}
