import React from 'react';

import useAuth from '@hooks/useAuth';

import * as tournamentAPI from '@api/tournament';

import * as ActionTypes from '@actions/actionTypes';

import tournamentListReducer from './tournamentListReducer';

const defaultInitialState = Object.freeze({
    fetching: false,
    tournaments: null,
    previews: {},
    eliminationGroups: {}
})

const TournamentListContext = React.createContext({
    state: defaultInitialState,
    dispatch: () => {}
});

export const TournamentListProvider = ({ children, initialState }) => {
    const [ state, dispatch ] = React.useReducer(tournamentListReducer, initialState || defaultInitialState);

    const [ authorizedAPI, setAuthorizedAPI ] = React.useState(false);
    const [ API, setAPI ] = React.useState({})

    const { authHeader } = useAuth();

    React.useEffect(() => {
        if (authHeader) {
            setAPI(createTournamentAPI(authHeader, dispatch))
            setAuthorizedAPI(true)
        } else if (authorizedAPI) {
            setAPI({})
        }
    }, [authHeader])

    return (
        <TournamentListContext.Provider value={{
            API,
            state,
            dispatch
        }}>
            { children }
        </TournamentListContext.Provider>
    );
};

export const useTournamentListState = () => {
    const { state } = React.useContext(TournamentListContext);
    const { tournaments, fetching } = state;
    return { tournaments, fetching };
}

export const useSelectedTournament = () => {
    const { state } = React.useContext(TournamentListContext);
    const { tournaments, selectedTournament } = state;
    return tournaments && selectedTournament && tournaments.find(t => t.id === selectedTournament.id);
}

export const useTournament = (id) => {
    const { state } = React.useContext(TournamentListContext);
    const { tournaments } = state;
    return tournaments && tournaments.find(t => t.id === id || t.shortId === id);
}

export const usePreview = (id) => {
    const { state } = React.useContext(TournamentListContext);
    return id && state.previews && state.previews[id]
}

export const useEliminationGroups = (id) => {
    const { state } = React.useContext(TournamentListContext);
    return id && state.eliminationGroups && state.eliminationGroups[id]
}

export const useTournamentListActions = () => {
    const { dispatch } = React.useContext(TournamentListContext);
    return {
        selectTournament: tournament => {
            dispatch({type: ActionTypes.SET_SELECTED_TOURNAMENT, tournament});
        }
    }
}

const createTournamentAPI = (authHeader, dispatch) => {
    return {
        fetchTournament: async (tournamentId) => {
            dispatch({type: ActionTypes.FETCH_TOURNAMENT_REQUEST});
            const tournament = await tournamentAPI.fetchTournament(authHeader, tournamentId);
            if (tournament) {
                dispatch({type: ActionTypes.FETCH_TOURNAMENT_SUCCESS, tournament});
            } else {
                dispatch({type: ActionTypes.FETCH_TOURNAMENT_FAILURE});
            }
            return tournament;
        },
        fetchTournaments: async (currentUser) => {
            dispatch({type: ActionTypes.FETCH_TOURNAMENTS_REQUEST});
            const tournaments = await tournamentAPI.fetchTournaments(authHeader, currentUser.id);
            if (tournaments) {
                dispatch({type: ActionTypes.FETCH_TOURNAMENTS_SUCCESS, tournaments});
            } else {
                dispatch({type: ActionTypes.FETCH_TOURNAMENTS_FAILURE});
            }
            return tournaments;
        },
        createTournament: async (tournamentFields, {select = true} = {}) => {
            const tournament = await tournamentAPI.createTournament(authHeader, tournamentFields);
            if (tournament) {
                dispatch({type: ActionTypes.ADD_TOURNAMENT, tournament});
                if (select) {
                    dispatch({type: ActionTypes.SET_SELECTED_TOURNAMENT, tournament});
                }
            }
            return tournament;
        },
        cloneTournament: async (tournament, {select = true} = {}) => {
            const clone = await tournamentAPI.cloneTournament(authHeader, tournament);
            if (clone) {
                const fullClone = await tournamentAPI.fetchTournament(authHeader, clone.id);
                dispatch({type: ActionTypes.ADD_TOURNAMENT, tournament: fullClone});
                if (select) {
                    dispatch({type: ActionTypes.SET_SELECTED_TOURNAMENT, tournament: fullClone});
                }
                return fullClone;
            } else {
                return null;
            }
        },
        deleteTournament: async tournamentOrId => {
            const tournamentId = tournamentOrId?.id ?? tournamentOrId;
            const result = await tournamentAPI.deleteTournamentById(authHeader, tournamentId);
            if (result) {
                dispatch({type: ActionTypes.REMOVE_TOURNAMENT_BYID, tournamentId});
            }
            return result;
        },
        deleteTournaments: async tournamentIds => {
            const results = await Promise.all(tournamentIds.map(id => tournamentAPI.deleteTournamentById(authHeader, id)));
            const deleted = tournamentIds.filter((t, i) => results[i]);
            dispatch({type: ActionTypes.REMOVE_TOURNAMENT_BYID, tournamentId: deleted});
            return deleted.length;
        },
        addEntry: async (tournamentId, {name, type}) => {
            const entry = await tournamentAPI.createAndAddEntry(authHeader, {id:tournamentId}, { name, type });
            if (entry) {
                dispatch({type: ActionTypes.TOURNAMENT_ADD_ENTRY, tournamentId, entry});
            }
            return entry;
        },
        removeEntry: async (tournamentId, {id, type}) => {
            console.assert(typeof id !== 'undefined');
            const result = await tournamentAPI.removeEntry(authHeader, {id:tournamentId}, {id, type});
            if (result) {
                dispatch({type: ActionTypes.TOURNAMENT_REMOVE_ENTRY, tournamentId, id});
            }
            return result;
        },
        setSeedingOrder: async (tournamentId, seeding) => {
            const result = await tournamentAPI.setSeeding(authHeader, {id:tournamentId}, seeding);
            if (result) {
                dispatch({type: ActionTypes.TOURNAMENT_SET_SEEDING, tournamentId, seeding});
            }
            return result;
        },
        updateTournament: async (tournamentId, fields) => {
            const result = await tournamentAPI.updateTournament(authHeader, {id:tournamentId}, fields);
            if (result) {
                dispatch({type: ActionTypes.TOURNAMENT_UPDATE, tournamentId, ...fields});
            }
            return result;
        },
        fetchPreview: async (tournamentId) => {
            dispatch({type: ActionTypes.FETCH_PREVIEW_REQUEST});
            const preview = await tournamentAPI.fetchPreview(authHeader, {id:tournamentId});
            if (preview) {
                dispatch({type: ActionTypes.FETCH_PREVIEW_SUCCESS, tournamentId, preview});
            } else {
                dispatch({type: ActionTypes.FETCH_PREVIEW_FAILURE});
            }
            return preview;
        },
        fetchEliminationGroups: async (tournament) => {
            dispatch({type: ActionTypes.FETCH_ELIMINATION_GROUPS_REQUEST});
            const eliminationGroups = await tournamentAPI.fetchEliminationGroups(authHeader, tournament);
            if (eliminationGroups) {
                dispatch({type: ActionTypes.FETCH_ELIMINATION_GROUPS_SUCCESS, tournament, eliminationGroups});
            } else {
                dispatch({type: ActionTypes.FETCH_ELIMINATION_GROUPS_FAILURE});
            }
            return eliminationGroups;
        },
        updateMatchScore: async ({tournament, match, score, isFinal = false}) => {
            dispatch({type: ActionTypes.UPDATE_MATCH_SCORE_REQUEST});
            const result = await tournamentAPI.updateMatchScore(authHeader, match, score, isFinal);
            if (result) {
                dispatch({type: ActionTypes.UPDATE_MATCH_SCORE_SUCCESS, tournament, match, score, isFinal});
            } else {
                dispatch({type: ActionTypes.UPDATE_MATCH_SCORE_FAILURE});
            }
            return result;
        },
        advanceWinners: async (tournament) => {
            dispatch({type: ActionTypes.ADVANCE_WINNERS_REQUEST});
            const result = await tournamentAPI.advanceWinners(authHeader, tournament);
            if (result) {
                dispatch({type: ActionTypes.ADVANCE_WINNERS_SUCCESS, tournament});
            } else {
                dispatch({type: ActionTypes.ADVANCE_WINNERS_FAILURE});
            }
            return result;
        },
        startTournament: async (tournament, ...args) => {
            dispatch({type: ActionTypes.START_TOURNAMENT_REQUEST});
            const result = await tournamentAPI.startTournament(authHeader, tournament, ...args);
            if (result) {
                dispatch({type: ActionTypes.START_TOURNAMENT_SUCCESS});
            } else {
                dispatch({type: ActionTypes.START_TOURNAMENT_FAILURE});
            }
            return result;
        }
    }
}

export const useTournamentAPI = () => {
    const { API } = React.useContext(TournamentListContext);
    return API
}

export const useFetchTournament = () => {
    const { API } = React.useContext(TournamentListContext);
    return API?.fetchTournament;
}

export const useFetchTournaments = () => {
    const { API } = React.useContext(TournamentListContext);
    return API?.fetchTournaments;
}
