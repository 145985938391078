import React from 'react';

import { Platform } from 'react-native';

import Purchases from 'react-native-purchases';

import useCurrentUser from '@hooks/useCurrentUser';

import { REVENUECAT_GOOGLE_API_KEY, REVENUECAT_APPLE_API_KEY } from '@env';

const TournamentProductsContext = React.createContext({
    packages: []
});

export const TournamentProductsProvider = (props) => {
    // eslint-disable-next-line react/prop-types
    const { children } = props;

    const { currentUser } = useCurrentUser();

    const [configured, setConfigured] = React.useState(false);
    const [productAPI, setProductAPI] = React.useState();
    const [packages, setPackages] = React.useState([]);

    React.useEffect(() => {
        const apiKey = props.apiKey || Platform.select({
            android: REVENUECAT_GOOGLE_API_KEY ?? process.env.REVENUECAT_GOOGLE_API_KEY,
            ios: REVENUECAT_APPLE_API_KEY ?? process.env.REVENUECAT_APPLE_API_KEY,
            default: null
        })

        if (!apiKey || Platform.OS === 'web') {
            // In-app purchases not supported on web version
            setProductAPI({
                getProductDetails: () => null,
                isPurchaseRequired: (productId) => productId && productId !== 'basic',
                purchaseProduct: async () => null
            })
            setConfigured(true);
        }
        else if (currentUser && !configured) {
            Purchases.setLogLevel(Purchases.LOG_LEVEL.VERBOSE);

            Purchases.configure({
                apiKey,
                appUserID: currentUser.id
            })

            const getPackages = async () => {
                try {
                    const offerings = await Purchases.getOfferings();
                    if (offerings?.current !== null && offerings.current.availablePackages.length > 0) {
                        const pkgs = offerings.current.availablePackages;
                        setPackages(pkgs);

                        setProductAPI({
                            getProductDetails: (productId) => {
                                const pkg = pkgs.find(p => p.product.identifier === productId)
                                return pkg && packageToProduct(pkg);
                            },
                            isPurchaseRequired: (productId) => {
                                const pkg = pkgs.find(p => p.product.identifier === productId)
                                return pkg && pkg.product.price > 0;
                            },
                            purchaseProduct: async (productId) => {
                                const pkg = pkgs.find(p => p.product.identifier === productId)
                                if (!pkg) {
                                    throw new Error(`Package for product ${productId} not found`)
                                }
                                const result = await Purchases.purchasePackage(pkg);
                                return result;
                            }
                        })
                    }
                } catch (e) {
                    // Alert.alert('Error getting offers', e.message);
                    console.error(e);
                }
            };

            getPackages();
            setConfigured(true);
        }
    }, [currentUser]);

    return (
        <TournamentProductsContext.Provider value={{
            packages,
            productAPI
        }}>
            { children }
        </TournamentProductsContext.Provider>
    );
};

// Product titles from RevenueCat API have the app name appended in parentheses
// like "Tournament product title (Backyard Bracket)"
const removeTrailingParentheses = s => {
    const iOpenParen = s.lastIndexOf('(');
    const iCloseParen = s.indexOf(')', iOpenParen);
    if (iOpenParen !== -1 && iCloseParen !== -1) {
        return s.substring(0, iOpenParen).trimEnd();
    } else {
        return s;
    }
}

const packageToProduct = pkg => {
    const { product } = pkg;
    return {
        id: product.identifier,
        description: product.description,
        price: product.price,
        priceString: product.priceString,
        currencyCode: product.currencyCode,
        title: removeTrailingParentheses(product.title)
    }
}

export const useTournamentProducts = () => {
    const { packages } = React.useContext(TournamentProductsContext);
    return packages?.map(packageToProduct)
}

export const useProductAPI = () => {
    const context = React.useContext(TournamentProductsContext);
    return context.productAPI
}

export const getTournamentProduct = (tournament) => tournament?.options?.productSelected
