import * as _SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';

import splitString from '@utils/splitString';

const MAX_TOKENCONFIG_PARTS = 10;

const useSecureStore = Platform.select({
    web: false,
    default: true
});

export const loadStoredTokens = async (SecureStore = _SecureStore) => {
    if (!useSecureStore) {
        return null;
    }

    try {
        let p = [];
        for (let i = 0; i < MAX_TOKENCONFIG_PARTS; ++i) {
            p.push(SecureStore.getItemAsync(`tokenConfig-${i}`));
        }
        const parts = await Promise.all(p);
        return JSON.parse(parts.join(''));
    } catch(err) {
        return null;
    }
}

export const storeTokens = async (tokens, SecureStore = _SecureStore) => {
    if (!useSecureStore) {
        return;
    }
    const parts = splitString(JSON.stringify(tokens), 2048);
    await Promise.all(parts.map((part, index) => SecureStore.setItemAsync(`tokenConfig-${index}`, part)));
}

export const clearStoredTokens = async (SecureStore = _SecureStore) => {
    if (!useSecureStore) {
        return;
    }
    let p = [];
    for (let i = 0; i < MAX_TOKENCONFIG_PARTS; ++i) {
        p.push(SecureStore.deleteItemAsync(`tokenConfig-${i}`));
    }
    await Promise.all(p);
}
