import React from 'react';
import { Button } from '@components/Button';
import { Pressable, StyleSheet, View } from 'react-native';

const FloatingActionButton = (props) => {
    const { children, icon, label, onPress, ...rest } = props;
    const [isOpen, setOpen] = React.useState(false);

    const buttonPressHandler = () => {
        if (children) {
            setOpen(!isOpen);
        } else if (typeof onPress === 'function') {
            onPress();
        }
    }

    const button = (
        <Button style={styles.button} onPress={buttonPressHandler} leftIcon={icon} p={3} rounded={'full'} shadow={8} {...rest}>
            {label}
        </Button>);

    if (children && isOpen) {
        const actionButtons = (Array.isArray(children) ? children : [children]).map(({props}, index) => {
            const { icon, label, onPress, ...rest } = props;
            return <Button key={index} size={'md'} mb={2} leftIcon={icon} {...rest} onPress={() => {
                setOpen(false);
                onPress();
            }}>
                {label}
            </Button>
        })

        return <Pressable style={styles.overlay} onPress={() => setOpen(false)}>
            <View style={styles.root}>
                <View style={styles.container}>
                    {actionButtons}
                </View>
                {button}
            </View>
        </Pressable>
    } else {
        return <View style={styles.root}>{button}</View>
    }
}

FloatingActionButton.Action = ({icon, label, onPress}) => {
    return {
        icon,
        label,
        onPress
    }
}

export default FloatingActionButton;

const styles = StyleSheet.create({
    root: {
        position: "absolute",
        right: 16,
        bottom: 48
    },
    overlay: {
        position: "absolute",
        width: "100%",
        height: "100%"
    },
    container: {
        marginBottom: 8
    },
    button: {
        alignSelf: "flex-end"
    }
})