import * as ActionTypes from '@actions/actionTypes';

export const updatePlayer = (player) => {
    return {
        type: ActionTypes.UPDATE_PLAYER,
        ...player
    }
}

export const updateSettings = (settings) => {
    return {
        type: ActionTypes.UPDATE_SETTINGS,
        settings
    }
}
