import React from 'react';

import { Pressable, StyleSheet, View } from 'react-native'

import blackOrWhite from '@utils/blackOrWhite';

import { CheckmarkIcon } from '@components/Icons';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    button: {
        margin: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1
    },
    pressed: {
        borderWidth: 2
    }
});

const ColorSwatchButton = ({color, selected, size = 40, ...rest}) => {
    const localStyle = {
        width: size,
        height: size,
        backgroundColor: color,
        borderRadius: Math.floor(size * 0.2)
    }
    return <Pressable
        role='radio'
        accessibilityHint={color}
        accessibilityState={{selected}}
        style={({pressed}) => pressed ? [styles.button, localStyle, styles.pressed] : [styles.button, localStyle]}
        {...rest}>
        {selected && <CheckmarkIcon size={'sm'} color={blackOrWhite(color)} />}
    </Pressable>
}

const ColorSwatchPicker = ({colors, selectedColor, onColorChange, disabled = false, buttonOptions}) => {
    return (
        <View style={styles.container} accessibilityRole='radiogroup'>
            {colors.map((color, index) => {
                return <ColorSwatchButton key={index} color={color} {...buttonOptions}
                    disabled={disabled}
                    selected={color === selectedColor}
                    onPress={() => onColorChange(color)} />
            })}
        </View>
    )
}

export default ColorSwatchPicker;
