import React, { createContext, useEffect } from 'react';

import * as ActionTypes from '@actions/actionTypes';
import fetchOrCreateCurrentUser from '@api/fetchOrCreateCurrentUser';
import { updateUser } from '@api/user';
import { updatePlayer } from '@actions/currentUser';
import fetchUserInfo from '@api/fetchUserInfo';

import { createOrUpdateUserPlayer } from '@api/player';

import useAuth from '@hooks/useAuth';

import currentUserReducer from './currentUserReducer';
import { updateSettings } from '@actions/currentUser';

const CurrentUserContext = createContext();

export const CurrentUserProvider = ({ children, user }) => {
    const [ state, dispatch ] = React.useReducer(currentUserReducer, {
        fetching: false,
        error: null,
        user: user || null
    });

    const { authHeader } = useAuth();

    useEffect(() => {
        (async () => {
            if (!user && authHeader) {
                const user = await fetchOrCreateCurrentUser(dispatch, authHeader);
                if (user) {
                    if (!user.profile) {
                        const profile = await fetchUserInfo(() => {}, authHeader);
                        if (profile) {
                            const result = await updateUser(authHeader, user, {
                                profile
                            })
                            if (result) {
                                dispatch({type: ActionTypes.FETCH_USER_SUCCESS, user: result})
                            }
                        }
                    }

                    const player = await createOrUpdateUserPlayer(authHeader, user);
                    if (player) {
                        dispatch(updatePlayer(player));
                    }
                }
            }
        })();
    }, [authHeader]);

    return (
        <CurrentUserContext.Provider value={{
            ...state,
            dispatch
        }}>
            { children }
        </CurrentUserContext.Provider>
    );
};

export default function useCurrentUser() {
    const context = React.useContext(CurrentUserContext);
    return {
        currentUser: context.user
    };
}

export const useCurrentUserActions = (authHeader) => {
    const { dispatch, user } = React.useContext(CurrentUserContext);
    return {
        updatePlayer: async (player) => {
            const result = await createOrUpdateUserPlayer(authHeader, user, player).then(() => {
                dispatch(updatePlayer(player));
            });
            return result;
        },
        updateSettings: async (settings) => {
            const result = await updateUser(authHeader, user, {settings}).then(() => {
                dispatch(updateSettings(settings));
            })
            return result;
        }
    }
}
