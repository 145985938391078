import * as ActionTypes from '@actions/actionTypes';

export const fetchUserInfoRequest = () => {
    return {
        type: ActionTypes.FETCH_USERINFO_REQUEST
    }
}

export const fetchUserInfoSuccess = userInfo => {
    return {
        type: ActionTypes.FETCH_USERINFO_SUCCESS,
        userInfo
    }
}

export const fetchUserInfoFailure = (error) => {
    return {
        type: ActionTypes.FETCH_USERINFO_FAILURE,
        error
    }
}
