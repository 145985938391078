import React from 'react';

import {
  ScrollView
} from 'react-native';

import useOpenCloseState from '@utils/useOpenCloseState';

import {
  Divider,
  HStack,
  Stack,
  VStack
} from '@components/Layout';

import { Input } from '@components/Form';

import { capitalize } from 'lodash';

import useAuth from '@hooks/useAuth';
import useCurrentUser, { useCurrentUserActions, useCurrentUserDispatch } from '@hooks/useCurrentUser';

import ResponsiveContainer from '@components/ResponsiveContainer';
import UserAvatar from '@components/UserAvatar';

import { Button } from '@components/Button';
import { Heading, Text } from '@components/Text';

import AvatarChoiceDialog from './AvatarChoiceDialog';
import ConfirmDialog from '@components/ConfirmDialog';
import ThemeChoiceDialog from './ThemeChoiceDialog';

import { requestDeleteUser, confirmDeleteUser } from '@api/user';

const SettingsSection = ({title, children}) => {
  return (
    <>
      {title && <Heading mt={4} mb={2}>{title}</Heading>}
      <Divider />
      <Stack my={8}>
        <Stack flex={1} direction={{base:'column', md:'row'}} space={{base:2, md:0}}>
          {children}
        </Stack>
      </Stack>
    </>
  )
}

const isFacebookProfile = (userProfile) => Boolean(userProfile?.provider?.startsWith('facebook'))
const isGoogleProfile = (userProfile) => Boolean(userProfile?.provider?.startsWith('google') || userProfile?.sub?.startsWith('google'))

const getProfileText = (user) => {
  let profileText;
  if (isFacebookProfile(user.profile)) {
    profileText = 'Signed in with Facebook';
  } else if (isGoogleProfile(user.profile)) {
    profileText = 'Signed in with Google';
  } else {
    profileText = 'Signed in with e-mail address';
  }
  return profileText;
}

const getThemeText = (user, defaultTheme = 'light') => {
  const theme = user && user.settings && user.settings.theme;
  return theme ?? defaultTheme;
}

const Settings = () => {
  const { logout, authHeader } = useAuth();
  const { currentUser } = useCurrentUser();
  const { updatePlayer, updateSettings } = useCurrentUserActions(authHeader);
  const [ accountDeletePending, setAccountDeletePending ] = React.useState(false);

  const accountDeleteConfirmInputRef = React.useRef(null);

  const onLogout = async () => {
    await logout();
  }

  const onPressDeleteAccount = () => {
    try {
      if (accountDeletePending) {
        const confirmationCode = accountDeleteConfirmInputRef.current.value;
        confirmDeleteUser(authHeader, currentUser, confirmationCode).then(success => {
          if (success) {
            logout();
          }
        });
      } else {
        requestDeleteUser(authHeader).then(success => {
          if (success) {
            setAccountDeletePending(true);
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  const {
    isOpen: isAccountDeleteConfirmOpen,
    onOpen: openAccountDeleteConfirm,
    onClose: closeAccountDeleteConfirm
  } = useOpenCloseState();

  const {
    isOpen: isThemeChoiceDialogOpen,
    onOpen: openThemeChoiceDialog,
    onClose: closeThemeChoiceDialog
  } = useOpenCloseState();

  const {
    isOpen: isAvatarChoiceDialogOpen,
    onOpen: openAvatarChoiceDialog,
    onClose: closeAvatarChoiceDialog
  } = useOpenCloseState();

  const onAvatarChoiceOK = React.useCallback(({avatarChoice}) => {
    const updateData = {
      avatar: {
        avatarChoice,
        picture: currentUser.profile.picture,
        nickname: currentUser.profile.nickname,
        displayName: currentUser.profile.displayName
      }
    }
    return updatePlayer(updateData).then(() => {
      // toast
      closeAvatarChoiceDialog();
    })
  }, [authHeader, currentUser]);

  return (
    <ScrollView>
      <ResponsiveContainer mx={1}>
        {/*
        <SettingsSection title={'Theme'}>
          <VStack flex={1} space={1}>
            <Text fontSize={'xl'} accessibilityHint='current theme'>{capitalize(getThemeText(currentUser))}</Text>
          </VStack>
          <Box>
            <Button onPress={openThemeChoiceDialog} accessibilityHint='change theme'>Change</Button>
          </Box>
        </SettingsSection> */}
        <SettingsSection title={'Player profile'}>
          <VStack flex={1} space={1}>
            <Text fontSize={'xl'}>{currentUser && currentUser.Player && currentUser.Player.name}</Text>
            <UserAvatar user={currentUser} size={'lg'} />
          </VStack>
          <Button onPress={openAvatarChoiceDialog} alignSelf={'flex-end'} accessibilityHint='change avatar'>Change</Button>
        </SettingsSection>
        <SettingsSection title={'Account'}>
          <HStack space={2} flex={1}>
            <VStack space={1} flex={1}>
              <Text fontSize={'xl'}>{currentUser && currentUser.profile && currentUser.profile.displayName}</Text>
              <Text>{getProfileText(currentUser)}</Text>
            </VStack>
            <Button onPress={onLogout} alignSelf={'flex-end'}>Sign out</Button>
          </HStack>
        </SettingsSection>
        <SettingsSection>
          <HStack alignItems={'center'} flex={1}>
            {accountDeletePending ? (
              <VStack flex={1}>
                <Text mb={1}>A confirmation code has been sent to your email address on file</Text>
                <HStack>
                  <Text fontSize={'lg'} mr={3}>Confirmation:</Text>
                  <Input ref={accountDeleteConfirmInputRef} testID={'account-delete-confirm-input'}></Input>
                </HStack>
              </VStack>
            ) : (
              <Text flex={1}>Request account deletion</Text>
            )}
            <Button colorScheme={"red"} onPress={openAccountDeleteConfirm} alignSelf={'flex-end'}>Delete account</Button>
          </HStack>
        </SettingsSection>
      </ResponsiveContainer>
      <ThemeChoiceDialog
        initialThemeChoice={getThemeText(currentUser)}
        isOpen={isThemeChoiceDialogOpen}
        onCancel={closeThemeChoiceDialog}
        onOK={({themeChoice}) => {
          return updateSettings({theme:themeChoice}).then(() => {
            closeThemeChoiceDialog();
          })
        }}
      />
      <AvatarChoiceDialog
        initialAvatarChoice={currentUser?.Player?.avatar?.avatarChoice}
        dialogTitle={'Choose avatar'}
        inputLabel='Name:'
        isOpen={isAvatarChoiceDialogOpen}
        onCancel={closeAvatarChoiceDialog}
        onOK={onAvatarChoiceOK}
      />
      <ConfirmDialog
        isOpen={isAccountDeleteConfirmOpen}
        onCancel={closeAccountDeleteConfirm}
        onConfirm={() => {
          onPressDeleteAccount();
          closeAccountDeleteConfirm();
        }}
        dialogTitle={'Confirm delete'}
        dialogText={'Delete account?'}
        confirmLabel={'Delete'}
        confirmButtonProps={{colorScheme: 'red'}}
      />
    </ScrollView>
  );
};

export default Settings;