const buildTournamentEntriesList = tournament => {
    let players = tournament.Players || [];
    let teams = tournament.Teams || [];
    return players.concat(teams).map(p => {
        return {
            name: p.name,
            participantId: p.id,
            participantType: p.TournamentEntry.ParticipantType.toLowerCase(),
            entryId: p.TournamentEntry.id,
            seed: p.TournamentEntry.seed
        }
    }).sort((a, b) => a.seed - b.seed);
}

export default buildTournamentEntriesList;