import React from 'react';

export { MenuProvider } from 'react-native-popup-menu';

import { merge } from 'lodash';

import {
    Menu as _Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
    renderers
} from 'react-native-popup-menu';

import { buildPropsStyle } from './Theme';

const menuDefaults = Object.freeze({
    font: 'body',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: 'sm',
    p: '1.5'
})

export const Menu = props => {
    const { placement = '', trigger, triggerProps, children, ...rest } = props;

    const [menuPlacement,] = placement && placement.split(' ');

    const { style: textStyle, nonStyleProps } = buildPropsStyle(merge({}, menuDefaults, rest));

    return (
        <_Menu renderer={renderers.Popover} rendererProps={{placement: menuPlacement}} {...nonStyleProps}>
            <MenuTrigger customStyles={{
                TriggerTouchableComponent: typeof trigger !== 'string' ? trigger : undefined,
                triggerTouchable: triggerProps
            }}>
            </MenuTrigger>
            <MenuOptions>
                {children.map(({props}, index) => {
                    const { children, onPress, isDisabled = false, testID } = props;
                    return (
                        <MenuOption
                            key={`item${index}`}
                            disabled={isDisabled}
                            text={typeof children === 'string' ? children : undefined}
                            customStyles={{
                                optionText: textStyle
                            }}
                            onSelect={onPress}
                            testID={testID}>
                            {typeof children !== 'string' ? children : undefined}
                        </MenuOption>
                    )
                })}
            </MenuOptions>
        </_Menu>
    )
}

Menu.Item = props => {
    return props
}
