/**
 * Backyard Bracket application root component with service providers
 *
 * @format
 * @flow strict-local
 */

import 'react-native-gesture-handler';

import React from 'react';

import { SafeAreaProvider } from 'react-native-safe-area-context';

import * as SplashScreen from 'expo-splash-screen';

import App from '@screens/main/App';

import { AuthProvider } from '@hooks/useAuth';
import { CurrentUserProvider } from '@hooks/useCurrentUser';

SplashScreen.preventAutoHideAsync();

const Root = () => {
    return (
        <SafeAreaProvider>
            <AuthProvider>
                <CurrentUserProvider>
                    <App />
                </CurrentUserProvider>
            </AuthProvider>
        </SafeAreaProvider>
    )
}

export default Root;
