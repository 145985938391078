import React from 'react';
import { Platform, Pressable } from 'react-native';

import * as Linking from 'expo-linking';

import { set } from 'lodash';

import { HStack } from '@components/Layout';
import { Text } from '@components/Text';

import { useThemeValue, buildPropsStyle } from '@components/Theme';

const inheritColor = Platform.select({
    web: 'inherit',
    default: null
})

const buildVariantStyle = ({ variant, colorScheme }) => {
    const [colorSchemeColors, lightTextColor, darkTextColor] = useThemeValue("colors", [colorScheme, "lightText", "darkText"]);
    const variantStyle = {
        _text: {
            color: darkTextColor
        },
        _icon: {
            color: darkTextColor
        }
    };
    switch (variant) {
        case 'link':
            set(variantStyle, '_text._hovered.textDecorationLine', 'underline');
            break;
        case 'ghost':
            variantStyle._text.color = colorSchemeColors['600'];
            set(variantStyle, '_hovered.backgroundColor', colorSchemeColors['50']);
            break;
        case 'subtle':
            variantStyle.backgroundColor = colorSchemeColors['100'];
            set(variantStyle, '_hovered.backgroundColor', colorSchemeColors['200']);
            break;
        case 'unstyled':
            // no text nor background color
            variantStyle.backgroundColor = inheritColor;
            variantStyle._text.color = inheritColor;
            break;
        case 'solid':
        default:
            variantStyle._text.color = lightTextColor;
            variantStyle.backgroundColor = colorSchemeColors['600'];
            set(variantStyle, '_hovered.backgroundColor', colorSchemeColors['700']);
            break;
    }
    return variantStyle;
}

export const Button = React.forwardRef((props, ref) => {
    const {
        children,
        leftIcon,
        variant = 'solid',
        colorScheme = 'primary',
        size = 'md',
        isDisabled = false,
        style,
        href,
        ...rest
    } = props;

    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const {
        _disabled,
        _stack,
        ...baseStyle
    } = useThemeValue('components.Button', 'baseStyle');

    const sizeStyle = useThemeValue('components.Button.sizes', size);

    const {
        _text: {
            _hovered: variantTextHovered,
            ...variantText
        },
        _hovered: variantHover,
        ...variantStyle
    } = buildVariantStyle({ variant, colorScheme });

    const renderButtonContents = ({hovered}) => {
        if (leftIcon && children) {
            return <HStack {..._stack} style={hovered ? variantTextHovered : null}>{leftIcon}<Text style={variantText}>{children}</Text></HStack>
        } else if (leftIcon) {
            return leftIcon
        } else {
            return <Text style={hovered ? [variantText, variantTextHovered] : variantText}>{children}</Text>
        }
    }

    const handlePress = href && React.useCallback(async () => {
        const canOpen = await Linking.canOpenURL(href);

        if (canOpen) {
            await Linking.openURL(href);
        }
    }, [href]);

    const styles = [{
        ...baseStyle,
        ...sizeStyle
    }, variantStyle ];

    if (propsStyle) {
        styles.push(propsStyle)
    }

    if (style) {
        styles.push(style)
    }

    if (isDisabled) {
        styles.push(_disabled);
    }

    return (
        <Pressable ref={ref} disabled={isDisabled} role='button'
            style={({hovered, pressed}) => hovered ? styles.concat(variantHover) : styles}
            onPress={handlePress ?? nonStyleProps?.onPress}
            {...nonStyleProps}>
            {renderButtonContents}
        </Pressable>
    )
})

Button.displayName = 'Button';

export const IconButton = React.forwardRef(({
    children,
    icon,
    variant = 'ghost',
    colorScheme = 'primary',
    size = 'md',
    isDisabled = false,
    style,
    ...rest
}, ref) => {
    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const baseStyle = {
        ...useThemeValue('components.Button', 'baseStyle'),
        ...useThemeValue('components.Button.sizes', size),
        ...propsStyle,
        ...style
    };
    const {
        _icon: {
            _hovered: variantIconHovered,
            ...variantIcon
        } = {},
        _hovered: variantHover,
        ...variantStyle
    } = buildVariantStyle({ variant, colorScheme });
    return (
        <Pressable ref={ref} disabled={isDisabled} role='button'
            style={({hovered, pressed}) => [baseStyle, hovered ? variantHover : variantStyle]}
            {...nonStyleProps}>
            {({hovered}) => {
                if (hovered) {
                    return <Text style={[variantIcon, variantIconHovered]}>{icon}</Text>
                } else {
                    return <Text style={variantIcon}>{icon}</Text>
                }
            }}
        </Pressable>
    )
})

IconButton.displayName = 'IconButton';
