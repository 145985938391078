import * as ActionTypes from '@actions/actionTypes';
import { findIndex, omit } from 'lodash';
import * as TournamentStatus from '@api/TournamentStatus';
import * as MatchStatus from '@api/MatchStatus';
import updateEliminationGroupScores from '@utils/updateEliminationGroupScores';

const getEntryType = entry => {
    if (typeof entry.TournamentEntry === 'undefined') {
        return null;
    }
    if (typeof entry.TournamentEntry.ParticipantType !== "string") {
        return null;
    }
    return entry.TournamentEntry.ParticipantType.toLowerCase();
}

const tournamentsSlice = (tournaments, action) => {
    switch (action.type) {
        case ActionTypes.ADD_TOURNAMENT:
            return [
                ...tournaments,
                action.tournament
            ]
        case ActionTypes.REMOVE_TOURNAMENT:
            return tournaments.filter(t => t.id !== action.tournament.id)
        case ActionTypes.REMOVE_TOURNAMENT_BYID: {
            if (Array.isArray(action.tournamentId)) {
                return tournaments.filter(t => action.tournamentId.indexOf(t.id) === -1)
            } else {
                return tournaments.filter(t => t.id !== action.tournamentId)
            }
        }
        case ActionTypes.FETCH_TOURNAMENT_SUCCESS:
            return tournaments.map(t => (t.id === action.tournament.id) ? action.tournament : t)
        case ActionTypes.FETCH_TOURNAMENTS_SUCCESS:
            return action.tournaments
        case ActionTypes.FETCH_TOURNAMENTS_FAILURE:
            return []
        case ActionTypes.TOURNAMENT_ADD_ENTRY: {
            const addToTournamentIndex = findIndex(tournaments, t => t.id === action.tournamentId);
            if (addToTournamentIndex < 0) {
                return tournaments;
            } else {
                const addToTournament = tournaments[addToTournamentIndex];
                const newEntry = action.entry;
                const newEntryType = getEntryType(action.entry);
                const isPlayer = newEntryType === "player";
                const isTeam = newEntryType === "team";
                const updated = {
                    ...addToTournament,
                };
                if (isPlayer) {
                    updated.Players = [...updated.Players, newEntry];
                } else if (isTeam) {
                    updated.Teams = [...updated.Teams, newEntry];
                } else {
                    return tournaments;
                }
                return [
                    ...tournaments.slice(0, addToTournamentIndex),
                    updated,
                    ...tournaments.slice(addToTournamentIndex + 1)
                ]
            }
        }
        case ActionTypes.TOURNAMENT_REMOVE_ENTRY:
            return tournaments.map(tournament => {
                if (tournament.id === action.tournamentId) {
                    return {
                        ...tournament,
                        Players: tournament.Players.filter(p => p.id !== action.id),
                        Teams: tournament.Teams.filter(t => t.id !== action.id)
                    }
                } else {
                    return tournament;
                }
            })
        case ActionTypes.TOURNAMENT_SET_SEEDING: {
            const updateTournamentEntrySeed = playerOrTeam => {
                return {
                    ...playerOrTeam,
                    TournamentEntry: {
                        ...playerOrTeam.TournamentEntry,
                        seed: typeof action.seeding[playerOrTeam.id] !== 'undefined' ? action.seeding[playerOrTeam.id] : playerOrTeam.TournamentEntry.seed
                    }
                }
            }
            return tournaments.map(tournament => {
                if (tournament.id === action.tournamentId) {
                    return {
                        ...tournament,
                        Players: tournament.Players.map(updateTournamentEntrySeed),
                        Teams: tournament.Teams.map(updateTournamentEntrySeed)
                    }
                } else {
                    return tournament;
                }
            })
        }
        case ActionTypes.TOURNAMENT_SET_STATUS: {
            if (TournamentStatus.isValid(action.status)) {
                return tournaments.map(tournament => {
                    if (tournament.id === action.tournamentId) {
                        return {
                            ...tournament,
                            status: action.status
                        }
                    } else {
                        return tournament;
                    }
                })
            }
            break;
        }
        case ActionTypes.TOURNAMENT_UPDATE: {
            return tournaments.map(tournament => {
                if (tournament.id === action.tournamentId) {
                    return {
                        ...tournament,
                        ...omit(action, ['type', 'tournamentId'])
                    }
                } else {
                    return tournament;
                }
            })
        }
    }
    return tournaments
}

const fetchingSlice = (fetching, action) => {
    if (/REQUEST$/.test(action.type)) {
        return true;
    } else if (/(SUCCESS|FAILURE)$/.test(action.type)) {
        return false;
    } else {
        return fetching;
    }
}

const getTournamentId = (action) => (action.tournamentId || (action.tournament && action.tournament.id))

const previewsSlice = (previews, action) => {
    const tournamentId = getTournamentId(action)
    switch (action.type) {
        case ActionTypes.FETCH_PREVIEW_SUCCESS: {
            return {
                ...previews,
                [tournamentId]: action.preview
            }
        }
        case ActionTypes.REMOVE_TOURNAMENT:
        case ActionTypes.REMOVE_TOURNAMENT_BYID:
        case ActionTypes.TOURNAMENT_ADD_ENTRY:
        case ActionTypes.TOURNAMENT_REMOVE_ENTRY: 
        case ActionTypes.TOURNAMENT_SET_SEEDING:
        case ActionTypes.ADVANCE_WINNERS_SUCCESS: {
            return tournamentId ? omit(previews, tournamentId) : previews
        }
    }
    return previews;
}

const eliminationGroupsSlice = (eliminationGroups, action) => {
    const tournamentId = getTournamentId(action)
    if (!tournamentId) {
        return eliminationGroups;
    }
    switch (action.type) {
        case ActionTypes.FETCH_ELIMINATION_GROUPS_SUCCESS: {
            return {
                ...eliminationGroups,
                [tournamentId]: action.eliminationGroups
            }
        }
        case ActionTypes.REMOVE_TOURNAMENT:
        case ActionTypes.REMOVE_TOURNAMENT_BYID:
        case ActionTypes.TOURNAMENT_ADD_ENTRY:
        case ActionTypes.TOURNAMENT_REMOVE_ENTRY:
        case ActionTypes.TOURNAMENT_SET_SEEDING:
        case ActionTypes.ADVANCE_WINNERS_SUCCESS: {
            return tournamentId ? omit(eliminationGroups, tournamentId) : eliminationGroups
        }
        case ActionTypes.UPDATE_MATCH_SCORE_SUCCESS: {
            return {
                ...eliminationGroups,
                [tournamentId]: updateEliminationGroupScores(eliminationGroups[tournamentId], action.score, action.match && {
                    [action.match.id]: action.isFinal ? MatchStatus.VERIFIED : MatchStatus.INPROGRESS
                })
            }
        }
    }
    return eliminationGroups;
}

const selectedTournamentSlice = (selectedTournament, action) => {
    if (action.type === ActionTypes.SET_SELECTED_TOURNAMENT) {
        return action.tournament
    } else {
        return selectedTournament
    }
}

export default (state, action) => {
    return {
        ...state,
        selectedTournament: selectedTournamentSlice(state.selectedTournament, action),
        tournaments: tournamentsSlice(state.tournaments, action),
        fetching: fetchingSlice(state.fetching, action),
        previews: previewsSlice(state.previews, action),
        eliminationGroups: eliminationGroupsSlice(state.eliminationGroups, action)
    }
}
