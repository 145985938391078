import React from 'react';
import PropTypes from 'prop-types';

import { useFocusEffect } from '@react-navigation/native';

import {
    Animated,
    Image,
    Platform,
    Pressable,
    StyleSheet,
    View
} from 'react-native';

import { shuffle, times } from 'lodash';

import { Text } from '@components/Text';

import { useThemeValue } from '@components/Theme';

import handDrawnBorder from '@assets/hand-drawn-border';

const bracketCellHeight = 32;
const bracketLineStyle = 'solid';
const bracketLineWidth = StyleSheet.hairlineWidth;

export const BracketThemes = Object.freeze({
    default: {},
    gameday: {
        fontFamily: 'PatuaOne_400Regular',
        borderStyle: 'dotted',
        borderWidth: 2,
        borderRightWidth: 4,
        borderRadius: 2
    },
    whiteboard: {
        imageBorder: handDrawnBorder,
        fontFamily: 'PermanentMarker_400Regular'
    }
})

const BracketThemeContext = React.createContext();

const useBracketTheme = () => React.useContext(BracketThemeContext)

const useBracketThemeTextStyle = () => {
    const { color, fontFamily } = React.useContext(BracketThemeContext);
    const textStyle = {};
    if (color) {
        textStyle.color = color;
    }
    if (fontFamily) {
        textStyle.fontFamily = fontFamily;
    }
    return textStyle;
}

const useBracketThemeBorderStyle = ({ right = false, bottom = false }) => {
    const {
        borderColor,
        borderBottomStyle = bracketLineStyle,
        borderBottomWidth = bracketLineWidth,
        borderRightStyle = bracketLineStyle,
        borderRightWidth = bracketLineWidth,
        imageBorder
    } = React.useContext(BracketThemeContext);

    const borderStyle = {};
    if (imageBorder) {
        borderStyle.imageBorder = imageBorder;
    }
    if (right) {
        borderStyle.borderRightWidth = borderRightWidth;
        borderStyle.borderRightStyle = borderRightStyle;
        if (borderColor) {
            borderStyle.borderRightColor = borderColor;
        }
    }
    if (bottom) {
        borderStyle.borderBottomWidth = borderBottomWidth;
        borderStyle.borderBottomStyle = borderBottomStyle;
        if (borderColor) {
            borderStyle.borderBottomColor = borderColor;
        }
    }

    return borderStyle;
}

const styles = StyleSheet.create({
    bracketContainer: {
        display: 'flex',
        flexDirection: 'row'
    },

    bracketColumn: {
        display: 'flex',
        justifyContent: 'space-around',
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 'auto'
    },

    bracketCellGroup: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: 'auto'
    },

    bracketCell: {
        display: 'flex',
        height: bracketCellHeight,
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        position: 'relative'
    },

    bracketCellBottomBorder: {
        flexGrow: 1,
        flexBasis: '100%',
        flexDirection: 'row',
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },

    bracketCellTextContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-end'
    }
});

const borderImageResizeMode = Platform.select({
    web: 'repeat',
    android: 'stretch'
})

const getMatchWinnerId = match => {
    if (match.resultStatus === 'verified') {
        const winner = match.contestants.reduce((a, b) => {
            return a.score > b.score ? a : b;
        });
        return winner && winner.id;
    }
    return null;
}

const renderMatchContestantDefault = ({contestant, isWinner = false, isChampion = false}) => {
    const bracketTextStyle = useBracketThemeTextStyle();
    if (contestant && typeof contestant.name) {
        return <>
            {/* <Text mr={1}>{contestant.seed}</Text> */}
            <Text fontSize={isChampion ? 'lg' : undefined} bold={isWinner} {...bracketTextStyle}>
                {contestant.name}
            </Text>
            {!isChampion && <Text ml={1} style={bracketTextStyle}>{contestant.score || 0}</Text>}
        </>
    } else {
        return null
    }
}

const BracketMatchContestant = ({
    isLower = false,
    children
}) => {
    const { color } = useBracketThemeTextStyle();
    const bracketBorderStyle = useBracketThemeBorderStyle({ right: isLower, bottom: true });
    const { imageBorder } = bracketBorderStyle;
    if (imageBorder) {
        // On Android, lower contestant text container is positioned above the bottom border
        // image which gives it a larger visual margin than the top contestant text.
        // Found no other way to correct this other than to hack it with a negative bottom margin.
        const bottomMarginCorrection = Platform.select({
            android: -imageBorder.borderBottomWidth,
            default: 0
        })
        return (
            <View style={styles.bracketCell}>
                <View style={isLower ? {flex: 1, flexDirection: 'row'} : {flex: 1}}>
                    <View style={[styles.bracketCellTextContainer, isLower ? {marginBottom:bottomMarginCorrection} : {}]}>
                        {children}
                    </View>
                    {isLower && (
                        <Image
                            source={imageBorder.borderRightImage}
                            resizeMode={borderImageResizeMode}
                            tintColor={color}
                            style={{
                                width: imageBorder.borderRightWidth,
                                height: bracketCellHeight - imageBorder.borderBottomWidth
                            }}
                        />
                    )}
                </View>
                <View style={styles.bracketCellBottomBorder}>
                    <Image
                        source={imageBorder.borderBottomImage}
                        resizeMode={borderImageResizeMode}
                        tintColor={color}
                        style={{flex: 1, height: imageBorder.borderBottomWidth}}
                    />
                    {isLower && (
                        <Image
                            source={imageBorder.borderBottomRightImage}
                            resizeMode={borderImageResizeMode}
                            tintColor={color}
                            style={{
                                width: imageBorder.borderRightWidth,
                                height: imageBorder.borderBottomWidth
                            }}
                        />
                    )}
                </View>
            </View>
        )
    } else {
        return (
            <View style={[bracketBorderStyle, styles.bracketCell]}>
                <View style={styles.bracketCellTextContainer}>
                    {children}
                </View>
            </View>
        )
    }
}

BracketMatchContestant.propTypes = {
    isLower: PropTypes.bool,
    children: PropTypes.node
};

const bracketPairVspacerHeight = (index, heightBasis) => {
    if (index > 0) {
        const x = (Math.pow(2, index) - 1);
        return x * heightBasis;
    } else {
        return 0;
    }
}

const BracketVSpacer = ({round}) => {
    const bracketBorderStyle = useBracketThemeBorderStyle({ right: true });
    const { color } = useBracketThemeTextStyle();
    const { imageBorder } = bracketBorderStyle;
    const spacerHeight = bracketPairVspacerHeight(round, bracketCellHeight);
    return imageBorder ? (
        <View style={[{ height: spacerHeight }]}>
            {(spacerHeight > 0) && (
                <Image
                    source={imageBorder.borderRightImage}
                    resizeMode={borderImageResizeMode}
                    tintColor={color}
                    style={{
                        width: imageBorder.borderRightWidth,
                        height: spacerHeight,
                        alignSelf: 'flex-end'
                    }}
                />
            )}
        </View>
    ) : (
        <View style={[bracketBorderStyle, { height: spacerHeight }]}></View>
    )
}

BracketVSpacer.propTypes = {
    round: PropTypes.number.isRequired
};

// invisible placeholder for first round
const FirstRoundPlaceholder = () => {
    return <View style={[styles.bracketCellGroup, {opacity: 0, pointerEvents: 'none'}]}>
        <BracketMatchContestant />
        <BracketVSpacer round={0} />
        <BracketMatchContestant />
    </View>
}

const renderMatchContestantPlaceholderNone = () => null

const renderMatchContestantPlaceholderDefault = ({match, round, contestantIndex}) => {
    return (
        <Text color={'muted.300'} italic>
            {`Winner of round ${round} match ${match.index * 2 + contestantIndex + 1}`}
        </Text>
    )
}

const BracketMatch = ({
    match,
    round,
    onPressMatch,
    onLongPressMatch
}) => {
    const {
        renderMatchContestant,
        renderMatchContestantPlaceholder
    } = useBracketTheme();

    const winner = getMatchWinnerId(match);

    return (
        <Pressable
            onLongPress={onLongPressMatch ?? (() => onLongPressMatch(match))}
            onPress={onPressMatch && (() => onPressMatch(match))}
        >
            <View style={styles.bracketCellGroup}>
                <BracketMatchContestant>
                    {match.contestants[0] ? renderMatchContestant({
                        contestant: match.contestants[0],
                        isWinner: winner && match.contestants[0] && winner==match.contestants[0].id,
                        isChampion: false
                    }) : renderMatchContestantPlaceholder({
                        match,
                        round,
                        contestantIndex: 0
                    })}
                </BracketMatchContestant>
                <BracketVSpacer round={round} />
                <BracketMatchContestant isLower={true}>
                    {match.contestants[1] ? renderMatchContestant({
                        contestant: match.contestants[1],
                        isWinner: winner && match.contestants[1] && winner==match.contestants[1].id,
                        isChampion: false
                    }) : renderMatchContestantPlaceholder({
                        match,
                        round,
                        contestantIndex: 1
                    })}
                </BracketMatchContestant>
            </View>
        </Pressable>
    )
}

BracketMatch.propTypes = {
    match: PropTypes.object.isRequired,
    round: PropTypes.number.isRequired,
    onPressMatch: PropTypes.func,
    onLongPressMatch: PropTypes.func
};

const BracketRound = props => {
    const {matches, round, style, ...rest} = props;
    return (
        <View style={style ? [style, styles.bracketColumn] : styles.bracketColumn}>
            {matches.map((match, matchIndex) => {
                return round === 0 && match.placeholder ? (
                    <FirstRoundPlaceholder key={match.id || matchIndex} />
                ) : (
                    <BracketMatch
                        {...rest}
                        key={match.id || matchIndex}
                        match={match}
                        round={round}
                    />
                )
            })}
        </View>
    )
}

BracketRound.propTypes = {
    matches: PropTypes.array.isRequired,
    round: PropTypes.number.isRequired,
    style: PropTypes.object
}

const BracketWinner = ({
    winner,
    round
}) => {
    const {
        renderMatchContestant,
        renderMatchContestantPlaceholder
    } = useBracketTheme();

    return (
        <View style={styles.bracketColumn}>
            <View style={styles.bracketCellGroup}>
                <BracketMatchContestant>
                    {winner ? renderMatchContestant({
                        contestant: winner,
                        isWinner: Boolean(winner),
                        isChampion: Boolean(winner)
                    }) : renderMatchContestantPlaceholder({
                        round,
                        match: { index: 0 },
                        contestantIndex: 0
                    })}
                </BracketMatchContestant>
            </View>
        </View>
    )
}

BracketWinner.propTypes = {
    winner: PropTypes.object,
    round: PropTypes.number
}

const Bracket = ({
    id,
    bracket,
    winner,
    onPressMatch,
    onLongPressMatch,
    colors,
    theme,
    renderMatchContestant = renderMatchContestantDefault,
    renderMatchContestantPlaceholder = renderMatchContestantPlaceholderNone
}) => {
    const themeDef = BracketThemes[theme];
    return (
        <BracketThemeContext.Provider value={{
            ...colors,
            ...themeDef,
            renderMatchContestant,
            renderMatchContestantPlaceholder
        }}>
            <View style={colors ? [styles.bracketContainer, colors] : [styles.bracketContainer]}>
                {bracket.map((roundMatches, roundIndex) => {
                    return (
                        <BracketRound
                            key={id + roundIndex.toString()}
                            matches={roundMatches}
                            round={roundIndex}
                            onLongPressMatch={onLongPressMatch}
                            onPressMatch={onPressMatch}

                        />
                    )
                })}
                <BracketWinner winner={winner} round={bracket.length} />
            </View>
        </BracketThemeContext.Provider>
    )
}

Bracket.propTypes = {
    id: PropTypes.string,
    bracket: PropTypes.array,
    winner: PropTypes.object,
    onPressMatch: PropTypes.func,
    onLongPressMatch: PropTypes.func,
    colors: PropTypes.object,
    theme: PropTypes.string,
    renderMatchContestant: PropTypes.func,
    renderMatchContestantPlaceholder: PropTypes.func
};

export default Bracket;

const renderMatchContestantSkeleton = ({contestant}) => {
    const duration = 1000;
    const opacityLow = 0;
    const opacityHigh = .62;
    const white = useThemeValue("colors", "white");
    const [fadeAnim] = React.useState(new Animated.Value(opacityLow));
    useFocusEffect(React.useCallback(() => {
        Animated.sequence([
            Animated.delay(duration * ((contestant && contestant.order) ?? Math.random())),
            Animated.timing(fadeAnim, {
                toValue: opacityHigh,
                duration,
                useNativeDriver: Platform.OS !== 'web'
            }),
            Animated.timing(fadeAnim, {
                toValue: opacityLow,
                duration,
                useNativeDriver: Platform.OS !== 'web'
            })
        ]).start();
    }, [contestant]))
    const style = {
        width: '100%',
        height: '100%',
        opacity: fadeAnim,
        backgroundColor: white
    }
    return <Animated.View style={style}></Animated.View>
}

export const BracketSkeleton = ({tournament, colors}) => {
    const numberOfEntries = tournament ? tournament.Players.length + tournament.Teams.length : 0;
    const nearestLowerPowerOf2 = Math.pow(2, Math.floor(Math.log2(numberOfEntries)));
    const nearestHigherPowerOf2 = nearestLowerPowerOf2 === numberOfEntries ? nearestLowerPowerOf2 : nearestLowerPowerOf2 * 2;
    const numberOfRounds = Math.ceil(Math.log(nearestHigherPowerOf2) / Math.log(2))

    return (
        <BracketThemeContext.Provider value={{
            ...colors,
            renderMatchContestant: renderMatchContestantSkeleton,
            renderMatchContestantPlaceholder: renderMatchContestantPlaceholderNone
        }}>
            <View style={colors ? [styles.bracketContainer, colors] : [styles.bracketContainer]}>
                {times(numberOfRounds, roundIndex => {
                    const numberOfMatches = Math.floor(nearestHigherPowerOf2 / Math.pow(2, roundIndex + 1))
                    const style = {
                        minHeight: nearestHigherPowerOf2 * bracketCellHeight
                    }
                    const contestantsShuffled = shuffle(times(numberOfMatches * 2, index => index));
                    const matches = times(numberOfMatches, matchIndex => {
                        return {
                            matchIndex,
                            roundIndex,
                            contestants: [{
                                order: contestantsShuffled[matchIndex * 2]
                            }, {
                                order: contestantsShuffled[matchIndex * 2 + 1]
                            }]
                        }
                    })
                    return <BracketRound
                        key={roundIndex}
                        round={roundIndex}
                        matches={matches}
                        style={style}
                        renderMatchContestant={renderMatchContestantSkeleton}
                    />
                })}
                <BracketWinner
                    winner={null}
                    renderMatchContestant={renderMatchContestantSkeleton}
                />
            </View>
        </BracketThemeContext.Provider>
    )
}

BracketSkeleton.propTypes = {
    tournament: PropTypes.object,
    colors: PropTypes.object
};
