import React from 'react';

import { Button } from '@components/Button';
import { Modal } from '@components/Modal';

import {
    FormControl,
    Input
} from '@components/Form';

import {
    HStack
} from '@components/Layout';

const AddEntryModal = props => {
    const [name, setName] = React.useState('');
    const nameInputRef = React.useRef();
    const { onOK, onCancel, ...rest } = props;
    const onAdd = () => {
        onOK && onOK(name);
        setName('');
        nameInputRef.current?.focus();
    }
    const onAddAndClose = () => {
        onOK && onOK(name);
        setName('');
        props.onClose && props.onClose();
    }
    React.useEffect(() => {
        setTimeout(() => {
            nameInputRef.current?.focus();
        }, 300);
    }, []);
    return (
        <Modal {...rest}>
            <Modal.Content>
                <Modal.CloseButton />
                <Modal.Header>Add Player</Modal.Header>
                <Modal.Body>
                    <FormControl>
                        <FormControl.Label>Name</FormControl.Label>
                        <Input
                            value={name}
                            ref={nameInputRef}
                            onChangeText={text => setName(text)}
                            onSubmitEditing={onAdd}
                            placeholder={'Enter player name'}
                        />
                    </FormControl>
                </Modal.Body>
                <Modal.Footer>
                    <HStack space={2}>
                        <Button variant="ghost" onPress={() => {
                            onCancel && onCancel();
                            props.onClose && props.onClose();
                        }}>
                        Close
                        </Button>
                        <Button onPress={onAddAndClose}>Add &amp; Close</Button>
                        <Button onPress={onAdd}>Add</Button>
                    </HStack>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    )
}

export default AddEntryModal;
