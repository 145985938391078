// Polyfill required for react-native-reanimated
import 'setimmediate';

import { registerRootComponent } from 'expo';

import Root from '@screens/main/Root';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(Root);
