import { AUTH0_DOMAIN } from '@env';

import { fetchUserInfoRequest, fetchUserInfoSuccess, fetchUserInfoFailure } from '@actions/profile';

export default async function fetchUserInfo(dispatch, authHeader) {
    dispatch(fetchUserInfoRequest());
    try {
        const headers = new Headers({
            Authorization: authHeader
        });
        const response = await fetch(`https://${AUTH0_DOMAIN}/userinfo`, {
            headers
        });
        if (response.ok) {
            const userInfo = await response.json();
            dispatch(fetchUserInfoSuccess(userInfo));
            return userInfo;
        }
    } catch (err) {
        console.error(err);
        dispatch(fetchUserInfoFailure(err));
    }
    return null;
}