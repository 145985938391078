import React from 'react';
import { Pressable } from 'react-native';
import { CheckboxEmpty, CheckboxChecked } from './Icons';
import { useThemeValue, buildPropsStyle } from '@components/Theme';
import { HStack } from '@components/Layout';
import { Text } from '@components/Text';

export default React.forwardRef((props, ref) => {
    const {
        children,
        value,
        onChange,
        size = 'md',
        style,
        ...rest
    } = props;

    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const sizeStyle = useThemeValue('components.Button.sizes', size);

    const label = typeof children === 'string' ? <Text>{children}</Text> : children;

    return (
        <Pressable ref={ref} style={[sizeStyle, propsStyle, style]} {...nonStyleProps} onPress={() => onChange(!value)}>
            <HStack space={2}>
                {value ? <CheckboxChecked size={size} /> : <CheckboxEmpty size={size} />}
                {label}
            </HStack>
        </Pressable>
    )
})
