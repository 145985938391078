import React from 'react';

import { TextInput } from 'react-native';

import { Picker } from '@react-native-picker/picker';

import { Box, HStack } from '@components/Layout';
import { Text } from '@components/Text';

import { useThemeValue, buildPropsStyle } from '@components/Theme';

export const FormControl = ({children, isInvalid, ...rest}) => {
    return <Box {...rest}>{children}</Box>
}

FormControl.Label = ({children, ...rest}) => {
    return <Text {...rest}>{children}</Text>
}

FormControl.ErrorMessage = ({leftIcon, children, ...rest}) => {
    return (
        <HStack space={1} py={1}>
            {leftIcon}
            <Text {...rest} alignContent={'center'}>{children}</Text>
        </HStack>
    )
}

export const Input = React.forwardRef((props, ref) => {
    const { InputLeftElement, InputRightElement, style, ...rest } = props;

    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const baseStyle = {
        ...useThemeValue('components.Input', 'baseStyle'),
        ...propsStyle,
        ...style
    };

    if (InputLeftElement || InputRightElement) {
        return <HStack space={1} style={baseStyle}>
            {InputLeftElement}
            <TextInput ref={ref} style={propsStyle} {...nonStyleProps}></TextInput>
            {InputRightElement}
        </HStack>
    } else {
        return <TextInput ref={ref} style={baseStyle} {...nonStyleProps}></TextInput>
    }
})

export const Select = React.forwardRef((props, ref) => {
    const { children, style, ...rest } = props;
    const { style: propsStyle, nonStyleProps } = buildPropsStyle({
        p: '1',
        ...rest
    });
    const itemStyle = {
        ...buildPropsStyle({
            font: 'body',
            fontSize: 'sm',
            fontStyle: 'normal',
            fontWeight: '400'
        })
    }
    return (
        <Picker ref={ref} style={style ? [propsStyle, style] : propsStyle} {...nonStyleProps} itemStyle={itemStyle}>
            {props.children}
        </Picker>
    )
})

Select.Item = (props) => {
    return (
        <Picker.Item {...props} />
    )
}
