import React from 'react';

import { useTournamentListActions, useTournamentAPI } from '@hooks/TournamentListContext';

import ResponsiveContainer from '@components/ResponsiveContainer';

import StepsNavigator from './StepsNavigator';

const TournamentCreator = (props) => {
    const [ newTournament, setNewTournament ] = React.useState(props.newTournament || null);
    const [ isJustCreated, setJustCreated ] = React.useState(false);

    const { selectTournament } = useTournamentListActions();
    const { createTournament, updateTournament } = useTournamentAPI();

    const createOrUpdateAfterFirstStep = async data => {
        if (newTournament) {
            return updateTournament(newTournament.id, data);
        } else {
            return createTournament(data).then(tournament => {
                if (tournament) {
                    setJustCreated(true);
                    setNewTournament(tournament);
                    selectTournament(tournament);
                }
            });
        }
    }

    return (
        <ResponsiveContainer>
            <StepsNavigator
                tournament={newTournament}
                isJustCreated={isJustCreated}
                createOrUpdateAfterFirstStep={createOrUpdateAfterFirstStep}
            />
        </ResponsiveContainer>
    )
}

export default TournamentCreator;
