import React from 'react';

import { Button } from '@components/Button';
import { MenuIcon } from '@components/Icons';

const MenuButton = React.forwardRef((props, ref) => {
    return (
        <Button variant='unstyled' ref={ref} {...props}>
            <MenuIcon size='lg' />
        </Button>
    );
});

export default MenuButton;