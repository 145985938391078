import {
    LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE,
    LOGOUT_REQUEST, LOGOUT_SUCCESS
} from '@actions/actionTypes';

const authReducer = (state, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                accessToken: action.accessToken,
                idToken: action.idToken,
                refreshToken: action.refreshToken,
                tokenType: action.tokenType
            }
        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                accessToken: null,
                idToken: null,
                refreshToken: null,
                tokenType: null
            }
        case LOGOUT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                accessToken: null,
                idToken: null,
                refreshToken: null,
                tokenType: null,
                user: null
            }
    }
    return state
}

export default authReducer;