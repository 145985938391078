import React from 'react';

import {
    DrawerActions
} from '@react-navigation/native';

import {
    createDrawerNavigator,
    DrawerContentScrollView,
    DrawerItem,
    DrawerItemList
} from "@react-navigation/drawer";

import {
    IconButton
} from '@components/Button';

import { useColorModeValue, useThemeValue } from '@components/Theme';

import {
    Divider,
    HStack
} from '@components/Layout';

import { Heading } from '@components/Text';

import { CloseIcon } from '@components/Icons';

import Settings from '@screens/settings/Settings';
import TournamentList from '@screens/tournament-list/TournamentList';

import { useTournamentListActions } from '@hooks/TournamentListContext';

import useAuth from '@hooks/useAuth';

const Drawer = createDrawerNavigator();

const DrawerCloseButton = ({navigation}) => {
    return (
        <IconButton size={'lg'} variant={'unstyled'} onPress={() => navigation.closeDrawer()} icon={<CloseIcon/>} />
    );
};

const HomeDrawerContent = props => {
    const { logout } = useAuth();
    const { selectTournament } = useTournamentListActions();
    return (
        <DrawerContentScrollView {...props}>
            <HStack my={2} space={4} alignItems={'center'}>
                <Heading size={"md"} flex={1} ml={2}>Backyard Bracket</Heading>
                <DrawerCloseButton navigation={props.navigation} />
            </HStack>
            <Divider />
            <DrawerItemList {...props} />
            <Divider />
            <DrawerItem
                label="Create tournament"
                onPress={() => {
                    // Clear selected tournament before navigating to the creation screen
                    // to prevent previously selected tournament's data from showing
                    selectTournament(null);
                    props.navigation.push('Create');
                    props.navigation.dispatch(DrawerActions.closeDrawer());
                }}
            />
            <Divider />
            <DrawerItem
                label="Logout"
                onPress={() => logout()}
            />
        </DrawerContentScrollView>
    )
}

const HomeNavigator = ({navigation}) => {
    // required for contrast in dark mode
    const [lightText, darkText] = useThemeValue("colors", ["lightText", "darkText"]);
    const headerTintColor = useColorModeValue(darkText, lightText);
    return (
        <Drawer.Navigator initialRouteName='Tournaments' screenOptions={{
            drawerType: 'front',
            headerTintColor
        }} drawerContent={(props) => <HomeDrawerContent {...props} />}>
            <Drawer.Screen name='Tournaments'>
                {props => (
                    <TournamentList {...props} />
                )}
            </Drawer.Screen>
            <Drawer.Screen name='Settings' component={Settings} />
        </Drawer.Navigator>
    )
}

export default HomeNavigator;
