import { Platform } from 'react-native';

import { API_HOST } from '@env';

const isEasBuild = Boolean(process.env.EAS_BUILD);

export const apiUrl = API_HOST && !isEasBuild ? API_HOST : 'https://backyardbracket.com';

export const appScheme = 'byb';

const isDevelopmentServer = () => {
    return (window?.location?.href &&
        (window.location.href.includes('byb-dev') || window.location.href.includes('localhost')));
}

const makeApiUrl = (...pieces) => {
    return [apiUrl, 'api', ...pieces].join('/');
}

const makeTournamentURL = (tournament) => {
    if (Platform.OS === 'web') {
        if (isDevelopmentServer()) {
            const url = new URL(window.location.href);
            url.pathname = `/tournaments/${tournament.shortId}`;
            return url.toString();
        } else {
            return `https://byb.events/tournaments/${tournament.shortId}`;
        }
    } else {
        return `${appScheme}://tournaments/${tournament.shortId}`;
    }
}

const makeTournamentEnterURL = (tournament) => {
    const base = makeTournamentURL(tournament)
    if (base.endsWith('/')) {
        return base + 'enter'
    } else {
        return base + '/enter'
    }
}

export { makeApiUrl, makeTournamentURL, makeTournamentEnterURL, isDevelopmentServer };