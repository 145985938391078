import { makeApiUrl } from '@api';

export const fetchTournaments = async (authHeader, userId) => {
    const headers = new Headers({
        Authorization: authHeader
    });
    const url = makeApiUrl('tournaments') + '?organizerId=' + userId;
    const response = await fetch(url, {
        headers
    });
    if (response.ok) {
        const tournaments = await response.json();
        return tournaments;
    } else {
        return [];
    }
}

export const fetchTournament = async (authHeader, tournamentId) => {
    const headers = new Headers({
        Authorization: authHeader
    });
    const url = makeApiUrl('tournaments', tournamentId);
    const response = await fetch(url, {
        headers
    });
    if (response.ok) {
        const tournament = await response.json();  
        return tournament;
    } else {
        return [];
    }
}

export const fetchEliminationGroups = async (authHeader, tournament) => {
    const responses = await Promise.all(tournament.EliminationGroups.map(g => {
        return fetch(makeApiUrl('eliminationgroups', g.id), {
            headers: {
                Authorization: authHeader
            }
        });
    }));
    if (responses.every(r => r.ok)) {
        const result = await Promise.all(responses.map(r => r.json()));
        return result;
    } else {
        return null;
    }
}

export const createTournament = async (authHeader, data) => {
    const response = await fetch(makeApiUrl('tournaments'), {
        method: 'POST',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        },
        body: data && JSON.stringify(data)
      });
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        return null;
      }
}

export const deleteTournamentById = async (authHeader, tournamentId) => {
    const response = await fetch(makeApiUrl('tournaments', tournamentId), {
        method: 'DELETE',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        }
    })
    return response.ok;
}

export const deleteTournament = async (authHeader, tournament) => deleteTournamentById(authHeader, tournament.id)

export const updateTournament = async (authHeader, tournament, data) => {
    const response = await fetch(makeApiUrl('tournaments', tournament.id), {
        method: 'PUT',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    if (response.ok) {
        const result = await response.json();
        return result;
    } else {
        return null;
    }
}

export const cloneTournament = async (authHeader, tournament) => {
    const response = await fetch(makeApiUrl('tournaments', tournament.id, 'clone'), {
        method: 'POST',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        }
    });
    if (response.ok) {
        const result = await response.json();
        return result;
    } else {
        return null;
    }
}

export const startTournament = async (authHeader, tournament, ...args) => {
    const response = await fetch(makeApiUrl('tournaments', tournament.id, 'start'), {
        method: 'POST',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({...args})
    });
    return response.ok;
}

export const fetchPreview = async (authHeader, tournament) => {
    const response = await fetch(makeApiUrl('tournaments', tournament.id, 'preview'), {
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        }
    });
    if (response.ok) {
        const preview = await response.json();  
        return preview;
    } else {
        return null;
    }
}

export const setSeeding = async (authHeader, tournament, seeding) => {
    const response = await fetch(makeApiUrl('tournaments', tournament.id, 'seed'), {
        method: 'POST',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({seeding})
    });
    return response.ok;
}

export const createAndAddEntry = async (authHeader, tournament, {name, type = 'player'}) => {
    if (type !== 'team' && type !== 'player') {
        throw new Error('Invalid entry type ' + type);
    }

    const createResponse = await fetch(makeApiUrl(type + 's'), {
        method: 'POST',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name })
    });

    if (!createResponse.ok) {
        return null;
    }

    const newParticipant = await createResponse.json();

    const addResponse = await fetch(makeApiUrl('tournaments', tournament.id, 'entries'), {
        method: 'POST',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: newParticipant.id, type })
    });

    if (!addResponse.ok) {
        return null;
    }

    return Object.assign({
        id: newParticipant.id,
        type,
        name,
        TournamentEntry: {
            ParticipantType: type
        }
    });
}

export const removeEntry = async (authHeader, tournament, {id, type}) => {
    if (!id) {
        throw new Error("No id given");
    }

    const response = await fetch(makeApiUrl('tournaments', tournament.id, 'entries'), {
        method: 'DELETE',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id, type })
    });

    return response.ok
}

export const updateMatchScore = async (authHeader, match, scores, isFinal) => {
    let responseOk = false;

    // If match is final then updating the score will fail so don't even try
    if (match.resultStatus !== 'verified') {
        const response = await fetch(makeApiUrl('matches', match.id, 'score'), {
            method: 'POST',
            headers: {
                Authorization: authHeader,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(scores)
        })
        responseOk = responseOk || response.ok;
    }

    const resultStatus = isFinal ? 'verified' : 'inprogress';
    if (resultStatus !== match.resultStatus) {
        const response = await fetch(makeApiUrl('matches', match.id), {
            method: 'PUT',
            headers: {
                Authorization: authHeader,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({resultStatus})
        })
        responseOk = responseOk || response.ok;
    }

    return responseOk;
}

export const advanceWinners = async (authHeader, tournament) => {
    const result = await fetch(makeApiUrl('tournaments', tournament.id, 'update'), {
        method: 'POST',
        headers: {
            Authorization: authHeader
        }
    })
    return result.ok;
}

export const enterTournament = async (authHeader, tournament, playerOrTeam, id) => {
    if (playerOrTeam !== 'player' && playerOrTeam !== 'team') {
        throw new Error("Invalid tournament entry type: " + playerOrTeam);
    }
    const response = await fetch(makeApiUrl('tournaments', tournament.id, 'enter'), {
        method: 'POST',
        headers: {
            Authorization: authHeader
        },
        body: JSON.stringify({
          id,
          type: playerOrTeam
        })
    })
    return response.ok;
}