import React from 'react';

export default () => {
    const [ isOpen, setOpen ] = React.useState(false);

    return {
        isOpen,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false)
    };
}
