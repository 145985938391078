import React from 'react';

import {
    Modal as RNModal,
    StyleSheet,
    View,
} from "react-native";

import { IconButton } from '@components/Button';

import { Text } from '@components/Text';

import { useThemeValue, buildPropsStyle } from '@components/Theme';

import { CloseIcon } from '@components/Icons';

const ModalContext = React.createContext();

const ModalOverlay = (props) => {
    const {
        children,
        style,
        ...rest
    } = props;

    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const baseStyle = {
        ...useThemeValue('components.ModalOverlay', 'baseStyle'),
        ...propsStyle
    };

    return <View style={style ? [baseStyle, style] : baseStyle} {...nonStyleProps}>
        {children}
    </View>
}

export const Modal = (props) => {
    const {
        children,
        size= 'sm',
        isOpen = false,
        onClose,
        initialFocusRef,
        style,
        ...rest
    } = props;

    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const baseStyle = {
        ...useThemeValue('components.Modal', 'baseStyle'),
        ...useThemeValue('components.Modal.sizes', size),
        ...propsStyle
    };

    React.useEffect(() => {
        if (initialFocusRef && initialFocusRef.current) {
            initialFocusRef.current.focus();
        }
    })

    return (
        <ModalContext.Provider value={{onClose}}>
            <RNModal
                animationType="fade"
                transparent={true}
                visible={isOpen}
                onRequestClose={onClose}
            >
                <ModalOverlay>
                    <View style={style ? [baseStyle, style] : baseStyle} {...nonStyleProps}>
                        {children}
                    </View>
                </ModalOverlay>
            </RNModal>
        </ModalContext.Provider>
    );
};

Modal.Content = (props) => {
    const {
        children,
        style,
        ...rest
    } = props;

    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const baseStyle = {
        ...useThemeValue('components.ModalContent', 'baseStyle'),
        ...propsStyle
    };

    return <View style={style ? [baseStyle, style] : baseStyle} {...nonStyleProps}>{children}</View>
}

Modal.Body = (props) => {
    const {
        children,
        style,
        ...rest
    } = props;

    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const baseStyle = {
        ...useThemeValue('components.ModalBody', 'baseStyle'),
        ...propsStyle
    };

    return <View style={style ? [baseStyle, style] : baseStyle} {...nonStyleProps}>
        {children}
    </View>
}

Modal.Header = (props) => {
    const {
        children,
        style,
        ...rest
    } = props;

    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const baseStyle = {
        ...useThemeValue('components.ModalHeader', 'baseStyle'),
        ...propsStyle
    };

    return <View style={style ? [baseStyle, style] : baseStyle} {...nonStyleProps}>
        <Text style={styles.modalTitle}>
            {children}
        </Text>
    </View>
}

Modal.Footer = (props) => {
    const {
        children,
        style,
        ...rest
    } = props;

    const { style: propsStyle, nonStyleProps } = buildPropsStyle(rest);

    const baseStyle = {
        ...useThemeValue('components.ModalFooter', 'baseStyle'),
        ...propsStyle
    };

    return <View style={style ? [baseStyle, style] : baseStyle} {...nonStyleProps}>
        {children}
    </View>
}

Modal.CloseButton = () => {
    const { onClose } = React.useContext(ModalContext);
    return onClose && <IconButton
        variant={'unstyled'}
        icon={<CloseIcon />}
        onPress={onClose}
        position={'absolute'}
        right={2}
        top={2}
        zIndex={1000}
    />;
}

const styles = StyleSheet.create({
    modalTitle: {
        fontWeight: 'bold'
    }
})
