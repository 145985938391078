import React from 'react';

import {
    Pressable,
    ScrollView,
    StyleSheet
} from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { useSelectedTournament } from '@hooks/TournamentListContext';

import { useTournamentProducts } from '@hooks/TournamentProductsContext';

import { useForm, Controller } from "react-hook-form";

import { useToast } from '@components/Toast';

import {
    FormControl,
    Input
} from '@components/Form';

import { RadioButton } from '@components/RadioButton';
import { Text } from '@components/Text';

import { HStack, VStack } from '@components/Layout';

const renderProductRadioButton = (buttonProps) => {
    const { id, key, isDisabled, isSelected, onPress, renderIndicator, product, ...rest } = buttonProps;
    return <Pressable
        key={key ?? id}
        onPress={onPress}
        disabled={isDisabled}
        {...rest}
    >
        <HStack space={2}>
            {renderIndicator({isSelected, isDisabled})}
            <VStack space={1}>
                <HStack space={2} alignItems={'center'}>
                    <Text fontSize='lg'>{product.title}</Text>
                    <Text>{product.priceString}</Text>
                </HStack>
                <Text>{product.description}</Text>
            </VStack>
        </HStack>
    </Pressable>
}

const TournamentNameStep = ({onNext, isJustCreated}) => {
    const tournament = useSelectedTournament();

    const products = useTournamentProducts();

    const navigation = useNavigation();

    const { control, handleSubmit } = useForm({
        defaultValues: {
            name: tournament ? tournament.name : '',
            game: tournament ? tournament.game : '',
            productSelected: tournament?.options?.productSelected ?? 'basic'
        }
    });

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('blur', () => {
            if (onNext) {
                handleSubmit(data => {
                    const { name, game, productSelected } = data;
                    onNext({
                        name,
                        game,
                        options: {
                            ...tournament?.options,
                            productSelected
                        }
                    });
                })(); // handleSubmit returns a function
            }
        });

        return unsubscribe;
    }, [navigation, handleSubmit, tournament]);

    const toast = useToast();

    const [newTournamentToastShown, setNewTournamentToastShown] = React.useState();

    React.useEffect(() => {
        if (isJustCreated && !newTournamentToastShown) {
            setNewTournamentToastShown(true);
            toast.show('Created tournament');
        }
    }, [newTournamentToastShown]);

    const renderProductsRadioButtonGroup = React.useCallback((field) => {
        const { onChange, value, name } = field;
        const basicProduct = {
            id: 'basic',
            title: 'Basic tournament',
            description: 'Up to 5 entries, no customization',
            price: 0,
            priceString: '$0.00'
        }

        return (
            <RadioButton.Group space={2} onChange={onChange} value={value} key={name} renderRadioButton={renderProductRadioButton}>
                {[basicProduct].concat(products).map((product) => {
                    return <RadioButton key={product.id} id={product.id} product={product}></RadioButton>
                })}
            </RadioButton.Group>
        )
    }, [products]);

    return (
        <ScrollView style={styles.container}>
            <VStack space={1}>
                <FormControl>
                    <FormControl.Label bold fontSize='lg' py={3}>Name</FormControl.Label>
                    <Controller
                        control={control}
                        name='name'
                        render={({field}) => (
                            <Input
                                onBlur={field.onBlur}
                                onChangeText={field.onChange}
                                value={field.value}
                            />
                        )}
                    />
                </FormControl>
                <FormControl>
                    <FormControl.Label bold fontSize='lg' py={3}>Game</FormControl.Label>
                    <Controller
                        control={control}
                        name='game'
                        render={({field}) => (
                            <Input
                                onBlur={field.onBlur}
                                onChangeText={field.onChange}
                                value={field.value}
                            />
                        )}
                    />
                </FormControl>
                {(products && products.length > 0) && (
                    <FormControl>
                        <FormControl.Label bold fontSize='lg' py={3}>Product</FormControl.Label>
                        <Controller
                            control={control}
                            name='productSelected'
                            render={({field}) => renderProductsRadioButtonGroup(field)}/>
                    </FormControl>
                )}
            </VStack>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        marginLeft: 4,
        marginRight: 4
    }
})

export default TournamentNameStep;
