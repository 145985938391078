import React from 'react';

import {
  Animated,
  Image,
  StyleSheet
} from 'react-native';

import { Button } from '@components/Button';

import {
  Box,
  HStack,
  Stack,
  VStack
} from '@components/Layout';

import { Heading, Text } from '@components/Text';

import IconLink from '@components/IconLink';

import { FacebookLogoIcon, InstagramLogoIcon, TwitterLogoIcon } from '@components/Icons';

const iconImage = require('@assets/adaptive-icon.png');

const boxWidth = 300;

const itemSpacing = 3;

const games = Object.freeze([
  "cornhole",
  "wiffleball",
  "ping pong",
  "horseshoe",
  "foosball",
  "badminton",
  "bocce"
]);

const Footer = (props) => {
  const color = 'primary.500';
  return (
    <HStack justifyContent={'center'} {...props}>
      <Button variant={'link'} href={"/contact"}>Contact</Button>
      <Button variant={'link'} href={"/legal/privacy"}>Privacy</Button>
      <IconLink icon={<FacebookLogoIcon color={color} />} url={"https://www.facebook.com/backyardbracket"} />
      <IconLink icon={<InstagramLogoIcon color={color} />} url={"https://www.instagram.com/backyardbracket"} />
      <IconLink icon={<TwitterLogoIcon color={color} />} url={"https://www.twitter.com/backyardbracket"} />
    </HStack>
  )
}

const LandingContentA = ({readyToLogin, onLogin}) => {
  const [gameIndex, setGameIndex] = React.useState(0);

  const gameSwitchMs = 3000;

  const [fadeAnim] = React.useState(new Animated.Value(0));

  React.useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: gameSwitchMs / 2,
          useNativeDriver: true
        }),
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: gameSwitchMs / 2,
          useNativeDriver: true
        }),
      ])
    ).start();
  }, [])

  React.useEffect(() => {
    const listener = fadeAnim.addListener(({value}) => {
      // Update the index whenever the animated value is zero
      if (Math.abs(value) < 0.0000001) {
        setGameIndex((gameIndex + 1) % games.length);
      }
    })
    return () => fadeAnim.removeListener(listener);
  })

  // minWidth 110 for 'xl' font size

  return (
    <Box safeArea h='100%' justifyContent='center' mx={2}>
      <HStack m={4} safeAreaTop>
        <Text flexGrow={1}>{/*TODO Header here*/}</Text>
        <Button w={120} variant={'subtle'} disabled={!readyToLogin} onPress={onLogin}>Login</Button>
      </HStack>
      <Stack direction={'column'} alignItems={'center'} space={itemSpacing} flexGrow={1} justifyContent={'center'}>
        <Stack direction={{base:'column', lg:'row'}} space={itemSpacing} alignItems='center' justifyContent='center'>
          <Image style={styles.logoImage} source={iconImage} alt={'Login page'} />
          <VStack alignItems={'center'} space={itemSpacing}>
            <Heading>Backyard Bracket</Heading>
            <HStack space={1}>
              <Text fontSize={'xl'} textAlign={'center'}>
                Run your next
                <Animated.Text width={110} textAlign={'center'} style={{
                  opacity: fadeAnim,
                  display: 'inline-block',
                  width: 110,
                  textAlign: 'center'
                }}>
                  {games[gameIndex]}
                </Animated.Text>
                tournament like a pro
              </Text>
            </HStack>
          </VStack>
        </Stack>
      </Stack>
      <Footer m={4} safeAreaBottom />
    </Box>
  );
}

const Landing = (props) => {
  return <LandingContentA {...props} />;
};

const styles = StyleSheet.create({
  logoImage: {
    width: boxWidth,
    height: boxWidth
  }
})

export default Landing;
