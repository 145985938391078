import React from 'react';

import { Button } from '@components/Button';

import {
    ScrollView,
    StyleSheet
} from 'react-native';

import {
    VStack
} from '@components/Layout';

import { useToast } from '@components/Toast';

import ConfirmDialog from '@components/ConfirmDialog';

import { useFocusEffect, useNavigation, StackActions } from '@react-navigation/native';

import { getTournamentProduct } from '@hooks/TournamentProductsContext';

import { usePreview, useSelectedTournament, useTournamentAPI, useTournamentListState } from '@hooks/TournamentListContext';
import { useProductAPI } from '@hooks/TournamentProductsContext';

import getTournamentColors from '@utils/getTournamentColors';

import PreviewBracket from './PreviewBracket';

const TournamentStartConfirmDialog = (props) => {
    const { children, ...rest } = props;
    return <ConfirmDialog {...rest}>{children}</ConfirmDialog>
}

const TournamentStartStep = () => {
    const tournament = useSelectedTournament();

    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);

    const productAPI = useProductAPI();
    const { fetchTournament, fetchPreview, startTournament } = useTournamentAPI();
    const { fetching } = useTournamentListState();
    const preview = usePreview(tournament && tournament.id);

    const toast = useToast();

    useFocusEffect(React.useCallback(() => {
        if (tournament && !preview) {
            fetchPreview(tournament.id);
        }
    }, [tournament]));

    const product = getTournamentProduct(tournament);
    const productDetails = productAPI?.getProductDetails(product);

    const navigation = useNavigation();

    const onStart = () => setIsConfirmationOpen(true);
    const onCancel = () => setIsConfirmationOpen(false);
    const onConfirm = React.useCallback(() => {
        setIsConfirmationOpen(false);
        const startTournamentAsync = async (tournament) => {
            try {
                const product = getTournamentProduct(tournament);
                let purchaseResult;
                if (productAPI.isPurchaseRequired(product)) {
                    purchaseResult = await productAPI.purchaseProduct(product);
                    if (!purchaseResult) {
                        toast.show('Could not complete purchase', {
                            type: 'warning'
                        });
                        return false;
                    }
                }
                const startResult = await startTournament(tournament, purchaseResult);
                if (!startResult) {
                    toast.show('Could not start tournament', {
                        type: 'warning'
                    });
                    return false;
                }
                await fetchTournament(tournament.id);
                navigation.dispatch(StackActions.replace('View', {
                    id: tournament.id
                }));
            } catch (err) {
                console.error(err);
                return false;
            }
        }
        startTournamentAsync(tournament);
    }, [tournament]);

    return (
        <ScrollView style={styles.container}>
            <VStack space={2}>
                <PreviewBracket tournament={tournament} preview={preview} colors={getTournamentColors(tournament)} />
                <Button isDisabled={!preview && !fetching} onPress={onStart}>Start</Button>
            </VStack>
            <TournamentStartConfirmDialog
                isOpen={isConfirmationOpen}
                onCancel={onCancel}
                onConfirm={onConfirm}
                dialogTitle={'Start tournament'}
                dialogText={'Starting the tournament will finalize all matchups.\nThis cannot be undone.'}
                confirmLabel={'Start'}
                productDetails={productDetails}
            />
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        marginLeft: 4,
        marginRight: 4,
        marginTop: 2
    }
})

export default TournamentStartStep;
