import React from 'react';

import * as NavigationBar from 'expo-navigation-bar';

import { useScreenOrientation } from '@hooks/useScreenOrientation';

import { useToast } from '@components/Toast';

import {
    Box
} from '@components/Layout';

import { useNavigation } from '@react-navigation/native';

import useCurrentUser from '@hooks/useCurrentUser';
import { useTournament, useEliminationGroups, useTournamentAPI } from '@hooks/TournamentListContext';

import { Heading, Text } from '@components/Text';

import FloatingActionButton from '@components/FloatingActionButton';

import getTournamentColors from '@utils/getTournamentColors';

import MatchScoreModal from './MatchScoreModal';

import TournamentBracket, { getTournamentWinner } from './TournamentBracket';
import TournamentMenu from '@screens/tournament-menu/TournamentMenu';

import * as TournamentStatus from '@api/TournamentStatus';
import * as MatchStatus from '@api/MatchStatus';

const isReadyToAdvanceWinners = (tournament, eliminationGroups) => {
    if (tournament.status !== TournamentStatus.COMPLETED) {
        for (const eliminationGroup of eliminationGroups) {
            for (const match of eliminationGroup.Matches) {
                if (match.resultStatus === MatchStatus.VERIFIED && !match.winnerDeclared) {
                    return true;
                }
            }
        }
    }
    return false;
}

const TournamentViewer = ({params}) => {
    const navigation = useNavigation();

    const tournament = useTournament(params.id);

    const { currentUser } = useCurrentUser();

    const [scoreUpdateMatch, setScoreUpdateMatch] = React.useState();

    const toast = useToast();

    const eliminationGroups = tournament && useEliminationGroups(tournament.id);

    const { fetchEliminationGroups, updateMatchScore, advanceWinners } = useTournamentAPI();

    const { isLandscape } = useScreenOrientation();

    React.useEffect(() => {
        if (tournament && !eliminationGroups) {
            fetchEliminationGroups(tournament);
        }
    }, [tournament, eliminationGroups]);

    const onPressMatch = match => {
        const nonNullContestantCount = match.contestants.filter(c => Boolean(c)).length;
        if (tournament.status === 'started' && !match.winnerDeclared && nonNullContestantCount > 1) {
            setScoreUpdateMatch(match);
        } else if (tournament.status !== TournamentStatus.COMPLETED) {
            let toastMessage;
            if (match.resultStatus === MatchStatus.VERIFIED) {
                toastMessage = 'Match is final';
            } else if (nonNullContestantCount <= 1) {
                toastMessage = 'Match awaiting prior round result';
            }
            if (toastMessage) {
                toast.show(toastMessage);
            }
        }
    }

    const onUpdateScores = (score, isFinal) => {
        updateMatchScore({ tournament, match: scoreUpdateMatch, score, isFinal });
        setScoreUpdateMatch(null);
    }

    const isOrganizer = tournament && currentUser.id === tournament.organizerId;
    const readyToAdvanceWinners = eliminationGroups && isReadyToAdvanceWinners(tournament, eliminationGroups);

    const tournamentColors = getTournamentColors(tournament);

    React.useEffect(() => {
        NavigationBar.setVisibilityAsync(isLandscape ? "hidden" : "visible");

        const options = {
            headerShown: !isLandscape
        };
        if (tournament) {
            options.headerStyle = tournamentColors;
            options.title = tournament.name;
            options.headerRight = () => tournament && <TournamentMenu tournament={tournament} />;
        }
        navigation.setOptions(options);
    }, [navigation, tournament, isLandscape]);

    const isCompleted = tournament?.status === TournamentStatus.COMPLETED;
    const winner = isCompleted && getTournamentWinner(tournament, eliminationGroups);

    return (
        <Box safeArea={true} flex={1} style={tournamentColors}>
            <Heading color={tournamentColors.color} mx={2} mt={2} mb={1} textAlign={'center'}>{tournament?.name}</Heading>
            {!isCompleted && scoreUpdateMatch && (
                <MatchScoreModal
                    isOpen={Boolean(scoreUpdateMatch)}
                    match={scoreUpdateMatch}
                    onCancel={() => setScoreUpdateMatch(null)}
                    onUpdateScores={onUpdateScores}
                />
            )}
            {winner && (
                <Text color={tournamentColors.color} mt={2} mb={1} fontSize={'2xl'} textAlign={'center'}>{`Winner: ${winner.name}`}</Text>
            )}
            <Box mx={2}>
                {tournament && <TournamentBracket
                    tournament={tournament}
                    eliminationGroups={eliminationGroups}
                    onPressMatch={onPressMatch}
                />}
            </Box>
            {!isCompleted && isOrganizer && readyToAdvanceWinners && (
                <FloatingActionButton label={'Advance winners'}
                    onPress={() => {
                        advanceWinners(tournament)
                    }}
                />
            )}
        </Box>
    );
}

export default TournamentViewer;
