import { getRandomBytesAsync } from 'expo-crypto';

const generateNonce = async (length = 8) => {
    if (length > 64) {
        throw new Error(`Generated nonce length ${length} too big`);
    }

    const nonceCharset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/'
    let result = ''

    while (length > 0) {
        const random = await getRandomBytesAsync(length * 2);

        random.forEach(c => {
            if (length == 0) {
                return;
            }
            if (c < nonceCharset.length) {
                result += nonceCharset[c];
                length--;
            }
        });
    }
    return result;
}

export default generateNonce;
