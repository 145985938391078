/**
 * Backyard Bracket application top-level component with theme
 *
 * @format
 * @flow strict-local
 */

import React from 'react';

import { Platform } from 'react-native';
import * as _SecureStore from 'expo-secure-store';

import { NavigationContainer } from '@react-navigation/native';

import { MenuProvider } from '@components/Menu';

import { ToastProvider } from 'react-native-toast-notifications';

import * as Linking from 'expo-linking';

import { ThemeProvider } from '@components/Theme';

import theme from './AppTheme';

import useCurrentUser from '@hooks/useCurrentUser';

import { ScreenOrientationProvider } from '@hooks/useScreenOrientation';

import { TournamentListProvider } from '@hooks/TournamentListContext';

import { TournamentProductsProvider } from '@hooks/TournamentProductsContext';

import AppContent from '@screens/main/AppContent';

const documentTitleFormatter = (options, route) => {
    if (options || route) {
        return `${options?.title ?? route?.name} - Backyard Bracket`;
    } else {
        return 'Backyard Bracket';
    }
}

const App = () => {
    const linking = {
        prefixes: [
            Linking.createURL("/"),
            "https://backyardbracket.com",
            "https://byb.events",
            "https://byb-dev.local:19006"
        ],
        config: {
            screens: {
                Home: {
                    screens: {
                        Tournaments: {
                            path: "/tournaments"
                        },
                        Profile: {
                            path: "/profile"
                        }
                    }
                },
                View: {
                    path: "/tournaments/:id"
                },
                Edit: {
                    path: "/tournaments/:id/edit"
                },
                Create: {
                    path: "/tournaments/new"
                },
                TournamentEnter: {
                    path: "/tournaments/:id/enter"
                },
                TournamentQR: {
                    path: "/tournaments/:id/qr"
                }
            }
        }
    };

    const { currentUser } = useCurrentUser();
    const dark = (currentUser && currentUser.settings && currentUser.settings.theme) === 'dark';

    const navigationTheme = {
        dark,
        colors: {
            primary: theme.colors.primary['400'],
            background: dark ? theme.colors.black : theme.colors.white,
            card: dark ? theme.colors.black : theme.colors.white,
            text: dark ? theme.colors.lightText : theme.colors.darkText,
            border: dark ? theme.colors.lightText : theme.colors.darkText,
            notification: theme.colors.danger['400']
        }
    };

    const colorModeManager = {
        get: async () => {
            try {
                if (currentUser && currentUser.settings && currentUser.settings) {
                    const dark = currentUser.settings.theme === 'dark';
                    return dark ? 'dark' : 'light';
                }
                if (Platform.OS === 'web') {
                    return localStorage.getItem("color-mode");
                }
                const colorMode = await _SecureStore.getItemAsync("color-mode");
                return colorMode;
            } catch {
                return 'light';
            }
        },
        set: async (value) => {
            if (Platform.OS === 'web') {
                return localStorage.setItem("color-mode", value ? value.toString() : '');
            }
            return _SecureStore.setItemAsync("color-mode", value);
        }
    };

    return (
        <ThemeProvider
            colorModeManager={colorModeManager}
            theme={{...theme, config: {
                initialColorMode: dark ? 'dark' : 'light',
                useSystemColorMode: (currentUser && currentUser.settings && currentUser.settings.theme) === 'system' 
            }}}>
            <ToastProvider
                successColor={theme.colors.success['500']}
                dangerColor={theme.colors.danger['500']}
                warningColor={theme.colors.warning['500']}
                normalColor={theme.colors.light['500']}
            >
                <MenuProvider>
                    <NavigationContainer
                        linking={linking}
                        theme={navigationTheme}
                        documentTitle={{ formatter: documentTitleFormatter }}>
                        <TournamentListProvider>
                            <TournamentProductsProvider>
                                <ScreenOrientationProvider>
                                    <AppContent />
                                </ScreenOrientationProvider>
                            </TournamentProductsProvider>
                        </TournamentListProvider>
                    </NavigationContainer>
                </MenuProvider>
            </ToastProvider>
        </ThemeProvider>
    );
};

export default App;
