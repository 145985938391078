const TOURNAMENT_PRODUCT_BASIC = "basic";
const TOURNAMENT_PRODUCT_LEVEL1 = "byb_tournament_level1_v1";
const TOURNAMENT_PRODUCT_LEVEL2 = "byb_tournament_level2_v1";
const TOURNAMENT_PRODUCT_LEVEL3 = "byb_tournament_level3_v1";

export const ENTITLEMENT_COLORS = 'byb_tournament_colors_v1';
export const ENTITLEMENT_THEME = 'byb_tournament_theme_v1';
export const ENTITLEMENT_UPTO5 = 'byb_tournament_upto5'; // not present in RevenueCat
export const ENTITLEMENT_UPTO10 = 'byb_tournament_upto10_v1';
export const ENTITLEMENT_UPTO50 = 'byb_tournament_upto50_v1';
export const ENTITLEMENT_UPTO100 = 'byb_tournament_upto100_v1';

// Duplicates the product entitlements configured in RevenueCat
export const Entitlements = Object.freeze({
    [TOURNAMENT_PRODUCT_BASIC]: [ENTITLEMENT_UPTO5],
    [TOURNAMENT_PRODUCT_LEVEL1]: [ENTITLEMENT_UPTO10],
    [TOURNAMENT_PRODUCT_LEVEL2]: [ENTITLEMENT_UPTO50, ENTITLEMENT_COLORS],
    [TOURNAMENT_PRODUCT_LEVEL3]: [ENTITLEMENT_UPTO100, ENTITLEMENT_THEME, ENTITLEMENT_COLORS]
})

// Duplicates the product names configured in RevenueCat
const TournamentProductNames = Object.freeze({
    [TOURNAMENT_PRODUCT_BASIC]: 'Basic tournament',
    [TOURNAMENT_PRODUCT_LEVEL1]: 'Standard tournament',
    [TOURNAMENT_PRODUCT_LEVEL2]: 'Enhanced tournament',
    [TOURNAMENT_PRODUCT_LEVEL3]: 'Premium tournament',
})

const getTournamentProductName = (product) => TournamentProductNames[product] ?? product

export const TournamentProduct = Object.freeze({
    basic: TOURNAMENT_PRODUCT_BASIC,
    level1: TOURNAMENT_PRODUCT_LEVEL1,
    level2: TOURNAMENT_PRODUCT_LEVEL2,
    level3: TOURNAMENT_PRODUCT_LEVEL3
})

const getProductLevel = product => {
    switch (product) {
        case TournamentProduct.level3: return 3;
        case TournamentProduct.level2: return 2;
        case TournamentProduct.level1: return 1;
        default: return 0;
    }
}

export const validateBackgroundColorProduct = (value, { product }, allowedColors) => {
    if (allowedColors.indexOf(value) === -1) {
        const productLevel = getProductLevel(product);
        return (productLevel >= 2) || `Custom background color not supported in ${getTournamentProductName(product)}`;
    }
    return true;
}

export const validateThemeProduct = (value, { product }) => {
    if (value !== 'default') {
        const productLevel = getProductLevel(product);
        return (productLevel >= 3) || `Custom theme not supported in ${getTournamentProductName(product)}`;
    }
    return true;
}

export const TournamentProductMaxEntries = Object.freeze({
    [TournamentProduct.basic]: 5,
    [TournamentProduct.level1]: 10,
    [TournamentProduct.level2]: 50,
    [TournamentProduct.level3]: 100
})

export const validateEntryCountProduct = (entryCount, { product }) => {
    if (entryCount <= TournamentProductMaxEntries[product]) {
        return true;
    } else {
        return `More than ${TournamentProductMaxEntries[product]} not supported in ${getTournamentProductName(product)}`;
    }
}
