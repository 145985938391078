import React from "react";

import { Text as RNText } from 'react-native';

import { merge } from 'lodash';

import { buildPropsStyle } from './Theme';

const textDefaults = Object.freeze({
    font: 'body',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: 'sm'
})

const headingDefaults = Object.freeze({
    font: 'heading',
    bold: true
})

export const Text = (props) => {
    const { style, children, ...rest } = props;
    const { style: propsStyle, nonStyleProps } = buildPropsStyle(merge({}, textDefaults, rest));
    return <RNText style={style ? [propsStyle, style] : propsStyle} {...nonStyleProps}>{children}</RNText>
}

export const Heading = (props) => {
    const { size = '3xl', style, children, ...rest } = props;
    const { style: propsStyle, nonStyleProps } = buildPropsStyle(merge({}, headingDefaults, { fontSize: rest.fontSize ?? size }, rest));
    return <RNText style={style ? [propsStyle, style] : propsStyle} {...nonStyleProps}>{children}</RNText>
}
