import React from 'react';

import { FormControl } from '@components/Form';

import { Icon } from '@components/Icons';

const ValidationErrorMessage = ({error, size = 'xl', ...rest}) => {
    const color = 'danger.700';
    return error && (
        <FormControl.ErrorMessage
            leftIcon={<Icon name={'alert-circle-outline'} size={size} color={color} />}
            size={size}
            color={color}
            {...rest}
        >
            {error.message}
        </FormControl.ErrorMessage>
    )
}

export default ValidationErrorMessage;
