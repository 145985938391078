import React from "react";

import {
    Dimensions,
    Platform,
    StyleSheet
} from "react-native";

import { get, set, merge } from 'lodash';

const ThemeContext = React.createContext();

// TODO dark mode
export const useColorModeValue = (light, dark) => light

export const ThemeProvider = (props) => {
    const { children, theme = defaultTheme, ...rest } = props;
    return (
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    )
}

const white = '#FEFFFE';
const richBlack = '#0C1618';

const azul = {
    50: '#e2f3ff',
    100: '#badaf8',
    200: '#91bfed',
    300: '#68a5e3',
    400: '#3f8cda',
    500: '#2572c0',
    600: '#1a5996',
    700: '#0f3f6d',
    800: '#022644',
    900: '#000e1c',
};

const orange = {
    50: '#fff7ed',
    100: '#ffedd5',
    200: '#fed7aa',
    300: '#fdba74',
    400: '#fb923c',
    500: '#f97316',
    600: '#ea580c',
    700: '#c2410c',
    800: '#9a3412',
    900: '#7c2d12',
}

const red = {
    50: '#FFEBEE',
    100: '#FFCDD2',
    200: '#EF9A9A',
    300: '#E57373',
    400: '#EF5350',
    500: '#F44336',
    600: '#E53935',
    700: '#D32F2F',
    800: '#C62828',
    900: '#B71C1C',
};

const rose = {
    50: '#fff1f2',
    100: '#ffe4e6',
    200: '#fecdd3',
    300: '#fda4af',
    400: '#fb7185',
    500: '#f43f5e',
    600: '#e11d48',
    700: '#be123c',
    800: '#9f1239',
    900: '#881337',
}

const green = {
    50: '#f0fdf4',
    100: '#dcfce7',
    200: '#bbf7d0',
    300: '#86efac',
    400: '#4ade80',
    500: '#22c55e',
    600: '#16a34a',
    700: '#15803d',
    800: '#166534',
    900: '#14532d',
}

const grey = {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
};

const warmGray = {
    50: '#fafaf9',
    100: '#f5f5f4',
    200: '#e7e5e4',
    300: '#d6d3d1',
    400: '#a8a29e',
    500: '#78716c',
    600: '#57534e',
    700: '#44403c',
    800: '#292524',
    900: '#1c1917',
};

const trueGray = {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#e5e5e5',
    300: '#d4d4d4',
    400: '#a3a3a3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
};

// const gray = {
//     50: '#fafafa',
//     100: '#f4f4f5',
//     200: '#e4e4e7',
//     300: '#d4d4d8',
//     400: '#a1a1aa',
//     500: '#71717a',
//     600: '#52525b',
//     700: '#3f3f46',
//     800: '#27272a',
//     900: '#18181b',
// };

// const coolGray = {
//     50: '#f9fafb',
//     100: '#f3f4f6',
//     200: '#e5e7eb',
//     300: '#d1d5db',
//     400: '#9ca3af',
//     500: '#6b7280',
//     600: '#4b5563',
//     700: '#374151',
//     800: '#1f2937',
//     900: '#111827',
// };

const lightBlue = {
    50: '#f0f9ff',
    100: '#e0f2fe',
    200: '#bae6fd',
    300: '#7dd3fc',
    400: '#38bdf8',
    500: '#0ea5e9',
    600: '#0284c7',
    700: '#0369a1',
    800: '#075985',
    900: '#0c4a6e',
};

const colors = {
    white,
    black: richBlack,
    lightText: white,
    darkText: richBlack,
    primary: azul,
    light: warmGray,
    muted: trueGray,
    danger: rose,
    error: red,
    info: lightBlue,
    success: green,
    warning: orange,
    red,
    grey,
    gray: grey
};

const scale = 4;

const space = {
    '0': 0,
    '0.5': 0.5 * scale,
    '1': 1 * scale,
    '1.5': 1.5 * scale,
    '2': 2 * scale,
    '2.5': 2.5 * scale,
    '3': 3 * scale,
    '3.5': 3.5 * scale,
    '4': 4 * scale,
    '5': 5 * scale,
    '6': 6 * scale,
    '7': 7 * scale,
    '8': 8 * scale,
    '9': 9 * scale,
    '10': 10 * scale,
    '12': 12 * scale,
    '16': 16 * scale,
    '20': 20 * scale,
    '24': 24 * scale,
    '32': 32 * scale,
    '40': 40 * scale,
    '48': 48 * scale,
    '56': 56 * scale,
    '64': 64 * scale,
    '72': 72 * scale,
    '80': 80 * scale,
    '96': 96 * scale,
    'full': '100%'
};

const buttonSizes = {
    lg: {
        // px: '3',
        // py: '3'
        paddingLeft: 3 * scale,
        paddingRight: 3 * scale,
        paddingTop: 3 * scale,
        paddingBottom: 3 * scale,
    },
    md: {
        // px: '3',
        // py: '2.5'
        paddingLeft: 3 * scale,
        paddingRight: 3 * scale,
        paddingTop: 2.5 * scale,
        paddingBottom: 2.5 * scale,
    },
    sm: {
        // px: '3',
        // py: '2'
        paddingLeft: 3 * scale,
        paddingRight: 3 * scale,
        paddingTop: 2 * scale,
        paddingBottom: 2 * scale,
    }
}

const iconSizes = {
    '2xs': 2 * scale,
    'xs': 3 * scale,
    'sm': 4 * scale,
    'md': 5 * scale,
    'lg': 6 * scale,
    'xl': 7 * scale,
    '2xl': 8 * scale,
    '3xl': 9 * scale,
    '4xl': 10 * scale,
    '5xl': 12 * scale,
    '6xl': 16 * scale
};

const modalSizes = {
    xs: {
        width: '60%',
        maxWidth: '280'
    },
    sm: {
        width: '65%',
        maxWidth: '320'
    },
    md: {
        width: '75%',
        maxWidth: '380'
    },
    lg: {
        width: '80%',
        maxWidth: '520'
    },
    xl: {
        width: '90%',
        maxWidth: '580'
    },
    full: {
        width: '100%'
    }
};

const opacity = {
    0: 0,
    5: 0.05,
    10: 0.1,
    20: 0.2,
    25: 0.25,
    30: 0.3,
    40: 0.4,
    50: 0.5,
    60: 0.6,
    70: 0.7,
    75: 0.75,
    80: 0.8,
    90: 0.9,
    95: 0.95,
    100: 1,
};

const radii = {
    'none': 0,
    'xs': 0.5 * scale,
    'sm': 1 * scale,
    'md': 1.5 * scale,
    'lg': 2 * scale,
    'xl': 3 * scale,
    '2xl': 4 * scale,
    '3xl': 6 * scale,
    'full': 9999,
};

const shadows = {
    'none': {
        shadowColor: 'transparent',
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0,
        shadowRadius: 0,
        elevation: 0,
    },
    '0': {
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.18,
        shadowRadius: 1.0,
        elevation: 1,
    },
    '1': {
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.2,
        shadowRadius: 1.41,
        elevation: 2,
    },
    '2': {
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    '3': {
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
    },
    '4': {
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    '5': {
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
    },
    '6': {
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.29,
        shadowRadius: 4.65,
        elevation: 7,
    },
    '7': {
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.3,
        shadowRadius: 4.65,
        elevation: 8,
    },
    '8': {
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.32,
        shadowRadius: 5.46,
        elevation: 9,
    },
    '9': {
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,
        elevation: 10,
    },
};

const fontDefaults = {
    fontWeights: {
        hairline: 100,
        thin: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
        extraBlack: 950,
    },
    fonts: {
        heading: undefined,
        body: undefined,
        mono: undefined,
    },
    fontSizes: {
        '2xs': 10,
        'xs': 12,
        'sm': 14,
        'md': 16,
        'lg': 18,
        'xl': 20,
        '2xl': 24,
        '3xl': 30,
        '4xl': 36,
        '5xl': 48,
        '6xl': 60,
        '7xl': 72,
        '8xl': 96,
        '9xl': 128,
    }
};

const defaultTheme = {
    colors,
    opacity,
    radii,
    sizes: {
        ...space
    },
    shadows,
    space,
    breakpoints: {
        base: 0,
        sm: 480,
        md: 768,
        lg: 992,
        xl: 1280,
        xxl: 1536
    },
    ...fontDefaults,
    components: {
        Divider: {
            baseStyle: {
                width: '100%',
                height: StyleSheet.hairlineWidth,
                marginTop: 1 * scale,
                marginBottom: 1 * scale
            }
        },
        HStack: {
            baseStyle: {
                flexDirection: 'row'
            }
        },
        VStack: {
            baseStyle: {
                flexDirection: 'column'
            }
        },
        Button: {
            baseStyle: {
                borderRadius: 1 * scale,
                alignItems: 'center',
                justifyContent: 'center',
                _disabled: {
                    opacity: .4
                },
                _stack: {
                    space: '1',
                    alignItems: 'center'
                }
            },
            sizes: buttonSizes
        },
        Icon: {
            sizes: iconSizes
        },
        Text: {
            baseStyle: {
            }
        },
        Input: {
            baseStyle: {
                // px: '3',
                // py: '2'
                paddingLeft: 3 * scale,
                paddingRight: 3 * scale,
                paddingTop: 2 * scale,
                paddingBottom: 2 * scale,
                borderRadius: radii.sm,
                borderColor: colors.muted['300'],
                borderWidth: .25 * scale
            }
        },
        Modal: {
            baseStyle: {
                backgroundColor: white,
                borderRadius: 2 * scale,
                overflow: 'hidden',
                borderWidth: .25 * scale
            },
            sizes: modalSizes
        },
        ModalOverlay: {
            baseStyle: {
                flex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Translucent overlay color
                justifyContent: 'center',
                alignItems: 'center',
            }
        },
        ModalContent: {
            baseStyle: {
                justifyContent: 'center'
            },
        },
        ModalHeader: {
            baseStyle: {
                width: '100%',
                padding: 3 * scale,
                borderBottomWidth: 1
            }
        },
        ModalFooter: {
            baseStyle: {
                width: '100%',
                alignItems: 'flex-end',
                padding: 3 * scale
            }
        },
        ModalBody: {
            baseStyle: {
                padding: 3 * scale
            }
        },
        Avatar: {
            baseStyle: {
                borderRadius: radii.full
            },
            sizes: {
                'sm': 32,
                'md': 48,
                'lg': 64,
                'xl': 96,
                '2xl': 128
            }
        }
    }
};

export const extendTheme = (customizations) => {
    return merge({}, defaultTheme, customizations);
}

export const useTheme = () => React.useContext(ThemeContext)

export const useBreakpointValue = (values) => {
    if (!values) {
        return values;
    }
    const { width: windowWidth } = Dimensions.get('window');
    const { breakpoints } = useTheme();
    if (windowWidth >= breakpoints.xxl) {
        return values.xxl ?? values.xl ?? values.lg ?? values.md ?? values.sm ?? values.base ?? values;
    } else if (windowWidth >= breakpoints.xl) {
        return values.xl ?? values.lg ?? values.md ?? values.sm ?? values.base ?? values;
    } else if (windowWidth >= breakpoints.lg) {
        return values.lg ?? values.md ?? values.sm ?? values.base ?? values;
    } else if (windowWidth >= breakpoints.md) {
        return values.md ?? values.sm ?? values.base ?? values;
    } else if (windowWidth >= breakpoints.sm) {
        return values.sm ?? values.base ?? values;
    } else {
        return values.base ?? values;
    }
}

const useSingleThemeValue = (group, value) => {
    const theme = useTheme();
    const g = get(theme, group);
    if (g) {
        return get(g, value);
    }
}

export const useThemeValue = (group, value) => {
    let result;
    if (Array.isArray(value)) {
        result = value.map(v => useSingleThemeValue(group, v))
    } else {
        result = useSingleThemeValue(group, value)
    }
    return result ?? value;
}

const validStyleKeys = Object.freeze({
    alignContent: true,
    alignItems: true,
    alignSelf: true,
    aspectRatio: true,
    borderBottomWidth: 'sizes',
    borderEndWidth: 'sizes',
    borderLeftWidth: 'sizes',
    borderRightWidth: 'sizes',
    borderStartWidth: 'sizes',
    borderTopWidth: 'sizes',
    borderWidth: 'sizes',
    bottom: true,
    columnGap: true,
    direction: true,
    display: true,
    end: true,
    flex: true,
    flexBasis: true,
    flexDirection: true,
    flexGrow: true,
    flexShrink: true,
    flexWrap: true,
    gap: true,
    height: true,
    inset: true,
    insetBlock: true,
    insetBlockEnd: true,
    insetBlockStart: true,
    insetInline: true,
    insetInlineEnd: true,
    insetInlineStart: true,
    justifyContent: true,
    left: true,
    margin: true,
    marginBlock: true,
    marginBlockEnd: true,
    marginBlockStart: true,
    marginBottom: true,
    marginEnd: true,
    marginHorizontal: true,
    marginInline: true,
    marginInlineEnd: true,
    marginInlineStart: true,
    marginLeft: true,
    marginRight: true,
    marginStart: true,
    marginTop: true,
    marginVertical: true,
    maxHeight: true,
    maxWidth: true,
    minHeight: true,
    minWidth: true,
    overflow: true,
    padding: true,
    paddingBlock: true,
    paddingBlockEnd: true,
    paddingBlockStart: true,
    paddingBottom: true,
    paddingEnd: true,
    paddingHorizontal: true,
    paddingInline: true,
    paddingInlineEnd: true,
    paddingInlineStart: true,
    paddingLeft: true,
    paddingRight: true,
    paddingStart: true,
    paddingTop: true,
    paddingVertical: true,
    position: true,
    right: true,
    rowGap: true,
    start: true,
    top: true,
    width: true,
    zIndex: true,
    shadowColor: 'colors',
    shadowOffset: true,
    shadowOpacity: true,
    shadowRadius: 'radii',
    transform: true,
    backfaceVisibility: true,
    backgroundColor: 'colors',
    borderBottomColor: 'colors',
    borderBottomEndRadius: 'radii',
    borderBottomLeftRadius: 'radii',
    borderBottomRightRadius: 'radii',
    borderBottomStartRadius: 'radii',
    borderColor: 'colors',
    borderCurve: true,
    borderEndColor: 'colors',
    borderEndEndRadius: 'radii',
    borderEndStartRadius: 'radii',
    borderLeftColor: 'colors',
    borderRadius: 'radii',
    borderRightColor: 'colors',
    borderStartColor: 'colors',
    borderStartEndRadius: 'radii',
    borderStartStartRadius: 'radii',
    borderStyle: true,
    borderTopColor: 'colors',
    borderTopEndRadius: 'radii',
    borderTopLeftRadius: 'radii',
    borderTopRightRadius: 'radii',
    borderTopStartRadius: 'radii',
    elevation: true,
    opacity: true,
    pointerEvents: true,
    color: 'colors',
    fontFamily: true,
    fontSize: true,
    fontStyle: true,
    fontVariant: true,
    fontWeight: true,
    includeFontPadding: true,
    letterSpacing: true,
    lineHeight: true,
    textAlign: true,
    textAlignVertical: true,
    textDecorationColor: 'colors',
    textDecorationLine: true,
    textDecorationStyle: true,
    textShadowColor: 'colors',
    textShadowOffset: true,
    textShadowRadius: 'radii',
    textTransform: true,
    textWrap: Platform.select({web: true, default: false}),
    userSelect: true,
    verticalAlign: true,
    writingDirection: true
})

export const buildPropsStyle = (props) => {
    let style = null, nonStyleProps = null;
    for (let [propName, propValue] of Object.entries(props)) {
        if (typeof propValue === 'object') {
            propValue = useBreakpointValue(propValue);
        }
        switch (propName) {
            case 'bg':
                style = set(style || {}, 'backgroundColor', useSingleThemeValue('colors', propValue) ?? propValue);
                break
            case 'color':
                style = set(style || {}, 'color', useSingleThemeValue('colors', propValue) ?? propValue);
                break
            case 'w':
                style = set(style || {}, 'width', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'maxW':
                style = set(style || {}, 'maxWidth', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'h':
                style = set(style || {}, 'height', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'maxH':
                style = set(style || {}, 'maxHeight', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'm':
                style = set(style || {}, 'marginTop', useSingleThemeValue('sizes', propValue) ?? propValue);
                style.marginBottom = style.marginTop;
                style.marginLeft = style.marginTop;
                style.marginRight = style.marginTop;
                break;
            case 'mx':
                style = set(style || {}, 'marginLeft', useSingleThemeValue('sizes', propValue) ?? propValue);
                style.marginRight = style.marginLeft;
                break;
            case 'my':
                style = set(style || {}, 'marginTop', useSingleThemeValue('sizes', propValue) ?? propValue);
                style.marginBottom = style.marginTop;
                break;
            case 'mt':
                style = set(style || {}, 'marginTop', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'mb':
                style = set(style || {}, 'marginBottom', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'mr':
                style = set(style || {}, 'marginRight', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'ml':
                style = set(style || {}, 'marginLeft', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'p':
                style = set(style || {}, 'paddingTop', useSingleThemeValue('sizes', propValue) ?? propValue);
                style.paddingBottom = style.paddingTop;
                style.paddingLeft = style.paddingTop;
                style.paddingRight = style.paddingTop;
                break;
            case 'px':
                style = set(style || {}, 'paddingLeft', useSingleThemeValue('sizes', propValue) ?? propValue);
                style.paddingRight = style.paddingLeft;
                break;
            case 'py':
                style = set(style || {}, 'paddingTop', useSingleThemeValue('sizes', propValue) ?? propValue);
                style.paddingBottom = style.paddingTop;
                break;
            case 'pt':
                style = set(style || {}, 'paddingTop', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'pb':
                style = set(style || {}, 'paddingBottom', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'pr':
                style = set(style || {}, 'paddingRight', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'pl':
                style = set(style || {}, 'paddingLeft', useSingleThemeValue('sizes', propValue) ?? propValue);
                break;
            case 'rounded':
                style = set(style || {}, 'borderRadius', useSingleThemeValue('radii', propValue) ?? propValue);
                break;
            case 'shadow':
                style = Object.assign(style || {}, useSingleThemeValue('shadows', propValue) ?? propValue);
                break;
            case 'bold':
                if (propValue) {
                    style = set(style || {}, 'fontWeight', useSingleThemeValue('fontWeights', 'bold'));
                }
                break;
            case 'italic':
                if (propValue) {
                    style = set(style || {}, 'fontStyle', 'italic');
                }
                break;
            case 'underline':
                if (propValue) {
                    style = set(style || {}, 'textDecorationLine', 'underline');
                }
                break;
            case 'fontSize':
                style = set(style || {}, 'fontSize', useSingleThemeValue('fontSizes', propValue) ?? propValue);
                break;
            case 'fontWeight':
                style = set(style || {}, 'fontWeight', useSingleThemeValue('fontWeights', propValue) ?? propValue);
                break;
            case 'font': {
                const themedFont = useThemeValue('fonts', propValue);
                const themedFontConfig = useThemeValue('fontConfig', themedFont);
                const themedFontWeight = useSingleThemeValue('fontWeights', props.fontWeight || 'normal') ?? props.fontWeight ?? '400';
                const fontStyle = props.fontStyle || 'normal';
                style = set(style || {}, 'fontFamily', typeof themedFontConfig === 'object' ? themedFontConfig[themedFontWeight][fontStyle] : themedFontConfig);
                break;
            }
            default:
                if (propName in validStyleKeys) {
                    if (!validStyleKeys[propName]) {
                        console.debug(`Unsupported style prop ${propName} used`);
                    } else if (typeof validStyleKeys[propName] === 'string') {
                        style = set(style || {}, propName, useSingleThemeValue(validStyleKeys[propName], propValue) ?? propValue);
                    } else {
                        style = set(style || {}, propName, propValue);
                    }
                } else {
                    nonStyleProps = set(nonStyleProps || {}, propName, propValue);
                }
                break;
        }
    }
    return { style, nonStyleProps };
}
