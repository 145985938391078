import React from 'react';
import PropTypes from 'prop-types';

import { useNavigation } from '@react-navigation/native';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import TournamentConfigureStep from '@screens/tournament-editor/TournamentConfigureStep';
import TournamentFillStep from '@screens/tournament-editor/TournamentFillStep';
import TournamentNameStep from '@screens/tournament-editor/TournamentNameStep';
import TournamentStartStep from '@screens/tournament-editor/TournamentStartStep';

import TournamentMenu from '@screens/tournament-menu/TournamentMenu';

import { useThemeValue } from '@components/Theme';

const Steps = createMaterialTopTabNavigator();

const StepsNavigator = (props) => {
    const { tournament, isJustCreated = false, createOrUpdateAfterFirstStep } = props;

    const navigation = useNavigation();

    React.useEffect(() => {
        if (tournament) {
            navigation.setOptions({
                title: tournament.name,
                headerRight: () => <TournamentMenu tournament={tournament} />
            });
        }
    }, [tournament]);

    const themedFont = useThemeValue('fonts', 'body');
    const themedFontConfig = themedFont && useThemeValue('fontConfig', themedFont);

    // Don't pass tournament as props to screens. To re-render when changes are made
    // they need to use the tournament from the list context via useSelectedTournament.
    return (
        <Steps.Navigator screenOptions={{
                tabBarLabelStyle: {
                    fontFamily: themedFontConfig && themedFontConfig['400']?.normal
                }
            }}>
            <Steps.Screen name='Details'>
                {props => {
                    return (
                        <TournamentNameStep
                            {...props}
                            onNext={createOrUpdateAfterFirstStep}
                            isJustCreated={isJustCreated}
                        />
                    )
                }}
            </Steps.Screen>
            <Steps.Screen name='Entries' component={TournamentFillStep} />
            <Steps.Screen name='Design' component={TournamentConfigureStep} />
            <Steps.Screen name='Start' component={TournamentStartStep} />
        </Steps.Navigator>
    )
}

StepsNavigator.propTypes = {
    tournament: PropTypes.object,
    isJustCreated: PropTypes.bool,
    createOrUpdateAfterFirstStep: PropTypes.func
}

export default StepsNavigator;
