import React from 'react';

import {
    Pressable,
    StyleSheet
} from 'react-native';

import { useColorModeValue, useThemeValue } from '@components/Theme';

import {
    Divider,
    HStack,
    VStack
} from '@components/Layout';

import { Text } from '@components/Text';

import * as MatchStatus from '@api/MatchStatus';

import { format } from 'date-fns';

const countParticipants = tournament => {
    const numTeams = tournament.Teams && tournament.Teams.length;
    const numPlayers = tournament.Players && tournament.Players.length;
    return numPlayers + numTeams;
}

const Header = ({tournament, namePlaceholder}) => {
    return (
        <HStack>
            <Text fontWeight={'bold'} fontStyle={tournament.name ? 'normal' : 'italic'} fontSize={'lg'} flex={1}>{tournament.name || namePlaceholder}</Text>
            {tournament.playDate && <Text alignSelf={'center'}>{format(new Date(tournament.playDate), "PPP")}</Text>}
        </HStack>
    )
}

const Body = ({tournament}) => {
    return (
        <VStack space={1}>
            <Text>{tournament.game && tournament.game}</Text>
            {tournament.winner ? (
                <Text fontSize={'md'}>{"Winner: " + tournament.winner.name}</Text>
            ) : (
                <Text fontSize={'md'}>{`${countParticipants(tournament)} participants`}</Text>
            )}
        </VStack>
    )
}

const TournamentCardPressable = (props) => {
    const { onPress, onLongPress, children } = props;
    const [gray300, gray500] = useThemeValue("colors", ["gray.300", "gray.500"]);
    const highlightColor = useColorModeValue(gray300, gray500);
    return (
        <Pressable style={({pressed}) => {
            return pressed ? [styles.container, { backgroundColor: highlightColor }] : styles.container
        }} onPress={onPress} onLongPress={onLongPress}>
            {children}
        </Pressable>
    )
}

const TournamentCardDefault = ({tournament, onPress, onLongPress, namePlaceholder = 'Un-named tournament'}) => {
    return (
        <TournamentCardPressable onPress={onPress} onLongPress={onLongPress}>
            <VStack px={1} my={1} divider={<Divider m={1} />}>
                <Header tournament={tournament} namePlaceholder={namePlaceholder} />
                <Body tournament={tournament} />
            </VStack>
        </TournamentCardPressable>
    )
}

const TournamentCardStarted = ({tournament, eliminationGroups, onPress, onLongPress}) => {
    const allMatches = eliminationGroups && eliminationGroups.map(g => g.Matches).flat();
    const matchesComplete = allMatches && allMatches.filter(m => m.resultStatus === MatchStatus.VERIFIED);

    return (
        <TournamentCardPressable onPress={onPress} onLongPress={onLongPress}>
            <VStack px={1} my={1} divider={<Divider m={1} />}>
                <Header tournament={tournament} namePlaceholder={''} />
                <VStack space={1}>
                    {/* <Text>{tournament.game && tournament.game}</Text> */}
                    {/* <Text fontSize={'md'}>{`${countParticipants(tournament)} participants`}</Text> */}
                    {matchesComplete && allMatches && (
                        <Text fontSize={'md'}>{`${matchesComplete.length} of ${allMatches.length} matches complete`}</Text>
                    )}
                </VStack>
            </VStack>
        </TournamentCardPressable>
    )
}

const TournamentCardCompleted = (props) => {
    return <TournamentCardDefault {...props} />
}

export { TournamentCardDefault, TournamentCardStarted, TournamentCardCompleted };

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginBottom: 4
    }
})
