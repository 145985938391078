import * as ActionTypes from '@actions/actionTypes';

const fetchingSlice = (state, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_USERINFO_REQUEST:
        case ActionTypes.FETCH_USER_REQUEST:
            return true;
        case ActionTypes.FETCH_USERINFO_SUCCESS:
        case ActionTypes.FETCH_USERINFO_FAILURE:
        case ActionTypes.FETCH_USER_SUCCESS:
        case ActionTypes.FETCH_USER_FAILURE:
            return false;
        default:
            return state;
    }
}

const errorSlice = (state, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_USERINFO_REQUEST:
        case ActionTypes.FETCH_USERINFO_SUCCESS:
        case ActionTypes.FETCH_USER_REQUEST:
        case ActionTypes.FETCH_USER_SUCCESS:
            return null;
        case ActionTypes.FETCH_USERINFO_FAILURE:
        case ActionTypes.FETCH_USER_FAILURE:
            return action.error
        default:
            return state;
    }
}

const userInfoSlice = (state, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_USERINFO_REQUEST:
        case ActionTypes.FETCH_USERINFO_FAILURE:
            return null
        case ActionTypes.FETCH_USERINFO_SUCCESS:
            return action.userInfo
        default:
            return state;
    }
}

const playerSlice = (state, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_PLAYER:
            return {
                ...state,
                name: action.name || state.name,
                avatar: {
                    ...(state && state.avatar),
                    ...action.avatar
                }
            }
        default:
            return state;
    }
}

const userSlice = (state, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_USER_SUCCESS:
            return action.user
        case ActionTypes.UPDATE_PLAYER:
            return {
                ...state,
                Player: playerSlice(state && state.Player, action)
            }
        case ActionTypes.UPDATE_SETTINGS:
            return {
                ...state,
                settings: {
                    ...(state && state.settings),
                    ...action.settings
                }
            }
    }
}

export default (state, action) => {
    return {
        fetching: fetchingSlice(state.fetching, action),
        error: errorSlice(state.error, action),
        userInfo: userInfoSlice(state.userInfo, action),
        user: userSlice(state.user, action)
    }
}
