import React from 'react';

import { Avatar } from '@components/Avatar';

const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

const rewriteFacebookPictureUrl = picture => {
    // Re-write the URL to use more stable graph.facebook.com.
    const parts = picture.split('?');
    if (parts.length > 1) {
        const pictureParams = getQueryStringParams(parts[1]);
        const asid = pictureParams.asid;
        return asid && `https://graph.facebook.com/${asid}/picture?type=large`;
    }
    return null;
}

const getPlayerPicture = player => {
    const avatar = player?.avatar;

    if (avatar?.avatarChoice !== 'profilepic') {
        return null;
    }

    if (avatar?.picture && avatar?.picture.indexOf('fbsbx.com') !== -1) {
        // Profile picture URLs seem to work only with a fresh hash
        // from GET:auth0/userinfo so convert it to a more stable form.
        return rewriteFacebookPictureUrl(avatar.picture);
    } else {
        return avatar.picture;
    }
}

const getPlayerInitials = player => {
    const name = player && player.avatar && (player.avatar.displayName || player.avatar.nickname);
    if (name) {
        const [first, last] = name.split(/[' ._']/);
        if (first && last) {
            return first[0].toUpperCase() + last[0].toUpperCase();
        } else {
            return first.substr(0, 2).toUpperCase();
        }
    } else {
        return '';
    }
}

const PlayerAvatar = ({player, ...rest}) => {
    const picture = getPlayerPicture(player);
    const initials = getPlayerInitials(player);
    return <Avatar source={{uri: picture}} {...rest}>{initials}</Avatar>
}

export default PlayerAvatar;
