import React from 'react';

import { Menu } from '@components/Menu';

import useOpenCloseState from '@utils/useOpenCloseState';

import { useNavigation } from '@react-navigation/native';

import { useTournamentAPI } from '@hooks/TournamentListContext';

import ConfirmDialog from '@components/ConfirmDialog';
import MenuButton from '@components/MenuButton';

const TournamentMenu = ({tournament, ...props}) => {
    const navigation = useNavigation();
    const { cloneTournament, deleteTournament } = useTournamentAPI();
    const { isOpen, onOpen, onClose } = useOpenCloseState();

    const onClone = React.useCallback(() => {
        cloneTournament(tournament).then(clone => {
            if (clone) {
                navigation.goBack();
            } else {
                // TODO: indicate error with some alert message
            }
        });
    }, [cloneTournament, tournament]);

    const confirmedDelete = React.useCallback(() => {
        onClose();
        deleteTournament(tournament).then((success) => {
            if (success) {
                navigation.goBack();
            }
        });
    }, [deleteTournament, tournament]);

    const onShowCode = React.useCallback(() => {
        navigation.push('TournamentQR', {
            id: tournament.shortId
        });
    }, [tournament]);

    return (<>
        <Menu placement='bottom right' trigger={MenuButton} triggerProps={{mx: 2}} {...props}>
            <Menu.Item onPress={() => onShowCode()}>Show QR code</Menu.Item>
            <Menu.Item onPress={() => onClone()}>Clone tournament</Menu.Item>
            <Menu.Item isDisabled={true}>Archive tournament</Menu.Item>
            <Menu.Item onPress={() => onOpen()}>Delete tournament</Menu.Item>
        </Menu>
        <ConfirmDialog
            isOpen={isOpen}
            onCancel={() => onClose()}
            onConfirm={() => confirmedDelete()}
            dialogTitle={'Confirm delete'}
            dialogText={`Delete tournament "${tournament.name}"?`}
            confirmLabel={'Delete'}
            confirmButtonProps={{colorScheme: 'red'}}
        />
    </>)
}

export default TournamentMenu;
