import { makeApiUrl } from '@api';

export const updateUser = async (authHeader, user, data) => {
    const response = await fetch(makeApiUrl('users', user.id), {
        method: 'PUT',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    if (response.ok) {
        const result = await response.json();
        return result;
    } else {
        return null;
    }
}

export async function requestDeleteUser(authHeader) {
    return await fetch(makeApiUrl('user'), {
        method: 'delete',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        return response.ok;
    });
}

export async function confirmDeleteUser(authHeader, user, code) {
    if (typeof user.id === 'undefined') {
        throw new Error("Given user does not have an \"id\" field");
    }

    await fetch(makeApiUrl('users', user.id), {
        method: 'delete',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({code})
    }).then(response => {
        return response.ok;
    });
}
