import React from 'react';

import {
    Box,
    VStack
} from '@components/Layout';

import {
    useBreakpointValue
} from '@components/Theme';

import { Heading, Text } from '@components/Text';

import { useToast } from '@components/Toast';

import QRCode from '@components/QRCode';

import Clipboard from '@react-native-clipboard/clipboard';

import { useNavigation } from '@react-navigation/native';

import { useTournament } from '@hooks/TournamentListContext';

import ResponsiveContainer from '@components/ResponsiveContainer';

import getTournamentColors from '@utils/getTournamentColors';

import { makeTournamentURL, makeTournamentEnterURL } from '@api';
import * as TournamentStatus from '@api/TournamentStatus';

const iconImage = require('@assets/qrcode-icon.png');

// showToast false by default because in the production version, copying to
// the clipboard will show a built-in notification automatically and any other
// message would be redundant
const TournamentQR = ({params, showToast = false}) => {
    const navigation = useNavigation();

    const toast = useToast();

    const tournament = useTournament(params.id);

    const qrCodeSize = useBreakpointValue({
        base: 250,
        md: 400,
        lg: 500
    })

    const qrCodeMargin = useBreakpointValue({
        base: 4,
        lg: 8
    })

    const tournamentColors = getTournamentColors(tournament);

    React.useEffect(() => {
        // Don't use the tournament name as the title because
        // it's redundant with the heading
        navigation.setOptions({
            headerStyle: tournamentColors,
        });
    }, [navigation]);

    const hasName = tournament && Boolean(tournament.name)

    const tournamentURL = tournament.status === TournamentStatus.CREATED ? makeTournamentEnterURL(tournament) : makeTournamentURL(tournament);

    const copyLinkToClipboard = React.useCallback(() => {
        if (tournamentURL) {
            Clipboard.setString(tournamentURL);
            if (showToast) {
                toast.show('Link copied to clipboard');
            }
        }
    }, [tournamentURL]);

    return (
        <ResponsiveContainer style={tournamentColors}>
            <VStack flexGrow={1} alignItems={'center'} justifyContent={'center'} space={4}>
                <Heading my={2} fontStyle={hasName ? 'normal' : 'italic'}>
                    {tournament && (tournament.name ? tournament.name : 'Untitled tournament')}
                </Heading>
                <Box>
                    {tournament ? (
                        <QRCode
                            value={tournamentURL}
                            size={qrCodeSize}
                            logo={iconImage}
                            logoMargin={qrCodeMargin}
                            logoBackgroundColor={tournamentColors.backgroundColor}
                            color={tournamentColors.color}
                            backgroundColor={tournamentColors.backgroundColor}
                        />
                    ) : (
                        <Box h={qrCodeSize} w={qrCodeSize} />
                    )}
                </Box>
            </VStack>
            <VStack alignItems={'center'} space={2} m={2} mb={4}>
                <Text textAlign={'center'} mb={6} selectable={true} onPress={copyLinkToClipboard}>{tournamentURL}</Text>
            </VStack>
        </ResponsiveContainer>
    );
}

export default TournamentQR;
