/**
 * Backyard Bracket application custom theme
 *
 * @format
 * @flow strict-local
 */

import { extendTheme } from '@components/Theme';

const theme = extendTheme({
    fontConfig: {
        MerriweatherSans: {
            400: {
                normal: 'MerriweatherSans_400Regular',
                italic: 'MerriweatherSans_400Regular_Italic',
            },
            700: {
                normal: 'MerriweatherSans_700Bold',
                italic: 'MerriweatherSans_700Bold_Italic',
            }
        },
        PatuaOne: {
            400: {
                normal: 'PatuaOne_400Regular'
            }
        },
        PermanentMarker: {
            400: {
                normal: 'PermanentMarker_400Regular'
            }
        }
    },
    fonts: {
        heading: 'MerriweatherSans',
        body: 'MerriweatherSans',
        mono: 'MerriweatherSans'
    },
    sizes: {
        contentMaxWidth: 800
    }
});

export default theme;
