import { makeApiUrl } from '@api';
import { updateUser } from '@api/user';

import { merge } from 'lodash';

export const createPlayer = async (authHeader, data) => {
    const response = await fetch(makeApiUrl('players'), {
        method: 'POST',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        },
        body: data && JSON.stringify(data)
    });
    if (response.ok) {
        const result = await response.json();
        return result;
    } else {
        return null;
    }
}

export const updatePlayer = async (authHeader, player, data) => {
    if (!player.id) {
        throw new Error('No id field in player object');
    }
    const response = await fetch(makeApiUrl('players', player.id), {
        method: 'PUT',
        headers: {
            Authorization: authHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    if (response.ok) {
        const result = await response.json();
        return result;
    } else {
        return null;
    }
}

export const createUserPlayer = async (authHeader, user, extra) => {
    const fromProfile = user.profile && {
        name: user.profile.displayName,
        avatar: {
            picture: user.profile.picture
        }
    }

    const player = await createPlayer(authHeader, merge(fromProfile, extra));
    if (player) {
        await updateUser(authHeader, user, {
            PlayerId: player.id
        })
    }
    return player;
}

export const createOrUpdateUserPlayer = async (authHeader, user, data) => {
    if (user.Player) {
        return updatePlayer(authHeader, user.Player, {
            ...data
        });
    } else {
        return createUserPlayer(authHeader, user, data)
    }
}
