import React from 'react';

import {
    ScrollView,
    StyleSheet
} from 'react-native';

import { useForm, Controller } from "react-hook-form";

import { partialRight } from 'lodash';

import { FormControl, Select } from '@components/Form';

import { useNavigation, useFocusEffect } from '@react-navigation/native';

import { useTheme } from '@components/Theme';

import {
    Box,
    Divider,
    VStack
} from '@components/Layout';

import ColorSwatchPicker from '@components/ColorSwatchPicker';

import { usePreview, useSelectedTournament, useTournamentAPI } from '@hooks/TournamentListContext';

import blackOrWhite from '@utils/blackOrWhite';

import getTournamentColors from '@utils/getTournamentColors';

import { validateBackgroundColorProduct, validateThemeProduct } from './validateProduct';
import { getTournamentProduct } from '@hooks/TournamentProductsContext';

import PreviewBracket from './PreviewBracket';
import ValidationErrorMessage from './ValidationErrorMessage';

const customBgColors = Object.freeze([
    '#007A33',//celtic green, former=#2ECC70
    '#27AF60',
    '#3398DB',
    '#004687',//royal blue, former=#2980B9
    '#33006F',//purple, former=#8E43AD
    '#008E97',//nautical aqua, former='#3D556E'
    '#FEDB00',//yellow, former=#F2C511
    '#F39C19',
    '#E84B3C',
    '#C41E3A',//cardinal red, former=#C0382B
    '#DDE6E8',
    '#BDC3C8'
]);

const TournamentConfigureStep = () => {
    const { colors } = useTheme();

    const tournament = useSelectedTournament();

    const { control, handleSubmit, setValue, watch, trigger } = useForm({
        mode: 'all',
        defaultValues: {
            bgColor: tournament?.bgColor || colors.white,
            textColor: tournament?.textColor || colors.black,
            theme: tournament?.theme || 'default',
            product: getTournamentProduct(tournament)
        }
    });

    const allFields = watch();

    const { fetchPreview, updateTournament } = useTournamentAPI();

    const navigation = useNavigation();

    const preview = usePreview(tournament && tournament.id);

    React.useEffect(() => {
        if (tournament && !preview) {
            fetchPreview(tournament.id);
        }
    }, [tournament]);

    const bgColors = Object.freeze([
        ...customBgColors,
        colors.black,
        colors.white
    ]);

    const textColors = Object.freeze(bgColors.reduce((result, color) => {
        result[color] = blackOrWhite(color, {black:colors.darkText, white:colors.lightText});
        return result;
    }, {}));

    useFocusEffect(
        React.useCallback(() => {
            // update the product value directly since it there is no
            // form control to change it on this screen
            setValue('product', getTournamentProduct(tournament))

            // trigger a validation to show initial errors
            trigger();
        }, [tournament])
    );

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('blur', () => {
            handleSubmit(data => {
                updateTournament(tournament.id, data).then(() => {});
            })(); // handleSubmit returns a function
        });

        return unsubscribe;
    }, [navigation, handleSubmit, tournament]);

    return (
        <ScrollView style={styles.container}>
            <VStack space={1}>
                <FormControl>
                    <FormControl.Label bold fontSize='lg' py={3}>Background color</FormControl.Label>
                    <Controller
                        control={control}
                        name='bgColor'
                        rules={{
                            validate: partialRight(validateBackgroundColorProduct, [colors.white, colors.black])
                        }}
                        render={({field, fieldState}) => (
                            <>
                                <ColorSwatchPicker
                                    colors={bgColors}
                                    onBlur={field.onBlur}
                                    onColorChange={value => {
                                        field.onChange(value)
                                        setValue('textColor', textColors[value])
                                    }}
                                    selectedColor={field.value}
                                />
                                <ValidationErrorMessage error={fieldState?.error} />
                            </>
                        )}
                    />
                </FormControl>

                <FormControl>
                    <FormControl.Label bold fontSize='lg' py={3}>Text color</FormControl.Label>
                    <Controller
                        control={control}
                        name='textColor'
                        render={({field}) => (
                            <ColorSwatchPicker
                                colors={[colors.black, colors.white]}
                                onBlur={field.onBlur}
                                onColorChange={field.onChange}
                                selectedColor={field.value}
                            />
                        )}
                    />
                </FormControl>

                <Divider my={1} />

                <FormControl>
                    <FormControl.Label bold fontSize='lg' py={3}>Theme</FormControl.Label>
                    <Controller
                        control={control}
                        name='theme'
                        rules={{
                            validate: validateThemeProduct
                        }}
                        render={({field, fieldState}) => (
                            <>
                                <Select accessibilityLabel="theme choice"
                                    selectedValue={field.value}
                                    onValueChange={field.onChange}>
                                    <Select.Item value='default' my={1} label="Default"></Select.Item>
                                    <Select.Item value='whiteboard' my={1} label="Whiteboard"></Select.Item>
                                    <Select.Item value='gameday' my={1} label="Game Day"></Select.Item>
                                </Select>
                                <ValidationErrorMessage error={fieldState?.error} />
                            </>
                        )}
                    />
                </FormControl>

                <Divider my={1} />

                <Box>
                    <PreviewBracket tournament={tournament} preview={preview} colors={getTournamentColors(allFields)} theme={allFields.theme} />
                </Box>
            </VStack>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    container: {
        marginLeft: 4,
        marginRight: 4
    }
})

export default TournamentConfigureStep;
