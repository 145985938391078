import hexToRGB from '@utils/hexToRGB';

const blackOrWhite = (color, {black='#000', white='#fff', threshold=.45, defaultResult} = {}) => {
    const rgb = hexToRGB(color);

    if (!rgb) {
        return defaultResult ?? black;
    }

    // Calculate luminance (Y) with the HDTV BT.709 weight values
    const Y = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b;

    const maxRGB = Math.max(rgb.r, rgb.g, rgb.b);
    const minRGB = Math.min(rgb.r, rgb.g, rgb.b);
    const S = maxRGB > 0 ? (maxRGB - minRGB) / maxRGB : 0;

    if (S < threshold) {
        if (Y >= 128) {
            return black;
        } else {
            return white;
        }
    } else {
        if (maxRGB >= 128) {
            return black;
        } else {
            return white;
        }
    }
}

export default blackOrWhite
