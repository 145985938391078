/**
 * Backyard Bracket application state component
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import PropTypes from 'prop-types';

import * as SplashScreen from 'expo-splash-screen';

import * as Font from 'expo-font';

import Landing from '@screens/landing/Landing';

import MainNavigator from '@screens/main/MainNavigator';

import useAuth from '@hooks/useAuth';
import useCurrentUser from '@hooks/useCurrentUser';

import { useFetchTournaments } from '@hooks/TournamentListContext';

const loadAppFonts = async () => {
    await Font.loadAsync({
        'MerriweatherSans_400Regular': require('@expo-google-fonts/merriweather-sans/MerriweatherSans_400Regular.ttf'),
        'MerriweatherSans_400Regular_Italic': require('@expo-google-fonts/merriweather-sans/MerriweatherSans_400Regular_Italic.ttf'),
        'MerriweatherSans_700Bold': require('@expo-google-fonts/merriweather-sans/MerriweatherSans_700Bold.ttf'),
        'MerriweatherSans_700Bold_Italic': require('@expo-google-fonts/merriweather-sans/MerriweatherSans_700Bold_Italic.ttf'),
        'PatuaOne_400Regular': require('@expo-google-fonts/patua-one/PatuaOne_400Regular.ttf'),
        'PermanentMarker_400Regular': require('@expo-google-fonts/permanent-marker/PermanentMarker_400Regular.ttf')
    });
}

const AppContent = ({ initialIsReady = false, initialFontsLoaded = false }) => {
    const [ isReady, setReady ] = React.useState(initialIsReady);
    const { isAuthenticated, isAuthenticating, login, loginSilently } = useAuth();
    const { currentUser } = useCurrentUser();
    const fetchTournaments = useFetchTournaments();

    const [fontsLoaded, setFontsLoaded] = React.useState(initialFontsLoaded);
    React.useEffect(() => {
        (async () => {
            if (!fontsLoaded) {
                await loadAppFonts()
                setFontsLoaded(true)
            }
        })()
    })

    React.useEffect(() => {
        const attemptSilentLogin = async () => {
            const result = await loginSilently();
            if (!result) {
                setReady(true);
            }
        }
        attemptSilentLogin();
    }, []);

    React.useEffect(() => {
        if (fetchTournaments && currentUser) {
            fetchTournaments(currentUser).then(() => {
                setReady(true)
            })
        }
    }, [fetchTournaments, currentUser]);

    React.useEffect(() => {
        if (isReady) {
            SplashScreen.hideAsync();
        }
    }, [isReady]);

    if (isReady && fontsLoaded) {
        if (isAuthenticated) {
            return (
                <MainNavigator />
            )
        } else {
            return (
                <Landing readyToLogin={!isAuthenticating} onLogin={login} />
            )
        }
    } else {
        // Splash screen will be visible--no need to show anything
        return null
    }
}

AppContent.propTypes = {
    initialIsReady: PropTypes.bool,
    initialFontsLoaded: PropTypes.bool
}

export default AppContent;